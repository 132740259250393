import React, {useState, useEffect} from "react";

export default function DynamixFront({
  lightContrast,
  darkContrast,
  stitching,
  patchColor,
  azomo,
  printStyle,
  getDataFromChild,
  preview,
}) {
  function adjust(color, amount) {
    return (
      "#" +
      color
        .replace(/^#/, "")
        .replace(/../g, (color) =>
          (
            "0" + Math.min(255, Math.max(0, parseInt(color, 16) + amount)).toString(16)
          ).substr(-2)
        )
    );
  }

  const [darkenColor, setDarkenColor] = useState(darkContrast);

  const [lightContrastActive, setLightContrastActive] = useState(false);
  const [darkContrastActive, setDarkContrastActive] = useState(false);
  const [stitchingActive, setStitchingActive] = useState(false);
  const [patchActive, setPatchActive] = useState(false);
  const [azomoActive, setAzomoActive] = useState(false);

  const handleClick = (tabId) => {
    if (!preview) {
      getDataFromChild(tabId);
    }
  };

  useEffect(() => {
    setDarkenColor(adjust(lightContrast, -150));
  }, lightContrast);

  return (
    <svg
      className={printStyle}
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 500 500"
      enableBackground="new 0 0 500 500"
    >
      <path
        fill={darkContrast}
        onClick={() => handleClick("contrast")}
        onMouseEnter={() => setDarkContrastActive(true)}
        onMouseLeave={() => setDarkContrastActive(false)}
        style={
          darkContrastActive && !preview
            ? {fill: adjust(darkContrast, 55)}
            : {fill: darkContrast}
        }
        d="M285.797,19.094l2.181-0.438c0,0-2.953,21.364-1.09,26.021l-0.171,0.698h-1.451c0,0-2.326-8.295-1.703-13
	S284.906,20.563,285.797,19.094z"
      />
      <path
        fill={lightContrast}
        onClick={() => handleClick("base")}
        onMouseEnter={() => setLightContrastActive(true)}
        onMouseLeave={() => setLightContrastActive(false)}
        style={
          lightContrastActive && !preview
            ? {fill: adjust(lightContrast, 55)}
            : {fill: lightContrast}
        }
        d="M474.23,275.37l-0.008-0.047c-0.102-7.015-0.23-14.442-0.375-22.119l0,0l-0.021-2.417l-0.29-13.375l0-0.002
	c-0.347-16.912-0.747-34.4-1.121-50.785c-0.074-3.195-0.146-6.351-0.216-9.47l-0.785-36.022c-0.014-0.683-0.028-1.377-0.041-2.047
	c-0.281-14.287-1.204-32.532-3.612-51.674l0.002-0.003l-0.007-0.041c-0.023-0.184-0.044-0.366-0.067-0.549c-0.009,0-0.017,0-0.025,0
	l-7.523-44.518l-0.001-0.004c0.475-5.529,0.104-11.043-0.338-14.933c-0.407-3.587-0.924-6.345-1.226-7.779l0-0.001l-0.001-0.001
	c-0.031-0.145-0.062-0.292-0.087-0.409c-0.029,0.04-0.064,0.076-0.094,0.116l-0.364-0.587c0.03-0.042,0.065-0.08,0.094-0.123
	c-0.065-0.014-0.129-0.026-0.195-0.04l0,0h-0.002c-2.702-0.558-5.345-1.076-7.603-1.515c-2.946-0.572-5.389-1.13-7.753-1.671
	c-3.108-0.71-6.045-1.382-9.877-2.034c-9.487-1.616-17.925-2.894-25.798-3.908c-7.234-0.932-15.818-1.928-24.64-2.254
	c-9.086-0.335-17.986-0.15-26.453,0.552c-7.369,0.612-15.377,1.568-25.202,3.011c-7.567,1.111-15.43,2.403-24.04,3.949
	c-3.021,0.542-5.22,1.091-7.547,1.673c-1.939,0.484-3.943,0.985-6.503,1.495c-1.037,0.208-2.237,0.448-3.467,0.702h-0.001h-0.38
	l-0.158,0.643c-0.002-0.002-0.004-0.004-0.005-0.007c-0.014,0.064-0.032,0.15-0.048,0.222l-0.007,0.029l0,0.006
	c-0.296,1.38-0.836,4.209-1.259,7.933c-0.438,3.851-0.802,9.295-0.348,14.767l-0.004,0.006l0.2,3.238l-3.935,16.406l-3.766,25.037
	c-0.011,0-0.023,0-0.034,0c-0.032,0.253-0.061,0.504-0.093,0.757l-0.01,0.06v0.022c-2.386,19.053-3.302,37.204-3.582,51.43
	c-0.271,13.717-0.646,30.144-1.043,47.536c-0.461,20.224-0.961,42.115-1.355,62.537l-0.001,0.001v0.045
	c-0.018,0.947-0.038,1.908-0.055,2.848c0.014,0.002,0.04,0.006,0.055,0.008v0.646c-0.019-0.003-0.049-0.007-0.067-0.01
	c-0.151,7.965-0.283,15.671-0.389,22.927l-0.003,0.015l0.002,0.103c0,0.035-0.001,0.073-0.002,0.108c0.001,0,0.002,0,0.003,0
	l1.458,91.915c0.034,4.726,0.064,9.682,0.094,14.774c0.256,42.682,0.574,95.741,3.661,104.687c1.139,0.333,8.591,2.409,20.278,3.479
	c11.641,1.067,29.978,1.325,51.31-3.48c0.419-5.477,5.926-114.766,5.983-115.89c0.003-0.076,0.034-0.808,0.086-2.098l0.065-0.078
	l2.729-94.75l-0.062-0.488c0.08-7.753,0.086-14.9-0.011-20.925l0.073-0.087l-0.102-1.564l-0.001-0.002
	c-0.013-0.643-0.026-1.285-0.041-1.899l-0.001-0.012l0.001-0.01c1.185-26.657,7.349-52.488,7.602-53.539
	c0.002-0.059,0.008-0.219,0.017-0.43l0.337-6.162c0.234-3.708,0.597-8.532,1.128-13.349c0.641-5.817,1.397-10.469,2.246-13.824
	c0.971-3.841,2.064-5.962,3.322-6.49l1.189,0.027c1.232,0.572,2.306,2.685,3.261,6.463c0.848,3.356,1.604,8.007,2.246,13.824
	c0.365,3.318,0.651,6.637,0.871,9.599v0.368l0.611,9.975c0.255,1.057,6.418,26.885,7.602,53.539l0.001,0.01v0.011
	c-0.02,0.724-0.035,1.475-0.049,2.239l-0.001,0.005l-0.003,0.177c0,0.027-0.001,0.052-0.002,0.079c0,0,0,0,0,0l-0.011,0.645
	c0,0,0,0-0.001,0c-0.001,0.072-0.002,0.149-0.003,0.222l-0.007,0.378l0.001,0.007c-0.1,6.403-0.086,14.048,0.008,22.338l0,0l0,0.015
	c0.001,0.083,0.002,0.164,0.003,0.247c0.002,0,0.003,0,0.005,0.001l2.641,91.715c-0.001,0-0.001,0-0.002,0
	c0.095,2.364,0.151,3.72,0.156,3.825c0.058,1.122,5.562,110.411,5.982,115.888c21.342,4.807,39.686,4.547,51.332,3.479
	c11.68-1.073,19.118-3.146,20.255-3.478c3.088-8.946,3.406-62.004,3.661-104.686c0.026-4.215,0.051-8.333,0.078-12.309l0.021-0.03
	v-2.375l1.449-91.97c0.002,0,0.006-0.001,0.008-0.001c-0.001-0.081-0.003-0.167-0.004-0.248L474.23,275.37z"
      />
      <path
        fill={lightContrast}
        onClick={() => handleClick("base")}
        onMouseEnter={() => setLightContrastActive(true)}
        onMouseLeave={() => setLightContrastActive(false)}
        style={
          lightContrastActive && !preview
            ? {fill: adjust(lightContrast, 55)}
            : {fill: lightContrast}
        }
        d="M211.951,489.157c6.454-1.456,13.398-3.49,20.719-6.311c-0.544-9.659-7.343-63.448-7.411-63.994
	c-0.046-0.442-4.534-43.53-7.425-61.764c-1.1-6.944-2.825-14.912-4.607-22.365l0-0.008l-0.165-0.677
	c-0.15-0.624-0.3-1.252-0.451-1.867c-0.001,0-0.002,0-0.004,0.001l-0.151-0.62c0.001,0,0.003-0.001,0.004-0.001
	c-0.063-0.255-0.125-0.5-0.187-0.753l-0.079-0.326l-0.017-0.063c-0.849-3.418-1.685-6.659-2.449-9.538
	c-1.479-5.581-2.188-11.34-2.108-17.121c0.117-8.414,0.269-23.13-0.028-27.139c-0.128-1.736-0.463-4.178-0.851-7.007
	c-0.971-7.08-2.301-16.779-1.737-24.994c0.245-3.578,0.856-8.649,1.564-14.522c1.556-12.93,3.689-30.637,3.611-46.665
	c-0.09-18.678,0.892-31.266,1.317-35.813c0.084-0.886,0.244-1.769,0.478-2.626c0.007-0.024,0.014-0.049,0.021-0.074
	c0.011-0.042,0.023-0.087,0.036-0.134c0.029-0.111,0.061-0.23,0.094-0.356c0.014-0.054,0.028-0.109,0.043-0.165
	c0.026-0.102,0.053-0.207,0.081-0.318c0.016-0.069,0.035-0.138,0.052-0.21c0.037-0.147,0.074-0.302,0.114-0.463
	c0.017-0.075,0.035-0.153,0.055-0.231c0.02-0.083,0.039-0.167,0.059-0.252c0.029-0.129,0.06-0.26,0.091-0.396
	c0.116-0.509,0.24-1.071,0.366-1.683c0.019-0.095,0.039-0.191,0.06-0.289c0.057-0.285,0.116-0.581,0.176-0.886
	c0.033-0.174,0.067-0.349,0.099-0.528c0.013-0.062,0.024-0.124,0.035-0.187c0.024-0.126,0.047-0.252,0.07-0.382
	c0.025-0.134,0.049-0.271,0.074-0.409c-0.002,0-0.004,0-0.005,0.001l0.115-0.646c0.001,0,0.002,0,0.003,0l0.024-0.142
	c0.012-0.07,0.023-0.138,0.035-0.208c0.023-0.139,0.047-0.279,0.069-0.422c0.013-0.071,0.024-0.142,0.035-0.214
	c0.04-0.24,0.078-0.487,0.116-0.738c0.019-0.12,0.037-0.242,0.056-0.366c0.269-1.785,0.525-3.774,0.739-5.946
	c0.009-0.098,0.018-0.196,0.029-0.295c0.063-0.667,0.125-1.352,0.18-2.054c0.014-0.176,0.028-0.353,0.041-0.532
	c0.012-0.165,0.025-0.33,0.037-0.498c0.014-0.199,0.027-0.401,0.041-0.603c0.008-0.116,0.015-0.232,0.022-0.348
	c0.062-0.944,0.112-1.914,0.153-2.91c0.008-0.213,0.016-0.428,0.024-0.643c0.006-0.144,0.011-0.288,0.015-0.433
	c0.005-0.116,0.007-0.232,0.011-0.349c0.027-0.905,0.045-1.829,0.053-2.775c0.002-0.15,0.003-0.299,0.003-0.45
	c0.004-0.615,0.003-1.239-0.003-1.87c-0.004-0.396-0.009-0.796-0.016-1.197c-0.013-0.805-0.036-1.621-0.066-2.449
	c-0.008-0.214-0.016-0.429-0.025-0.643c-0.005-0.14-0.011-0.278-0.018-0.418c-0.007-0.154-0.014-0.307-0.021-0.46
	c-0.009-0.209-0.021-0.417-0.031-0.627c-0.055-1.057-0.123-2.114-0.201-3.168c-0.017-0.226-0.035-0.453-0.053-0.679
	c-0.016-0.2-0.033-0.401-0.049-0.603c-0.013-0.149-0.025-0.299-0.038-0.449c-0.025-0.27-0.049-0.541-0.074-0.811
	c-0.034-0.362-0.068-0.725-0.106-1.086c-0.046-0.452-0.094-0.904-0.145-1.355c-0.019-0.174-0.038-0.348-0.058-0.523
	c-0.008-0.066-0.016-0.131-0.023-0.197l-0.06-0.509c-0.027-0.213-0.052-0.426-0.079-0.637c-0.051-0.412-0.105-0.822-0.161-1.234
	c-0.034-0.258-0.069-0.517-0.106-0.776c-0.021-0.147-0.042-0.296-0.063-0.443c-0.125-0.878-0.26-1.754-0.403-2.63
	c-0.023-0.147-0.049-0.295-0.073-0.442c-0.048-0.294-0.098-0.589-0.149-0.884c-0.028-0.155-0.054-0.307-0.082-0.462
	c-0.034-0.196-0.068-0.39-0.104-0.586c-0.04-0.217-0.079-0.434-0.119-0.651c-0.008-0.046-0.018-0.091-0.027-0.138
	c-0.043-0.235-0.087-0.47-0.133-0.704c-0.039-0.21-0.08-0.42-0.123-0.629c-0.037-0.187-0.074-0.372-0.112-0.559
	c-0.041-0.208-0.084-0.417-0.127-0.626c-0.029-0.146-0.059-0.292-0.09-0.437c-0.032-0.156-0.065-0.311-0.098-0.465
	c-0.019-0.088-0.037-0.177-0.058-0.266l-0.067,0.02c-1.26-4.845-4.709-17.416-4.709-17.416l-0.177-0.06
	c-2.436-6.893-5.425-13.683-8.953-20.351l-0.012-0.038l-1.525-3.015c-1.637-3.619-6.124-14.411-8.854-29.408
	c-0.026-0.14-0.13-0.245-0.26-0.278c-0.076,0.001-0.152,0.004-0.228,0.004c-2.097,0.254-4.173,0.588-6.23,0.964l-0.007-0.012
	l-0.254,0.057c-0.278,0.052-0.56,0.094-0.837,0.147l0.005,0.041l-9.301,2.103c-4.773,1.266-9.305,2.699-13.466,4.08
	c-2.106,0.698-4.249,1.382-6.419,2.057l-15.734,4.553c-2.821,0.759-5.705,1.508-8.676,2.251c-4.164,1.169-8.386,2.432-12.565,3.745
	l-0.06,0.011l-16.181,4.816l-0.15,0.245c-3.951,1.255-7.845,2.551-11.212,3.83c-0.11,0.043-0.186,0.151-0.186,0.27v17.953
	c0.012-0.003,0.029-0.008,0.041-0.011l0.006,0.661c-0.011,0.003-0.028,0.008-0.039,0.011l1.078,37.709l-1.474,3.085
	c-0.593,1.026-1.218,2.115-1.895,3.305c-1.227,2.156-2.509,4.434-3.81,6.768c-1.627,2.922-3.281,5.931-4.917,8.942
	c-1.939,3.573-3.885,7.207-5.782,10.804c-1.134,2.148-2.253,4.306-3.368,6.459l-0.026,0.034l-0.32,0.631
	c-0.208,0.402-0.422,0.805-0.629,1.206c0.005,0.002,0.009,0.004,0.013,0.006l-0.291,0.574c-0.005-0.003-0.011-0.005-0.016-0.008
	c-0.595,1.156-1.186,2.31-1.77,3.456c-1.305,2.56-2.588,5.117-3.855,7.663l-0.241,0.475l-0.088,0.188
	c-0.409,0.823-0.824,1.652-1.228,2.47c0.018,0.008,0.036,0.017,0.053,0.025l-13.14,27.9c-0.011-0.005-0.023-0.01-0.033-0.014
	c-0.201,0.451-0.392,0.892-0.591,1.341l-0.074,0.157l-0.054,0.132c-0.44,0.994-0.884,1.993-1.311,2.973
	c-2.309,5.293-4.495,10.501-6.498,15.482c-2.004,4.986-3.869,9.861-5.564,14.523l-0.031,0.078l-0.05,0.144
	c-0.089,0.247-0.185,0.5-0.273,0.745c-1.285,3.565-2.447,6.959-3.532,10.281l-0.084,0.242l-0.082,0.262
	c-0.473,1.458-0.936,2.907-1.379,4.344c0.005,0.001,0.009,0.002,0.014,0.003l-0.192,0.611c-0.004-0.001-0.007-0.001-0.011-0.002
	c-0.079,0.258-0.15,0.504-0.227,0.76l-0.096,0.305l0.003,0.005c-1.371,4.542-2.542,8.802-3.545,12.949l-0.15,0.622
	c-0.112,0.462-0.222,0.93-0.333,1.394c-0.002,0.004-0.003,0.007-0.005,0.011c-2.779,6.549-1.867,89.246-1.738,99.873
	c-0.003,0-0.007,0-0.01-0.001c0.008,0.378,0.016,0.746,0.024,1.114c0,0.002,0,0.014,0,0.014l0,0c0.086,3.9,0.175,7.28,0.275,10.477
	c0.095,3.015,0.219,6.583,0.414,10.205l0.014,0.241c0.111,2.063,0.227,4.198,0.496,6.271c0.04,0.302,0.088,0.657,0.155,1.005
	c2.121,10.958,1.866,31.861,1.018,39.59c-0.848,7.728,1.035,31.415,1.223,33.737c1.377,0.648,11.541,5.15,26.92,4.841
	c4.524-0.092,8.603-0.194,12.385-0.324l0.001,0.002l2.168-0.055l0.004-0.027c8.351-0.327,15.236-0.827,22.088-1.743
	c8.95-1.196,10.087-3.205,10.189-3.436c0.005-1.566,0.144-36.868,1.238-52.18c1.199-16.792,3.106-62.481,1.352-74.758l-0.004-0.045
	c0-6.121,2.984-10.128,3.209-10.423c0.075-0.126,1.699-3.048,3.095-18.411c1.447-15.919,3.694-28.594,3.717-28.719l0.002-0.012
	c0.016-0.076,0.422-1.87,1.743-4.086c1.192-2,3.383-4.717,7.086-6.423l-0.003-0.013l0.59-0.306c0.032,0.135,0.067,0.285,0.109,0.465
	l0.056,0.217l-0.004,0.003c0.542,2.324,1.723,7.555,2.684,12.899l0,0.003l11.264,94.099c-0.024,0-0.047,0-0.071,0
	c0.04,0.135,0.08,0.27,0.119,0.406l0,0.001l0.055,0.187c4.522,15.364,9.304,30.846,9.393,31.132l0.005,0.02
	c0.096,0.396,9.586,39.823,11.035,56.531c1.449,16.714,4.867,31.641,5.432,32.663c0.597,0.339,8.251,2.913,21.751,3.305
	c7.17,0.207,16.872-0.224,28.323-2.481l0.006,0.002l0.322-0.068c0.437-0.087,0.872-0.171,1.314-0.264
	c0-0.004-0.001-0.01-0.002-0.014l0.625-0.132c0,0.003,0.001,0.009,0.002,0.013c0.349-0.075,0.707-0.159,1.059-0.237l0.476-0.101
	L211.951,489.157z"
      />
      <path
        fill={patchColor}
        onClick={() => handleClick("patch")}
        onMouseEnter={() => setPatchActive(true)}
        onMouseLeave={() => setPatchActive(false)}
        style={
          patchActive && !preview ? {fill: adjust(patchColor, 55)} : {fill: patchColor}
        }
        d="M192.01,362.74c-3.029,0.543-32.202,5.63-59.958,5.472c-3.761-12.794-7.299-25.368-7.749-28.869
	c-1.034-8.046-2.356-45.395-2.022-52.492c0.097-2.095-0.221-5.196-0.727-8.554c25.19-0.884,49.891-6.62,53.661-7.527
	c2.198,15.469,4.218,27.041,6.034,34.564C184.656,319.439,188.438,340.542,192.01,362.74"
      />
      <path
        fill="#E0E0E0"
        d="M175.124,270.136c-3.542,0.854-28.369,6.648-53.668,7.526c-0.06-0.386-0.122-0.776-0.186-1.167
	c25.358-0.896,50.206-6.704,53.688-7.544C175.015,269.348,175.069,269.743,175.124,270.136"
      />
      <path
        fill="#E0E0E0"
        d="M71.982,262.211c-0.062,0.393-0.125,0.781-0.187,1.166c-2.515-0.093-28.206-1.128-52.201-5.977
	c0.074-0.324,0.147-0.647,0.222-0.97C43.518,261.23,68.805,262.116,71.982,262.211"
      />
      <path
        fill={patchColor}
        onClick={() => handleClick("patch")}
        onMouseEnter={() => setPatchActive(true)}
        onMouseLeave={() => setPatchActive(false)}
        style={
          patchActive && !preview ? {fill: adjust(patchColor, 55)} : {fill: patchColor}
        }
        d="M71.696,264.01c-1.646,10.363-2.584,17.619-2.598,17.724l-0.002,0.034c-0.004,0.26-0.5,26.319-4.723,56.107
	c-0.613,4.317-1.086,9.948-1.45,16.36c-3.018,0.188-22.157,1.255-44.308-0.246c-0.011-0.43-0.02-0.868-0.028-1.314
	c-0.094-4.477-0.176-9.274-0.253-14.667c-0.07-4.857-0.13-9.909-0.186-15.446c-0.05-4.902-0.092-9.893-0.13-15.262
	c-0.032-4.584-0.06-9.331-0.083-14.11c-0.019-4.001-0.035-8.036-0.048-11.994c-0.004-1.633-0.008-3.267-0.012-4.901
	c-0.002-0.79-0.013-1.592-0.023-2.367c-0.033-2.356-0.065-4.792,0.156-7.184c0.267-2.9,0.809-5.825,1.447-8.721
	C43.268,262.837,68.748,263.902,71.696,264.01"
      />
      <path
        fill="#E0E0E0"
        d="M193.811,172.901l-6.043,3.063c-0.81,0.41-1.664,0.707-2.55,0.889l2.547-6.157
	c0.256-0.616,0.337-1.293,0.235-1.958l-3.281-21.427l6.713-0.993l3.681,24.039C195.273,171.403,194.749,172.425,193.811,172.901"
      />
      <path
        fill={darkContrast}
        onClick={() => handleClick("contrast")}
        onMouseEnter={() => setDarkContrastActive(true)}
        onMouseLeave={() => setDarkContrastActive(false)}
        style={
          darkContrastActive && !preview
            ? {fill: adjust(darkContrast, 55)}
            : {fill: darkContrast}
        }
        d="M187.171,170.449l-2.703,6.533l-51.875,7.667c-1.237,0.183-2.484,0.139-3.707-0.131l-5.345-1.177
	c-0.987-0.218-1.754-1.037-1.907-2.037l-3.692-24.123l66.141-9.777l3.281,21.432C187.447,169.384,187.381,169.942,187.171,170.449"
      />
      <path
        fill="#E0E0E0"
        d="M75.922,168.332c-3.173,6.654-6.071,13.461-8.876,20.045l-0.69,1.619c-0.491,1.151-1.11,1.449-2.755,1.321
	c-1.432-0.11-3.153-0.341-4.817-0.564c-0.984-0.133-1.913-0.258-2.772-0.357c-1.293-0.149-2.029-0.343-3.135-0.777
	c0.979-0.658,2.018-1.305,3.028-1.933c0.926-0.576,1.882-1.171,2.773-1.762c0.729-0.485,0.993-0.745,1.332-1.542
	c2.678-6.289,5.549-12.972,8.653-19.571C71.098,165.978,73.528,167.159,75.922,168.332"
      />
      <path
        fill={darkContrast}
        onClick={() => handleClick("contrast")}
        onMouseEnter={() => setDarkContrastActive(true)}
        onMouseLeave={() => setDarkContrastActive(false)}
        style={
          darkContrastActive && !preview
            ? {fill: adjust(darkContrast, 55)}
            : {fill: darkContrast}
        }
        d="M68.083,164.531c-3.11,6.61-5.985,13.302-8.666,19.599c-0.285,0.67-0.463,0.837-1.097,1.257
	c-0.881,0.586-1.834,1.179-2.756,1.752c-1.139,0.708-2.313,1.44-3.404,2.187c-1.698-0.707-3.511-1.478-5.264-2.224
	c-0.748-0.317-1.487-0.631-2.206-0.936c1.463-3.276,2.983-6.614,4.543-9.973c2.204-4.742,4.537-9.633,6.935-14.54
	c0.362-0.741,0.728-1.484,1.095-2.23C60.777,161.054,64.427,162.778,68.083,164.531"
      />
      <path
        fill="#E0E0E0"
        d="M155.953,87.233c-1.866,0.094-11.396,0.553-20.975,0.687c-6.237,0.087-11.274,0.022-14.967-0.194
	c-6.128-0.359-7.117-1.055-7.257-1.338c-1.477-2.965-1.837-25.609-1.871-27.99c1.288-0.339,2.778-0.728,4.418-1.153
	c0.186,1.151,0.891,5.405,1.792,9.754c2.145,10.363,3.282,11.158,3.656,11.42c2.201,1.541,30.181,3.065,34.731,3.304
	C155.633,83.505,155.79,85.344,155.953,87.233"
      />
      <path
        fill={lightContrast}
        onClick={() => handleClick("base")}
        onMouseEnter={() => setLightContrastActive(true)}
        onMouseLeave={() => setLightContrastActive(false)}
        style={
          lightContrastActive && !preview
            ? {fill: adjust(lightContrast, 55)}
            : {fill: lightContrast}
        }
        d="M210.385,80.34c0.179,0.655,0.353,1.31,0.521,1.964C210.739,81.648,210.565,80.993,210.385,80.34
	 M210.906,82.304c0.413,1.606,0.798,3.215,1.154,4.829C211.706,85.519,211.322,83.91,210.906,82.304 M177.127,142.177
	c-0.589,0.066-1.131-0.367-1.229-0.987c-1.083-6.805-3.344-29.576-4.941-47.745c0.016,0.002,0.03,0.006,0.046,0.009l11.586,2.37
	l0.019,0.004c0.434,0.061,0.869,0.091,1.302,0.091c0.861,0,1.716-0.119,2.555-0.358l25.753-7.714
	c-0.044-0.204-0.09-0.407-0.134-0.611l-25.794,7.726c-0.58,0.165-1.169,0.269-1.764,0.31l-8.48-6.905
	c-0.393-0.32-0.649-0.798-0.703-1.312L173.7,71.501c-0.208,0.014-0.416,0.026-0.624,0.04l1.646,15.579
	c0.072,0.678,0.411,1.309,0.93,1.732l7.903,6.435c-0.282-0.012-0.567-0.036-0.851-0.076l-11.585-2.371l-0.019-0.004
	c-0.879-0.121-1.569-0.883-1.64-1.808l-1.466-19.133c-0.209,0.016-0.416,0.032-0.622,0.046l1.465,19.134
	c0.074,0.959,0.659,1.781,1.476,2.168c1.599,18.221,3.877,41.194,4.968,48.045c0.141,0.885,0.88,1.52,1.726,1.52
	c0.063,0,0.126-0.003,0.19-0.01c6.602-0.743,18.82-2.346,36.319-4.767c0.037-0.211,0.074-0.426,0.113-0.645
	C196.028,139.821,183.748,141.432,177.127,142.177"
      />
      <path
        fill={darkContrast}
        onClick={() => handleClick("contrast")}
        onMouseEnter={() => setDarkContrastActive(true)}
        onMouseLeave={() => setDarkContrastActive(false)}
        style={
          darkContrastActive && !preview
            ? {fill: adjust(darkContrast, 55)}
            : {fill: darkContrast}
        }
        d="M200.913,50.05l-0.015,0.02c-0.004,0.005-0.361,0.516-1.243,1.148c-0.147-0.286-0.297-0.571-0.445-0.857
	c1.064-1.364,0.723-2.217,0.679-2.311l-0.006-0.012c-0.053-0.104-5.399-10.717-8.747-29.112c-0.092-0.505-0.557-0.856-1.066-0.807
	l-1.402-0.003c-0.04-0.217-0.08-0.433-0.12-0.652c-0.011-0.057-0.027-0.112-0.046-0.164c0.702,0.007,1.287,0.03,1.296,0.03
	l1.851,0.004c0.369,0,0.408,0.207,0.421,0.285c3.316,18.209,9.237,30.253,9.475,30.734C201.564,48.411,201.734,49.02,200.913,50.05"
      />
      <path
        fill={darkContrast}
        onClick={() => handleClick("contrast")}
        onMouseEnter={() => setDarkContrastActive(true)}
        onMouseLeave={() => setDarkContrastActive(false)}
        style={
          darkContrastActive && !preview
            ? {fill: adjust(darkContrast, 55)}
            : {fill: darkContrast}
        }
        d="M183.475,53.957c-2.631,1.265-7.394,1-8.553,0.913l-3.454-34.083l7.404-2.081
	c0.203-0.041,0.404-0.083,0.606-0.123C179.911,22.411,183.159,51.152,183.475,53.957"
      />
      <path
        fill={darkContrast}
        onClick={() => handleClick("contrast")}
        onMouseEnter={() => setDarkContrastActive(true)}
        onMouseLeave={() => setDarkContrastActive(false)}
        style={
          darkContrastActive && !preview
            ? {fill: adjust(darkContrast, 55)}
            : {fill: darkContrast}
        }
        d="M151.314,60.207c-4.036,1.331-10.948,1.414-12.584,1.414h-0.063L136.293,31
	c4.42-1.199,8.67-2.423,12.792-3.684C149.385,31.758,151.126,57.431,151.314,60.207"
      />
      <path
        fill={darkContrast}
        onClick={() => handleClick("contrast")}
        onMouseEnter={() => setDarkContrastActive(true)}
        onMouseLeave={() => setDarkContrastActive(false)}
        style={
          darkContrastActive && !preview
            ? {fill: adjust(darkContrast, 55)}
            : {fill: darkContrast}
        }
        d="M109.618,68.853c-2.883,1.429-8,1.974-9.337,2.095l-1.33-29.028c2.43-0.763,4.861-1.508,7.189-2.221
	l2.386-0.659C108.652,42.46,109.529,66.402,109.618,68.853"
      />
      <rect
        x="157.024"
        y="84.564"
        transform="matrix(0.9965 -0.0834 0.0834 0.9965 -6.5344 13.4165)"
        width="0.419"
        height="0.63"
      />
      <path
        fill={darkContrast}
        onClick={() => handleClick("contrast")}
        onMouseEnter={() => setDarkContrastActive(true)}
        onMouseLeave={() => setDarkContrastActive(false)}
        style={
          darkContrastActive && !preview
            ? {fill: adjust(darkContrast, 55)}
            : {fill: darkContrast}
        }
        d="M153.132,74.017l-0.863-6.132c-0.067-0.473-0.445-0.842-0.919-0.898l-0.875-0.101l-28.161-2.193
	c-0.008-0.001-0.016-0.001-0.024-0.001c-0.115,0-0.193,0.068-0.23,0.11c-0.094,0.104-0.131,0.257-0.102,0.408l1.196,6.138
	c0.038,0.19,0.174,0.332,0.334,0.345l29.486,2.515c0.061-0.003,0.099-0.037,0.117-0.058
	C153.111,74.129,153.141,74.083,153.132,74.017 M151.175,70.712l-27.117-2.138l0.05-0.637l27.117,2.138L151.175,70.712z"
      />
      <path
        d="M153.763,73.928l-0.861-6.131c-0.108-0.76-0.715-1.354-1.478-1.442l-0.882-0.103l-28.179-2.194
	c-0.293-0.024-0.578,0.093-0.78,0.32c-0.224,0.252-0.319,0.608-0.252,0.954l1.197,6.139c0.09,0.469,0.464,0.823,0.907,0.858
	l29.502,2.517l0.027,0.002c0.234,0,0.456-0.102,0.608-0.279C153.726,74.393,153.795,74.16,153.763,73.928 M153.091,74.151
	c-0.018,0.021-0.056,0.054-0.117,0.058l-29.487-2.516c-0.159-0.013-0.295-0.155-0.333-0.345l-1.196-6.137
	c-0.029-0.151,0.008-0.305,0.102-0.408c0.037-0.043,0.115-0.11,0.23-0.11c0.008,0,0.016,0,0.024,0.001l28.161,2.193l0.875,0.101
	c0.474,0.055,0.852,0.424,0.919,0.898l0.863,6.131C153.141,74.084,153.111,74.13,153.091,74.151"
      />
      <rect
        x="137.322"
        y="55.725"
        transform="matrix(0.0783 -0.9969 0.9969 0.0783 57.7575 201.1193)"
        width="0.639"
        height="27.201"
      />
      <path
        fill={darkContrast}
        onClick={() => handleClick("contrast")}
        onMouseEnter={() => setDarkContrastActive(true)}
        onMouseLeave={() => setDarkContrastActive(false)}
        style={
          darkContrastActive && !preview
            ? {fill: adjust(darkContrast, 55)}
            : {fill: darkContrast}
        }
        d="M212.084,87.236l-25.794,7.726c-0.58,0.165-1.169,0.269-1.764,0.31l-8.48-6.905
	c-0.393-0.32-0.65-0.798-0.703-1.312L173.7,71.501c0.55-0.035,1.108-0.07,1.669-0.103c0.048-0.004,0.098-0.007,0.147-0.009
	c0.199-0.012,0.398-0.024,0.598-0.035c0.005-0.001,0.011-0.001,0.015-0.001c0.559-0.032,1.122-0.063,1.688-0.095
	c0.189-0.01,0.378-0.02,0.569-0.03c0.127-0.008,0.254-0.013,0.382-0.02c0.234-0.013,0.469-0.025,0.706-0.036
	c0.038-0.002,0.075-0.005,0.113-0.006c0.238-0.013,0.478-0.025,0.718-0.035c0.279-0.015,0.558-0.027,0.839-0.041
	c0.211-0.009,0.423-0.019,0.635-0.027l0.462-0.021c0.219-0.01,0.438-0.019,0.657-0.027c0.561-0.024,1.123-0.046,1.685-0.065
	c0.257-0.01,0.514-0.019,0.772-0.027c0.389-0.014,0.779-0.026,1.167-0.037c0.259-0.008,0.518-0.016,0.777-0.023
	s0.516-0.014,0.775-0.02c0.664-0.017,1.327-0.03,1.987-0.042c0.209-0.003,0.418-0.006,0.627-0.01
	c0.282-0.004,0.563-0.008,0.843-0.01c0.253-0.002,0.505-0.004,0.757-0.006c0.126-0.002,0.251-0.003,0.377-0.003
	c5.769-0.033,11.098,0.176,15.014,0.79c0.093,0.272,0.185,0.544,0.276,0.816c0.242,0.715,0.479,1.432,0.707,2.15
	c0.099,0.307,0.195,0.616,0.291,0.923c0.098,0.311,0.193,0.623,0.289,0.936c0.013,0.039,0.026,0.08,0.037,0.12
	c0.091,0.298,0.181,0.597,0.269,0.896c0.096,0.322,0.189,0.645,0.284,0.968c0,0.003,0.001,0.006,0.002,0.01
	c0.094,0.321,0.185,0.644,0.276,0.965c0.001,0.007,0.003,0.014,0.005,0.021c0.092,0.325,0.182,0.649,0.27,0.973
	c0.178,0.655,0.353,1.31,0.521,1.964c0.413,1.606,0.798,3.215,1.154,4.828C212.068,87.167,212.076,87.201,212.084,87.236"
      />
      <path
        fill="#E0E0E0"
        d="M183.555,95.287c-0.282-0.012-0.566-0.036-0.851-0.076l-11.585-2.371l-0.019-0.004
	c-0.879-0.121-1.568-0.883-1.64-1.808l-1.466-19.133c0.402-0.03,0.811-0.059,1.223-0.089c0.104-0.009,0.207-0.015,0.313-0.023
	c0.832-0.06,1.686-0.119,2.557-0.177c0.238-0.016,0.477-0.031,0.717-0.047c0.091-0.006,0.182-0.012,0.272-0.018l1.646,15.579
	c0.072,0.678,0.411,1.309,0.931,1.732L183.555,95.287z"
      />
      <polyline
        fill="none"
        stroke={stitching}
        onClick={() => handleClick("stitching")}
        onMouseEnter={() => setStitchingActive(true)}
        onMouseLeave={() => setStitchingActive(false)}
        style={
          stitchingActive && !preview
            ? {stroke: adjust(stitching, 55)}
            : {stroke: stitching}
        }
        strokeWidth="0.319"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        points="
	174.721,20.791 174.424,21.412 174.364,20.911 174.068,21.531 174.008,21.031 173.713,21.65 173.654,21.151 173.359,21.769 
	173.299,21.27 173.005,21.887 172.947,21.389 172.653,22.005 172.594,21.508 172.301,22.123 "
      />
      <polyline
        fill="none"
        stroke={stitching}
        onClick={() => handleClick("stitching")}
        onMouseEnter={() => setStitchingActive(true)}
        onMouseLeave={() => setStitchingActive(false)}
        style={
          stitchingActive && !preview
            ? {stroke: adjust(stitching, 55)}
            : {stroke: stitching}
        }
        strokeWidth="0.319"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        points="
	178.643,19.643 178.327,20.281 178.26,19.776 177.945,20.414 177.878,19.909 177.564,20.545 177.497,20.041 177.184,20.677 
	177.118,20.173 176.805,20.808 176.739,20.305 176.427,20.938 176.361,20.436 176.05,21.068 "
      />
      <line
        fill="none"
        stroke={stitching}
        onClick={() => handleClick("stitching")}
        onMouseEnter={() => setStitchingActive(true)}
        onMouseLeave={() => setStitchingActive(false)}
        style={
          stitchingActive && !preview
            ? {stroke: adjust(stitching, 55)}
            : {stroke: stitching}
        }
        strokeWidth="0.42"
        strokeMiterlimit="10"
        strokeDasharray="1.259,1.259"
        x1="87.176"
        y1="47.103"
        x2="97.79"
        y2="43.092"
      />
      <line
        fill="none"
        stroke={stitching}
        onClick={() => handleClick("stitching")}
        onMouseEnter={() => setStitchingActive(true)}
        onMouseLeave={() => setStitchingActive(false)}
        style={
          stitchingActive && !preview
            ? {stroke: adjust(stitching, 55)}
            : {stroke: stitching}
        }
        strokeWidth="0.42"
        strokeMiterlimit="10"
        strokeDasharray="1.259,1.259"
        x1="87.176"
        y1="63.193"
        x2="98.216"
        y2="60.047"
      />
      <path
        fill="none"
        stroke={stitching}
        onClick={() => handleClick("stitching")}
        onMouseEnter={() => setStitchingActive(true)}
        onMouseLeave={() => setStitchingActive(false)}
        style={
          stitchingActive && !preview
            ? {stroke: adjust(stitching, 55)}
            : {stroke: stitching}
        }
        strokeWidth="0.42"
        strokeMiterlimit="10"
        strokeDasharray="1.259,1.259"
        d="M110.468,56.779
	c0,0,22.729-6.076,26.217-6.526"
      />
      <path
        fill="none"
        stroke={stitching}
        onClick={() => handleClick("stitching")}
        onMouseEnter={() => setStitchingActive(true)}
        onMouseLeave={() => setStitchingActive(false)}
        style={
          stitchingActive && !preview
            ? {stroke: adjust(stitching, 55)}
            : {stroke: stitching}
        }
        strokeWidth="0.42"
        strokeMiterlimit="10"
        strokeDasharray="1.259,1.259"
        d="M109.905,39.676
	c0,0,40.089-10.708,45.032-12.746l15.503-4.469"
      />
      <path
        fill="none"
        stroke={stitching}
        onClick={() => handleClick("stitching")}
        onMouseEnter={() => setStitchingActive(true)}
        onMouseLeave={() => setStitchingActive(false)}
        style={
          stitchingActive && !preview
            ? {stroke: adjust(stitching, 55)}
            : {stroke: stitching}
        }
        strokeWidth="0.42"
        strokeMiterlimit="10"
        strokeDasharray="1.247,1.246"
        d="M154.08,48.361
	c0.404,5.026,3.199,39.542,6.603,77.013l2.056,24.563"
      />
      <path
        fill="none"
        stroke={stitching}
        onClick={() => handleClick("stitching")}
        onMouseEnter={() => setStitchingActive(true)}
        onMouseLeave={() => setStitchingActive(false)}
        style={
          stitchingActive && !preview
            ? {stroke: adjust(stitching, 55)}
            : {stroke: stitching}
        }
        strokeWidth="0.42"
        strokeMiterlimit="10"
        d="M211.286,489.91c0,0-0.026-0.213-0.077-0.625"
      />
      <line
        fill="none"
        stroke={stitching}
        onClick={() => handleClick("stitching")}
        onMouseEnter={() => setStitchingActive(true)}
        onMouseLeave={() => setStitchingActive(false)}
        style={
          stitchingActive && !preview
            ? {stroke: adjust(stitching, 55)}
            : {stroke: stitching}
        }
        strokeWidth="0.42"
        strokeMiterlimit="10"
        x1="211.054"
        y1="488.031"
        x2="211.053"
        y2="488.03"
      />
      <path
        fill="none"
        stroke={stitching}
        onClick={() => handleClick("stitching")}
        onMouseEnter={() => setStitchingActive(true)}
        onMouseLeave={() => setStitchingActive(false)}
        style={
          stitchingActive && !preview
            ? {stroke: adjust(stitching, 55)}
            : {stroke: stitching}
        }
        strokeWidth="0.42"
        strokeMiterlimit="10"
        strokeDasharray="1.265,1.264"
        d="M211.053,488.03
	c-2.05-16.455-17.2-136.521-28.368-182.771c-3.802-15.745-6.345-32.541-10.305-67.644"
      />
      <path
        fill="none"
        stroke={stitching}
        onClick={() => handleClick("stitching")}
        onMouseEnter={() => setStitchingActive(true)}
        onMouseLeave={() => setStitchingActive(false)}
        style={
          stitchingActive && !preview
            ? {stroke: adjust(stitching, 55)}
            : {stroke: stitching}
        }
        strokeWidth="0.42"
        strokeMiterlimit="10"
        d="M94.524,169.599
	c-0.007,0.18-0.019,0.388-0.036,0.628"
      />
      <line
        fill="none"
        stroke={stitching}
        onClick={() => handleClick("stitching")}
        onMouseEnter={() => setStitchingActive(true)}
        onMouseLeave={() => setStitchingActive(false)}
        style={
          stitchingActive && !preview
            ? {stroke: adjust(stitching, 55)}
            : {stroke: stitching}
        }
        strokeWidth="0.42"
        strokeMiterlimit="10"
        x1="94.375"
        y1="171.481"
        x2="94.374"
        y2="171.482"
      />
      <path
        fill="none"
        stroke={stitching}
        onClick={() => handleClick("stitching")}
        onMouseEnter={() => setStitchingActive(true)}
        onMouseLeave={() => setStitchingActive(false)}
        style={
          stitchingActive && !preview
            ? {stroke: adjust(stitching, 55)}
            : {stroke: stitching}
        }
        strokeWidth="0.42"
        strokeMiterlimit="10"
        strokeDasharray="1.26,1.259"
        d="M94.374,171.482
	c-0.395,3.679-1.705,11.75-5.831,28.94c-10.576,24.192-18.228,83.715-18.228,83.715s-0.45,25.991-4.725,56.146
	c-3.822,26.957-2.358,104.425-2.004,120.553"
      />
      <path
        fill="none"
        stroke={stitching}
        onClick={() => handleClick("stitching")}
        onMouseEnter={() => setStitchingActive(true)}
        onMouseLeave={() => setStitchingActive(false)}
        style={
          stitchingActive && !preview
            ? {stroke: adjust(stitching, 55)}
            : {stroke: stitching}
        }
        strokeWidth="0.42"
        strokeMiterlimit="10"
        d="M63.586,460.837
	c0.005,0.222,0.01,0.432,0.015,0.629"
      />
      <path
        fill="none"
        stroke={stitching}
        onClick={() => handleClick("stitching")}
        onMouseEnter={() => setStitchingActive(true)}
        onMouseLeave={() => setStitchingActive(false)}
        style={
          stitchingActive && !preview
            ? {stroke: adjust(stitching, 55)}
            : {stroke: stitching}
        }
        strokeWidth="0.42"
        strokeMiterlimit="10"
        d="M95.65,173.94c-0.029,0.2-0.06,0.407-0.092,0.623
	"
      />
      <line
        fill="none"
        stroke={stitching}
        onClick={() => handleClick("stitching")}
        onMouseEnter={() => setStitchingActive(true)}
        onMouseLeave={() => setStitchingActive(false)}
        style={
          stitchingActive && !preview
            ? {stroke: adjust(stitching, 55)}
            : {stroke: stitching}
        }
        strokeWidth="0.42"
        strokeMiterlimit="10"
        x1="95.362"
        y1="175.809"
        x2="95.362"
        y2="175.81"
      />
      <path
        fill="none"
        stroke={stitching}
        onClick={() => handleClick("stitching")}
        onMouseEnter={() => setStitchingActive(true)}
        onMouseLeave={() => setStitchingActive(false)}
        style={
          stitchingActive && !preview
            ? {stroke: adjust(stitching, 55)}
            : {stroke: stitching}
        }
        strokeWidth="0.42"
        strokeMiterlimit="10"
        strokeDasharray="1.263,1.262"
        d="M95.362,175.81
	c-0.76,4.686-2.271,12.338-5.244,24.725c-10.576,24.191-18.228,83.714-18.228,83.714s-0.45,25.991-4.725,56.146
	c-3.811,26.875-2.366,103.951-2.007,120.404"
      />
      <path
        fill="none"
        stroke={stitching}
        onClick={() => handleClick("stitching")}
        onMouseEnter={() => setStitchingActive(true)}
        onMouseLeave={() => setStitchingActive(false)}
        style={
          stitchingActive && !preview
            ? {stroke: adjust(stitching, 55)}
            : {stroke: stitching}
        }
        strokeWidth="0.42"
        strokeMiterlimit="10"
        d="M65.158,460.8
	c0.005,0.221,0.01,0.431,0.014,0.629"
      />
      <path
        fill="none"
        stroke={stitching}
        onClick={() => handleClick("stitching")}
        onMouseEnter={() => setStitchingActive(true)}
        onMouseLeave={() => setStitchingActive(false)}
        style={
          stitchingActive && !preview
            ? {stroke: adjust(stitching, 55)}
            : {stroke: stitching}
        }
        strokeWidth="0.42"
        strokeMiterlimit="10"
        d="M96.413,176.82
	c-0.034,0.202-0.07,0.409-0.107,0.62"
      />
      <line
        fill="none"
        stroke={stitching}
        onClick={() => handleClick("stitching")}
        onMouseEnter={() => setStitchingActive(true)}
        onMouseLeave={() => setStitchingActive(false)}
        style={
          stitchingActive && !preview
            ? {stroke: adjust(stitching, 55)}
            : {stroke: stitching}
        }
        strokeWidth="0.42"
        strokeMiterlimit="10"
        x1="96.085"
        y1="178.681"
        x2="96.084"
        y2="178.682"
      />
      <path
        fill="none"
        stroke={stitching}
        onClick={() => handleClick("stitching")}
        onMouseEnter={() => setStitchingActive(true)}
        onMouseLeave={() => setStitchingActive(false)}
        style={
          stitchingActive && !preview
            ? {stroke: adjust(stitching, 55)}
            : {stroke: stitching}
        }
        strokeWidth="0.42"
        strokeMiterlimit="10"
        strokeDasharray="1.261,1.26"
        d="M96.084,178.682
	c-0.867,4.761-2.316,11.693-4.728,21.74c-10.577,24.192-18.228,83.715-18.228,83.715s-0.45,25.991-4.726,56.146
	c-3.813,26.896-2.363,104.068-2.005,120.441"
      />
      <path
        fill="none"
        stroke={stitching}
        onClick={() => handleClick("stitching")}
        onMouseEnter={() => setStitchingActive(true)}
        onMouseLeave={() => setStitchingActive(false)}
        style={
          stitchingActive && !preview
            ? {stroke: adjust(stitching, 55)}
            : {stroke: stitching}
        }
        strokeWidth="0.42"
        strokeMiterlimit="10"
        d="M66.396,460.725
	c0.005,0.221,0.01,0.431,0.015,0.629"
      />
      <path
        fill="none"
        stroke={stitching}
        onClick={() => handleClick("stitching")}
        onMouseEnter={() => setStitchingActive(true)}
        onMouseLeave={() => setStitchingActive(false)}
        style={
          stitchingActive && !preview
            ? {stroke: adjust(stitching, 55)}
            : {stroke: stitching}
        }
        strokeWidth="0.42"
        strokeMiterlimit="10"
        strokeDasharray="1.259,1.259"
        d="M180.792,19.76
	c0,0,1.672-0.246,7.708-0.82"
      />
      <path
        fill="none"
        stroke={stitching}
        onClick={() => handleClick("stitching")}
        onMouseEnter={() => setStitchingActive(true)}
        onMouseLeave={() => setStitchingActive(false)}
        style={
          stitchingActive && !preview
            ? {stroke: adjust(stitching, 55)}
            : {stroke: stitching}
        }
        strokeWidth="0.42"
        strokeMiterlimit="10"
        d="M155.276,478.155c0,0,0.214,0.042,0.618,0.116"
      />
      <line
        fill="none"
        stroke={stitching}
        onClick={() => handleClick("stitching")}
        onMouseEnter={() => setStitchingActive(true)}
        onMouseLeave={() => setStitchingActive(false)}
        style={
          stitchingActive && !preview
            ? {stroke: adjust(stitching, 55)}
            : {stroke: stitching}
        }
        strokeWidth="0.42"
        strokeMiterlimit="10"
        x1="157.154"
        y1="478.49"
        x2="157.154"
        y2="478.49"
      />
      <path
        fill="none"
        stroke={stitching}
        onClick={() => handleClick("stitching")}
        onMouseEnter={() => setStitchingActive(true)}
        onMouseLeave={() => setStitchingActive(false)}
        style={
          stitchingActive && !preview
            ? {stroke: adjust(stitching, 55)}
            : {stroke: stitching}
        }
        strokeWidth="0.42"
        strokeMiterlimit="10"
        strokeDasharray="1.279,1.278"
        d="M157.154,478.49
	c6.856,1.146,31.156,4.515,50.76-1.439"
      />
      <path
        fill="none"
        stroke={stitching}
        onClick={() => handleClick("stitching")}
        onMouseEnter={() => setStitchingActive(true)}
        onMouseLeave={() => setStitchingActive(false)}
        style={
          stitchingActive && !preview
            ? {stroke: adjust(stitching, 55)}
            : {stroke: stitching}
        }
        strokeWidth="0.42"
        strokeMiterlimit="10"
        d="M207.915,477.05
	c0.201-0.061,0.401-0.123,0.602-0.186"
      />
      <line
        fill="none"
        stroke={stitching}
        onClick={() => handleClick("stitching")}
        onMouseEnter={() => setStitchingActive(true)}
        onMouseLeave={() => setStitchingActive(false)}
        style={
          stitchingActive && !preview
            ? {stroke: adjust(stitching, 55)}
            : {stroke: stitching}
        }
        strokeWidth="0.42"
        strokeMiterlimit="10"
        x1="215.088"
        y1="475"
        x2="215.089"
        y2="474.999"
      />
      <path
        fill="none"
        stroke={stitching}
        onClick={() => handleClick("stitching")}
        onMouseEnter={() => setStitchingActive(true)}
        onMouseLeave={() => setStitchingActive(false)}
        style={
          stitchingActive && !preview
            ? {stroke: adjust(stitching, 55)}
            : {stroke: stitching}
        }
        strokeWidth="0.42"
        strokeMiterlimit="10"
        strokeDasharray="1.211,1.21"
        d="M210.609,476.362
	c3.8-1.158,17.168-5.299,20.496-6.909"
      />
      <path
        fill="none"
        stroke={stitching}
        onClick={() => handleClick("stitching")}
        onMouseEnter={() => setStitchingActive(true)}
        onMouseLeave={() => setStitchingActive(false)}
        style={
          stitchingActive && !preview
            ? {stroke: adjust(stitching, 55)}
            : {stroke: stitching}
        }
        strokeWidth="0.42"
        strokeMiterlimit="10"
        d="M231.105,469.453
	c0.21-0.101,0.397-0.197,0.56-0.289"
      />
      <path
        fill="none"
        stroke={stitching}
        onClick={() => handleClick("stitching")}
        onMouseEnter={() => setStitchingActive(true)}
        onMouseLeave={() => setStitchingActive(false)}
        style={
          stitchingActive && !preview
            ? {stroke: adjust(stitching, 55)}
            : {stroke: stitching}
        }
        strokeWidth="0.42"
        strokeMiterlimit="10"
        strokeDasharray="1.259,1.259"
        d="M21.185,446.455
	c0,0,19.61,7.187,41.346,4.292"
      />
      <path
        fill="none"
        stroke={stitching}
        onClick={() => handleClick("stitching")}
        onMouseEnter={() => setStitchingActive(true)}
        onMouseLeave={() => setStitchingActive(false)}
        style={
          stitchingActive && !preview
            ? {stroke: adjust(stitching, 55)}
            : {stroke: stitching}
        }
        strokeWidth="0.42"
        strokeMiterlimit="10"
        strokeDasharray="1.259,1.259"
        d="M67.165,450.215
	c0,0,20.621-1.3,29.214-4.363"
      />
      <line
        fill="none"
        stroke={stitching}
        onClick={() => handleClick("stitching")}
        onMouseEnter={() => setStitchingActive(true)}
        onMouseLeave={() => setStitchingActive(false)}
        style={
          stitchingActive && !preview
            ? {stroke: adjust(stitching, 55)}
            : {stroke: stitching}
        }
        strokeWidth="0.319"
        strokeMiterlimit="10"
        x1="96.067"
        y1="62.936"
        x2="96.081"
        y2="63.415"
      />
      <line
        fill="none"
        stroke={stitching}
        onClick={() => handleClick("stitching")}
        onMouseEnter={() => setStitchingActive(true)}
        onMouseLeave={() => setStitchingActive(false)}
        style={
          stitchingActive && !preview
            ? {stroke: adjust(stitching, 55)}
            : {stroke: stitching}
        }
        strokeWidth="0.319"
        strokeMiterlimit="10"
        x1="96.106"
        y1="64.362"
        x2="96.106"
        y2="64.363"
      />
      <path
        fill="none"
        stroke={stitching}
        onClick={() => handleClick("stitching")}
        onMouseEnter={() => setStitchingActive(true)}
        onMouseLeave={() => setStitchingActive(false)}
        style={
          stitchingActive && !preview
            ? {stroke: adjust(stitching, 55)}
            : {stroke: stitching}
        }
        strokeWidth="0.319"
        strokeMiterlimit="10"
        strokeDasharray="0.949,0.948"
        d="M96.106,64.363
	l1.551,56.17c0,0-0.32,12.345-8.254,14.618"
      />
      <path
        fill="none"
        stroke={stitching}
        onClick={() => handleClick("stitching")}
        onMouseEnter={() => setStitchingActive(true)}
        onMouseLeave={() => setStitchingActive(false)}
        style={
          stitchingActive && !preview
            ? {stroke: adjust(stitching, 55)}
            : {stroke: stitching}
        }
        strokeWidth="0.319"
        strokeMiterlimit="10"
        d="M89.403,135.151
	c-0.151,0.043-0.306,0.083-0.463,0.119"
      />
      <path
        fill="none"
        stroke={stitching}
        onClick={() => handleClick("stitching")}
        onMouseEnter={() => setStitchingActive(true)}
        onMouseLeave={() => setStitchingActive(false)}
        style={
          stitchingActive && !preview
            ? {stroke: adjust(stitching, 55)}
            : {stroke: stitching}
        }
        strokeWidth="0.424"
        strokeMiterlimit="10"
        strokeDasharray="1.271,1.271"
        d="
	M171.589,243.367c0,0-29.102,7.028-56.748,7.943"
      />
      <path
        fill="none"
        stroke={stitching}
        onClick={() => handleClick("stitching")}
        onMouseEnter={() => setStitchingActive(true)}
        onMouseLeave={() => setStitchingActive(false)}
        style={
          stitchingActive && !preview
            ? {stroke: adjust(stitching, 55)}
            : {stroke: stitching}
        }
        strokeWidth="0.424"
        strokeMiterlimit="10"
        strokeDasharray="1.271,1.271"
        d="
	M172.257,245.249c0,0-29.102,7.028-56.748,7.943"
      />
      <path
        fill="none"
        stroke={stitching}
        onClick={() => handleClick("stitching")}
        onMouseEnter={() => setStitchingActive(true)}
        onMouseLeave={() => setStitchingActive(false)}
        style={
          stitchingActive && !preview
            ? {stroke: adjust(stitching, 55)}
            : {stroke: stitching}
        }
        strokeWidth="0.424"
        strokeMiterlimit="10"
        strokeDasharray="1.271,1.271"
        d="
	M175.054,267.077c0,0-26.926,6.512-54.572,7.427"
      />
      <path
        fill="none"
        stroke={stitching}
        onClick={() => handleClick("stitching")}
        onMouseEnter={() => setStitchingActive(true)}
        onMouseLeave={() => setStitchingActive(false)}
        style={
          stitchingActive && !preview
            ? {stroke: adjust(stitching, 55)}
            : {stroke: stitching}
        }
        strokeWidth="0.42"
        strokeMiterlimit="10"
        strokeDasharray="1.259,1.259"
        d="M76.504,239.804
	c0,0-25.524-0.302-50.879-5.381"
      />
      <path
        fill="none"
        stroke={stitching}
        onClick={() => handleClick("stitching")}
        onMouseEnter={() => setStitchingActive(true)}
        onMouseLeave={() => setStitchingActive(false)}
        style={
          stitchingActive && !preview
            ? {stroke: adjust(stitching, 55)}
            : {stroke: stitching}
        }
        strokeWidth="0.42"
        strokeMiterlimit="10"
        strokeDasharray="1.259,1.259"
        d="M76.054,241.829
	c0,0-25.422-0.347-50.998-5.568"
      />
      <path
        fill="none"
        stroke={stitching}
        onClick={() => handleClick("stitching")}
        onMouseEnter={() => setStitchingActive(true)}
        onMouseLeave={() => setStitchingActive(false)}
        style={
          stitchingActive && !preview
            ? {stroke: adjust(stitching, 55)}
            : {stroke: stitching}
        }
        strokeWidth="0.42"
        strokeMiterlimit="10"
        strokeDasharray="1.259,1.259"
        d="M71.44,260.058
	c0,0-25.422-0.347-50.998-5.568"
      />
      <path
        fill="none"
        stroke={stitching}
        onClick={() => handleClick("stitching")}
        onMouseEnter={() => setStitchingActive(true)}
        onMouseLeave={() => setStitchingActive(false)}
        style={
          stitchingActive && !preview
            ? {stroke: adjust(stitching, 55)}
            : {stroke: stitching}
        }
        strokeWidth="0.442"
        strokeMiterlimit="10"
        strokeDasharray="1.326,1.326"
        d="M191.658,359.66
	c0,0-31.408,5.779-60.938,5.513"
      />
      <path
        fill="none"
        stroke={stitching}
        onClick={() => handleClick("stitching")}
        onMouseEnter={() => setStitchingActive(true)}
        onMouseLeave={() => setStitchingActive(false)}
        style={
          stitchingActive && !preview
            ? {stroke: adjust(stitching, 55)}
            : {stroke: stitching}
        }
        strokeWidth="0.442"
        strokeMiterlimit="10"
        strokeDasharray="1.326,1.326"
        d="
	M192.269,361.569c0,0-31.407,5.778-60.937,5.512"
      />
      <path
        fill="none"
        stroke={stitching}
        onClick={() => handleClick("stitching")}
        onMouseEnter={() => setStitchingActive(true)}
        onMouseLeave={() => setStitchingActive(false)}
        style={
          stitchingActive && !preview
            ? {stroke: adjust(stitching, 55)}
            : {stroke: stitching}
        }
        strokeWidth="0.442"
        strokeMiterlimit="10"
        strokeDasharray="1.325,1.325"
        d="M63.564,351.396
	c0,0-21.193,1.365-45.381-0.276"
      />
      <path
        fill="none"
        stroke={stitching}
        onClick={() => handleClick("stitching")}
        onMouseEnter={() => setStitchingActive(true)}
        onMouseLeave={() => setStitchingActive(false)}
        style={
          stitchingActive && !preview
            ? {stroke: adjust(stitching, 55)}
            : {stroke: stitching}
        }
        strokeWidth="0.442"
        strokeMiterlimit="10"
        strokeDasharray="1.325,1.325"
        d="M63.339,353.309
	c0,0-20.788,1.434-45.107-0.244"
      />
      <path
        fill="none"
        stroke={stitching}
        onClick={() => handleClick("stitching")}
        onMouseEnter={() => setStitchingActive(true)}
        onMouseLeave={() => setStitchingActive(false)}
        style={
          stitchingActive && !preview
            ? {stroke: adjust(stitching, 55)}
            : {stroke: stitching}
        }
        strokeWidth="0.624"
        strokeMiterlimit="10"
        d="M213.909,137.662
	c0.04-0.005,0.081-0.011,0.121-0.017"
      />
      <path
        fill="none"
        stroke={stitching}
        onClick={() => handleClick("stitching")}
        onMouseEnter={() => setStitchingActive(true)}
        onMouseLeave={() => setStitchingActive(false)}
        style={
          stitchingActive && !preview
            ? {stroke: adjust(stitching, 55)}
            : {stroke: stitching}
        }
        strokeWidth="0.319"
        strokeMiterlimit="10"
        strokeDasharray="0.96,0.958"
        d="M214.03,135.94
	c0.056-0.007,0.111-0.015,0.167-0.023"
      />
      <path
        fill="none"
        stroke={stitching}
        onClick={() => handleClick("stitching")}
        onMouseEnter={() => setStitchingActive(true)}
        onMouseLeave={() => setStitchingActive(false)}
        style={
          stitchingActive && !preview
            ? {stroke: adjust(stitching, 55)}
            : {stroke: stitching}
        }
        strokeWidth="0.319"
        strokeMiterlimit="10"
        strokeDasharray="0.96,0.958"
        d="M172.327,94.095
	c1.494,17.037,3.701,38.575,4.802,46.043c0.052,0.36,0.381,0.611,0.741,0.57c8.064-0.93,22.513-2.878,36.16-4.767"
      />
      <line
        fill="none"
        stroke={stitching}
        onClick={() => handleClick("stitching")}
        onMouseEnter={() => setStitchingActive(true)}
        onMouseLeave={() => setStitchingActive(false)}
        style={
          stitchingActive && !preview
            ? {stroke: adjust(stitching, 55)}
            : {stroke: stitching}
        }
        strokeWidth="0.319"
        strokeMiterlimit="10"
        strokeDasharray="0.96,0.958"
        x1="184.023"
        y1="94.869"
        x2="212.143"
        y2="86.121"
      />
      <line
        fill="none"
        stroke={stitching}
        onClick={() => handleClick("stitching")}
        onMouseEnter={() => setStitchingActive(true)}
        onMouseLeave={() => setStitchingActive(false)}
        style={
          stitchingActive && !preview
            ? {stroke: adjust(stitching, 55)}
            : {stroke: stitching}
        }
        strokeWidth="0.319"
        strokeMiterlimit="10"
        strokeDasharray="0.96,0.958"
        x1="182.038"
        y1="93.267"
        x2="211.655"
        y2="84.054"
      />
      <path
        fill="none"
        stroke={stitching}
        onClick={() => handleClick("stitching")}
        onMouseEnter={() => setStitchingActive(true)}
        onMouseLeave={() => setStitchingActive(false)}
        style={
          stitchingActive && !preview
            ? {stroke: adjust(stitching, 55)}
            : {stroke: stitching}
        }
        strokeWidth="0.319"
        strokeMiterlimit="10"
        strokeDasharray="0.96,0.958"
        d="M169.023,72.441
	l1.432,17.692c0.057,0.691,0.418,1.612,1.096,1.753l9.146,2.097"
      />
      <path
        fill="none"
        stroke={stitching}
        onClick={() => handleClick("stitching")}
        onMouseEnter={() => setStitchingActive(true)}
        onMouseLeave={() => setStitchingActive(false)}
        style={
          stitchingActive && !preview
            ? {stroke: adjust(stitching, 55)}
            : {stroke: stitching}
        }
        strokeWidth="0.319"
        strokeMiterlimit="10"
        strokeDasharray="0.96,0.958"
        d="M172.194,72.146
	l1.645,15.434c0.055,0.526,0.317,1.009,0.727,1.343l5.283,4.315"
      />
      <path
        fill="none"
        stroke={stitching}
        onClick={() => handleClick("stitching")}
        onMouseEnter={() => setStitchingActive(true)}
        onMouseLeave={() => setStitchingActive(false)}
        style={
          stitchingActive && !preview
            ? {stroke: adjust(stitching, 55)}
            : {stroke: stitching}
        }
        strokeWidth="0.319"
        strokeMiterlimit="10"
        strokeDasharray="0.96,0.958"
        d="M212.299,329.746
	c-6.59,1.362-15.635,2.921-22.899,3.999"
      />
      <path
        fill="none"
        stroke={stitching}
        onClick={() => handleClick("stitching")}
        onMouseEnter={() => setStitchingActive(true)}
        onMouseLeave={() => setStitchingActive(false)}
        style={
          stitchingActive && !preview
            ? {stroke: adjust(stitching, 55)}
            : {stroke: stitching}
        }
        strokeWidth="0.319"
        strokeMiterlimit="10"
        strokeDasharray="0.96,0.958"
        d="M210.328,324.631
	c-6.471,1.343-15.376,2.988-22.462,4.053"
      />
      <path
        fill="none"
        stroke={stitching}
        onClick={() => handleClick("stitching")}
        onMouseEnter={() => setStitchingActive(true)}
        onMouseLeave={() => setStitchingActive(false)}
        style={
          stitchingActive && !preview
            ? {stroke: adjust(stitching, 55)}
            : {stroke: stitching}
        }
        strokeWidth="0.319"
        strokeMiterlimit="10"
        strokeDasharray="1.276,0.957"
        d="
	M119.185,158.168l3.459,22.591c0.076,0.5,0.442,0.906,0.925,1.024l5.332,1.317c0.941,0.233,1.916,0.279,2.87,0.138l51.212-7.569
	l2.206-5.136c0.298-0.693,0.394-1.46,0.279-2.212l-2.987-19.508L119.185,158.168z"
      />
      <path
        fill="none"
        stroke={stitching}
        onClick={() => handleClick("stitching")}
        onMouseEnter={() => setStitchingActive(true)}
        onMouseLeave={() => setStitchingActive(false)}
        style={
          stitchingActive && !preview
            ? {stroke: adjust(stitching, 55)}
            : {stroke: stitching}
        }
        strokeWidth="0.319"
        strokeMiterlimit="10"
        strokeDasharray="1.276,0.957"
        d="
	M185.694,148.337l3.21,20.97c0.072,0.467,0.018,0.945-0.157,1.381l-1.757,4.388l6.12-3.333c0.646-0.352,1.001-1.083,0.888-1.826
	l-3.415-22.303L185.694,148.337z"
      />
      <line
        fill="none"
        stroke={stitching}
        onClick={() => handleClick("stitching")}
        onMouseEnter={() => setStitchingActive(true)}
        onMouseLeave={() => setStitchingActive(false)}
        style={
          stitchingActive && !preview
            ? {stroke: adjust(stitching, 55)}
            : {stroke: stitching}
        }
        strokeWidth="0.309"
        strokeMiterlimit="10"
        strokeDasharray="1.238,0.928"
        x1="134.278"
        y1="196.382"
        x2="141.188"
        y2="241.685"
      />
      <line
        fill="none"
        stroke={stitching}
        onClick={() => handleClick("stitching")}
        onMouseEnter={() => setStitchingActive(true)}
        onMouseLeave={() => setStitchingActive(false)}
        style={
          stitchingActive && !preview
            ? {stroke: adjust(stitching, 55)}
            : {stroke: stitching}
        }
        strokeWidth="0.309"
        strokeMiterlimit="10"
        strokeDasharray="1.238,0.928"
        x1="126.067"
        y1="205.145"
        x2="145.072"
        y2="202.337"
      />
      <polyline
        fill="none"
        stroke={stitching}
        onClick={() => handleClick("stitching")}
        onMouseEnter={() => setStitchingActive(true)}
        onMouseLeave={() => setStitchingActive(false)}
        style={
          stitchingActive && !preview
            ? {stroke: adjust(stitching, 55)}
            : {stroke: stitching}
        }
        strokeWidth="0.233"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        points="
	143.957,201.669 144.718,200.555 143.841,200.842 144.602,199.729 143.725,200.015 144.485,198.902 143.608,199.188 
	144.369,198.076 143.492,198.361 144.253,197.251 143.376,197.535 144.138,196.425 143.26,196.709 144.021,195.6 "
      />
      <polyline
        fill="none"
        stroke={stitching}
        onClick={() => handleClick("stitching")}
        onMouseEnter={() => setStitchingActive(true)}
        onMouseLeave={() => setStitchingActive(false)}
        style={
          stitchingActive && !preview
            ? {stroke: adjust(stitching, 55)}
            : {stroke: stitching}
        }
        strokeWidth="0.233"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        points="
	134.755,203.031 135.517,201.917 134.639,202.203 135.401,201.09 134.523,201.376 135.284,200.263 134.406,200.549 135.168,199.437 
	134.29,199.722 135.052,198.611 134.174,198.895 134.936,197.785 134.059,198.069 134.819,196.96 "
      />
      <polyline
        fill="none"
        stroke={stitching}
        onClick={() => handleClick("stitching")}
        onMouseEnter={() => setStitchingActive(true)}
        onMouseLeave={() => setStitchingActive(false)}
        style={
          stitchingActive && !preview
            ? {stroke: adjust(stitching, 55)}
            : {stroke: stitching}
        }
        strokeWidth="0.233"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        points="
	126.216,204.29 126.978,203.177 126.1,203.463 126.861,202.35 125.984,202.635 126.744,201.523 125.867,201.81 126.629,200.698 
	125.751,200.983 126.513,199.872 125.635,200.157 126.397,199.047 125.519,199.331 126.28,198.222 "
      />
      <path
        fill="none"
        stroke={stitching}
        onClick={() => handleClick("stitching")}
        onMouseEnter={() => setStitchingActive(true)}
        onMouseLeave={() => setStitchingActive(false)}
        style={
          stitchingActive && !preview
            ? {stroke: adjust(stitching, 55)}
            : {stroke: stitching}
        }
        strokeWidth="0.42"
        strokeMiterlimit="10"
        strokeDasharray="1.247,1.246"
        d="M156.939,70.957
	c9.984-0.914,37.779-2.952,50.324-1.13"
      />
      <path
        fill="none"
        stroke={stitching}
        onClick={() => handleClick("stitching")}
        onMouseEnter={() => setStitchingActive(true)}
        onMouseLeave={() => setStitchingActive(false)}
        style={
          stitchingActive && !preview
            ? {stroke: adjust(stitching, 55)}
            : {stroke: stitching}
        }
        strokeWidth="0.42"
        strokeMiterlimit="10"
        strokeDasharray="1.247,1.246"
        d="M156.713,69.269
	c9.67-0.889,37.065-3.121,49.912-1.443"
      />
      <line
        fill="none"
        stroke={stitching}
        onClick={() => handleClick("stitching")}
        onMouseEnter={() => setStitchingActive(true)}
        onMouseLeave={() => setStitchingActive(false)}
        style={
          stitchingActive && !preview
            ? {stroke: adjust(stitching, 55)}
            : {stroke: stitching}
        }
        strokeWidth="0.319"
        strokeMiterlimit="10"
        x1="99.243"
        y1="41.4"
        x2="99.263"
        y2="41.827"
      />
      <line
        fill="none"
        stroke={stitching}
        onClick={() => handleClick("stitching")}
        onMouseEnter={() => setStitchingActive(true)}
        onMouseLeave={() => setStitchingActive(false)}
        style={
          stitchingActive && !preview
            ? {stroke: adjust(stitching, 55)}
            : {stroke: stitching}
        }
        strokeWidth="0.319"
        strokeMiterlimit="10"
        x1="99.301"
        y1="42.686"
        x2="99.301"
        y2="42.687"
      />
      <line
        fill="none"
        stroke={stitching}
        onClick={() => handleClick("stitching")}
        onMouseEnter={() => setStitchingActive(true)}
        onMouseLeave={() => setStitchingActive(false)}
        style={
          stitchingActive && !preview
            ? {stroke: adjust(stitching, 55)}
            : {stroke: stitching}
        }
        strokeWidth="0.319"
        strokeMiterlimit="10"
        strokeDasharray="0.963,0.962"
        x1="99.301"
        y1="42.687"
        x2="100.526"
        y2="70.84"
      />
      <line
        fill="none"
        stroke={stitching}
        onClick={() => handleClick("stitching")}
        onMouseEnter={() => setStitchingActive(true)}
        onMouseLeave={() => setStitchingActive(false)}
        style={
          stitchingActive && !preview
            ? {stroke: adjust(stitching, 55)}
            : {stroke: stitching}
        }
        strokeWidth="0.319"
        strokeMiterlimit="10"
        x1="100.525"
        y1="70.84"
        x2="100.544"
        y2="71.268"
      />
      <line
        fill="none"
        stroke={stitching}
        onClick={() => handleClick("stitching")}
        onMouseEnter={() => setStitchingActive(true)}
        onMouseLeave={() => setStitchingActive(false)}
        style={
          stitchingActive && !preview
            ? {stroke: adjust(stitching, 55)}
            : {stroke: stitching}
        }
        strokeWidth="0.319"
        strokeMiterlimit="10"
        x1="103.558"
        y1="40.263"
        x2="103.578"
        y2="40.691"
      />
      <line
        fill="none"
        stroke={stitching}
        onClick={() => handleClick("stitching")}
        onMouseEnter={() => setStitchingActive(true)}
        onMouseLeave={() => setStitchingActive(false)}
        style={
          stitchingActive && !preview
            ? {stroke: adjust(stitching, 55)}
            : {stroke: stitching}
        }
        strokeWidth="0.319"
        strokeMiterlimit="10"
        x1="103.615"
        y1="41.551"
        x2="103.615"
        y2="41.552"
      />
      <line
        fill="none"
        stroke={stitching}
        onClick={() => handleClick("stitching")}
        onMouseEnter={() => setStitchingActive(true)}
        onMouseLeave={() => setStitchingActive(false)}
        style={
          stitchingActive && !preview
            ? {stroke: adjust(stitching, 55)}
            : {stroke: stitching}
        }
        strokeWidth="0.319"
        strokeMiterlimit="10"
        strokeDasharray="0.963,0.962"
        x1="103.615"
        y1="41.552"
        x2="104.844"
        y2="69.763"
      />
      <line
        fill="none"
        stroke={stitching}
        onClick={() => handleClick("stitching")}
        onMouseEnter={() => setStitchingActive(true)}
        onMouseLeave={() => setStitchingActive(false)}
        style={
          stitchingActive && !preview
            ? {stroke: adjust(stitching, 55)}
            : {stroke: stitching}
        }
        strokeWidth="0.319"
        strokeMiterlimit="10"
        x1="104.845"
        y1="69.763"
        x2="104.865"
        y2="70.192"
      />
      <line
        fill="none"
        stroke={stitching}
        onClick={() => handleClick("stitching")}
        onMouseEnter={() => setStitchingActive(true)}
        onMouseLeave={() => setStitchingActive(false)}
        style={
          stitchingActive && !preview
            ? {stroke: adjust(stitching, 55)}
            : {stroke: stitching}
        }
        strokeWidth="0.319"
        strokeMiterlimit="10"
        x1="108.027"
        y1="39.19"
        x2="108.046"
        y2="39.619"
      />
      <line
        fill="none"
        stroke={stitching}
        onClick={() => handleClick("stitching")}
        onMouseEnter={() => setStitchingActive(true)}
        onMouseLeave={() => setStitchingActive(false)}
        style={
          stitchingActive && !preview
            ? {stroke: adjust(stitching, 55)}
            : {stroke: stitching}
        }
        strokeWidth="0.319"
        strokeMiterlimit="10"
        x1="108.085"
        y1="40.481"
        x2="108.085"
        y2="40.482"
      />
      <line
        fill="none"
        stroke={stitching}
        onClick={() => handleClick("stitching")}
        onMouseEnter={() => setStitchingActive(true)}
        onMouseLeave={() => setStitchingActive(false)}
        style={
          stitchingActive && !preview
            ? {stroke: adjust(stitching, 55)}
            : {stroke: stitching}
        }
        strokeWidth="0.319"
        strokeMiterlimit="10"
        strokeDasharray="0.963,0.962"
        x1="108.085"
        y1="40.482"
        x2="109.319"
        y2="68.753"
      />
      <line
        fill="none"
        stroke={stitching}
        onClick={() => handleClick("stitching")}
        onMouseEnter={() => setStitchingActive(true)}
        onMouseLeave={() => setStitchingActive(false)}
        style={
          stitchingActive && !preview
            ? {stroke: adjust(stitching, 55)}
            : {stroke: stitching}
        }
        strokeWidth="0.319"
        strokeMiterlimit="10"
        x1="109.319"
        y1="68.753"
        x2="109.339"
        y2="69.182"
      />
      <polyline
        fill="none"
        stroke={stitching}
        onClick={() => handleClick("stitching")}
        onMouseEnter={() => setStitchingActive(true)}
        onMouseLeave={() => setStitchingActive(false)}
        style={
          stitchingActive && !preview
            ? {stroke: adjust(stitching, 55)}
            : {stroke: stitching}
        }
        strokeWidth="0.319"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        points="
	104.204,68.372 103.765,68.936 103.755,68.476 103.317,69.04 103.307,68.579 102.869,69.143 102.858,68.682 102.422,69.246 
	102.411,68.786 101.975,69.349 101.964,68.889 101.528,69.452 101.516,68.992 101.081,69.554 "
      />
      <polyline
        fill="none"
        stroke={stitching}
        onClick={() => handleClick("stitching")}
        onMouseEnter={() => setStitchingActive(true)}
        onMouseLeave={() => setStitchingActive(false)}
        style={
          stitchingActive && !preview
            ? {stroke: adjust(stitching, 55)}
            : {stroke: stitching}
        }
        strokeWidth="0.319"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        points="
	104.197,66.951 103.737,67.52 103.727,67.059 103.268,67.627 103.259,67.166 102.801,67.734 102.79,67.274 102.332,67.841 
	102.321,67.381 101.865,67.948 101.853,67.489 101.397,68.055 101.385,67.596 100.93,68.162 "
      />
      <polyline
        fill="none"
        stroke={stitching}
        onClick={() => handleClick("stitching")}
        onMouseEnter={() => setStitchingActive(true)}
        onMouseLeave={() => setStitchingActive(false)}
        style={
          stitchingActive && !preview
            ? {stroke: adjust(stitching, 55)}
            : {stroke: stitching}
        }
        strokeWidth="0.319"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        points="
	102.827,41.411 102.43,42.005 102.401,41.548 102.004,42.141 101.975,41.684 101.579,42.277 101.551,41.821 101.154,42.413 
	101.126,41.957 100.73,42.55 100.702,42.094 100.306,42.685 100.277,42.23 99.883,42.821 "
      />
      <polyline
        fill="none"
        stroke={stitching}
        onClick={() => handleClick("stitching")}
        onMouseEnter={() => setStitchingActive(true)}
        onMouseLeave={() => setStitchingActive(false)}
        style={
          stitchingActive && !preview
            ? {stroke: adjust(stitching, 55)}
            : {stroke: stitching}
        }
        strokeWidth="0.319"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        points="
	108.689,67.355 108.249,67.921 108.239,67.459 107.798,68.025 107.789,67.563 107.349,68.128 107.338,67.667 106.9,68.232 
	106.888,67.771 106.451,68.335 106.439,67.875 106.001,68.438 105.99,67.978 105.553,68.542 "
      />
      <polyline
        fill="none"
        stroke={stitching}
        onClick={() => handleClick("stitching")}
        onMouseEnter={() => setStitchingActive(true)}
        onMouseLeave={() => setStitchingActive(false)}
        style={
          stitchingActive && !preview
            ? {stroke: adjust(stitching, 55)}
            : {stroke: stitching}
        }
        strokeWidth="0.319"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        points="
	108.61,65.945 108.17,66.511 108.16,66.049 107.719,66.615 107.709,66.153 107.27,66.719 107.259,66.257 106.821,66.823 
	106.809,66.362 106.372,66.926 106.36,66.466 105.922,67.03 105.911,66.57 105.474,67.133 "
      />
      <polyline
        fill="none"
        stroke={stitching}
        onClick={() => handleClick("stitching")}
        onMouseEnter={() => setStitchingActive(true)}
        onMouseLeave={() => setStitchingActive(false)}
        style={
          stitchingActive && !preview
            ? {stroke: adjust(stitching, 55)}
            : {stroke: stitching}
        }
        strokeWidth="0.319"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        points="
	107.438,40.082 107.02,40.688 106.99,40.23 106.573,40.835 106.541,40.378 106.125,40.982 106.095,40.526 105.679,41.129 
	105.648,40.673 105.233,41.276 105.202,40.82 104.788,41.423 104.755,40.966 104.341,41.57 "
      />
      <line
        fill="none"
        stroke={stitching}
        onClick={() => handleClick("stitching")}
        onMouseEnter={() => setStitchingActive(true)}
        onMouseLeave={() => setStitchingActive(false)}
        style={
          stitchingActive && !preview
            ? {stroke: adjust(stitching, 55)}
            : {stroke: stitching}
        }
        strokeWidth="0.319"
        strokeMiterlimit="10"
        x1="136.735"
        y1="30.545"
        x2="136.768"
        y2="30.992"
      />
      <line
        fill="none"
        stroke={stitching}
        onClick={() => handleClick("stitching")}
        onMouseEnter={() => setStitchingActive(true)}
        onMouseLeave={() => setStitchingActive(false)}
        style={
          stitchingActive && !preview
            ? {stroke: adjust(stitching, 55)}
            : {stroke: stitching}
        }
        strokeWidth="0.319"
        strokeMiterlimit="10"
        x1="136.838"
        y1="31.893"
        x2="136.838"
        y2="31.894"
      />
      <line
        fill="none"
        stroke={stitching}
        onClick={() => handleClick("stitching")}
        onMouseEnter={() => setStitchingActive(true)}
        onMouseLeave={() => setStitchingActive(false)}
        style={
          stitchingActive && !preview
            ? {stroke: adjust(stitching, 55)}
            : {stroke: stitching}
        }
        strokeWidth="0.319"
        strokeMiterlimit="10"
        strokeDasharray="0.963,0.962"
        x1="136.838"
        y1="31.894"
        x2="139.055"
        y2="61.513"
      />
      <line
        fill="none"
        stroke={stitching}
        onClick={() => handleClick("stitching")}
        onMouseEnter={() => setStitchingActive(true)}
        onMouseLeave={() => setStitchingActive(false)}
        style={
          stitchingActive && !preview
            ? {stroke: adjust(stitching, 55)}
            : {stroke: stitching}
        }
        strokeWidth="0.319"
        strokeMiterlimit="10"
        x1="139.055"
        y1="61.512"
        x2="139.089"
        y2="61.964"
      />
      <line
        fill="none"
        stroke={stitching}
        onClick={() => handleClick("stitching")}
        onMouseEnter={() => setStitchingActive(true)}
        onMouseLeave={() => setStitchingActive(false)}
        style={
          stitchingActive && !preview
            ? {stroke: adjust(stitching, 55)}
            : {stroke: stitching}
        }
        strokeWidth="0.319"
        strokeMiterlimit="10"
        x1="142.247"
        y1="28.962"
        x2="142.282"
        y2="29.421"
      />
      <line
        fill="none"
        stroke={stitching}
        onClick={() => handleClick("stitching")}
        onMouseEnter={() => setStitchingActive(true)}
        onMouseLeave={() => setStitchingActive(false)}
        style={
          stitchingActive && !preview
            ? {stroke: adjust(stitching, 55)}
            : {stroke: stitching}
        }
        strokeWidth="0.319"
        strokeMiterlimit="10"
        x1="142.354"
        y1="30.345"
        x2="142.354"
        y2="30.346"
      />
      <line
        fill="none"
        stroke={stitching}
        onClick={() => handleClick("stitching")}
        onMouseEnter={() => setStitchingActive(true)}
        onMouseLeave={() => setStitchingActive(false)}
        style={
          stitchingActive && !preview
            ? {stroke: adjust(stitching, 55)}
            : {stroke: stitching}
        }
        strokeWidth="0.319"
        strokeMiterlimit="10"
        strokeDasharray="0.963,0.962"
        x1="142.354"
        y1="30.346"
        x2="144.655"
        y2="60.756"
      />
      <line
        fill="none"
        stroke={stitching}
        onClick={() => handleClick("stitching")}
        onMouseEnter={() => setStitchingActive(true)}
        onMouseLeave={() => setStitchingActive(false)}
        style={
          stitchingActive && !preview
            ? {stroke: adjust(stitching, 55)}
            : {stroke: stitching}
        }
        strokeWidth="0.319"
        strokeMiterlimit="10"
        x1="144.655"
        y1="60.756"
        x2="144.691"
        y2="61.22"
      />
      <line
        fill="none"
        stroke={stitching}
        onClick={() => handleClick("stitching")}
        onMouseEnter={() => setStitchingActive(true)}
        onMouseLeave={() => setStitchingActive(false)}
        style={
          stitchingActive && !preview
            ? {stroke: adjust(stitching, 55)}
            : {stroke: stitching}
        }
        strokeWidth="0.319"
        strokeMiterlimit="10"
        x1="148.254"
        y1="27.354"
        x2="148.29"
        y2="27.826"
      />
      <line
        fill="none"
        stroke={stitching}
        onClick={() => handleClick("stitching")}
        onMouseEnter={() => setStitchingActive(true)}
        onMouseLeave={() => setStitchingActive(false)}
        style={
          stitchingActive && !preview
            ? {stroke: adjust(stitching, 55)}
            : {stroke: stitching}
        }
        strokeWidth="0.319"
        strokeMiterlimit="10"
        x1="148.364"
        y1="28.776"
        x2="148.364"
        y2="28.777"
      />
      <line
        fill="none"
        stroke={stitching}
        onClick={() => handleClick("stitching")}
        onMouseEnter={() => setStitchingActive(true)}
        onMouseLeave={() => setStitchingActive(false)}
        style={
          stitchingActive && !preview
            ? {stroke: adjust(stitching, 55)}
            : {stroke: stitching}
        }
        strokeWidth="0.319"
        strokeMiterlimit="10"
        strokeDasharray="0.963,0.962"
        x1="148.364"
        y1="28.777"
        x2="150.76"
        y2="60.05"
      />
      <line
        fill="none"
        stroke={stitching}
        onClick={() => handleClick("stitching")}
        onMouseEnter={() => setStitchingActive(true)}
        onMouseLeave={() => setStitchingActive(false)}
        style={
          stitchingActive && !preview
            ? {stroke: adjust(stitching, 55)}
            : {stroke: stitching}
        }
        strokeWidth="0.319"
        strokeMiterlimit="10"
        x1="150.76"
        y1="60.05"
        x2="150.797"
        y2="60.527"
      />
      <polyline
        fill="none"
        stroke={stitching}
        onClick={() => handleClick("stitching")}
        onMouseEnter={() => setStitchingActive(true)}
        onMouseLeave={() => setStitchingActive(false)}
        style={
          stitchingActive && !preview
            ? {stroke: adjust(stitching, 55)}
            : {stroke: stitching}
        }
        strokeWidth="0.319"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        points="
	143.77,59.204 143.203,59.774 143.178,59.279 142.617,59.848 142.591,59.354 142.033,59.92 142.008,59.428 141.453,59.992 
	141.427,59.501 140.876,60.064 140.851,59.575 140.303,60.135 140.277,59.648 139.733,60.206 "
      />
      <polyline
        fill="none"
        stroke={stitching}
        onClick={() => handleClick("stitching")}
        onMouseEnter={() => setStitchingActive(true)}
        onMouseLeave={() => setStitchingActive(false)}
        style={
          stitchingActive && !preview
            ? {stroke: adjust(stitching, 55)}
            : {stroke: stitching}
        }
        strokeWidth="0.319"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        points="
	143.727,57.677 143.133,58.254 143.109,57.759 142.519,58.334 142.496,57.84 141.91,58.413 141.885,57.921 141.303,58.491 
	141.279,58.001 140.702,58.57 140.676,58.081 140.102,58.648 140.077,58.16 139.507,58.725 "
      />
      <polyline
        fill="none"
        stroke={stitching}
        onClick={() => handleClick("stitching")}
        onMouseEnter={() => setStitchingActive(true)}
        onMouseLeave={() => setStitchingActive(false)}
        style={
          stitchingActive && !preview
            ? {stroke: adjust(stitching, 55)}
            : {stroke: stitching}
        }
        strokeWidth="0.319"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        points="
	141.204,30.71 140.701,31.379 140.653,30.892 140.153,31.559 140.106,31.073 139.608,31.737 139.561,31.253 139.067,31.915 
	139.019,31.433 138.529,32.091 138.481,31.61 137.993,32.267 137.945,31.788 137.461,32.441 "
      />
      <polyline
        fill="none"
        stroke={stitching}
        onClick={() => handleClick("stitching")}
        onMouseEnter={() => setStitchingActive(true)}
        onMouseLeave={() => setStitchingActive(false)}
        style={
          stitchingActive && !preview
            ? {stroke: adjust(stitching, 55)}
            : {stroke: stitching}
        }
        strokeWidth="0.319"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        points="
	149.845,58.453 149.245,59.041 149.22,58.532 148.625,59.119 148.6,58.611 148.008,59.195 147.983,58.689 147.395,59.271 
	147.368,58.767 146.785,59.348 146.759,58.845 146.179,59.423 146.153,58.922 145.576,59.497 "
      />
      <polyline
        fill="none"
        stroke={stitching}
        onClick={() => handleClick("stitching")}
        onMouseEnter={() => setStitchingActive(true)}
        onMouseLeave={() => setStitchingActive(false)}
        style={
          stitchingActive && !preview
            ? {stroke: adjust(stitching, 55)}
            : {stroke: stitching}
        }
        strokeWidth="0.319"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        points="
	149.701,56.892 149.101,57.485 149.077,56.975 148.481,57.566 148.456,57.059 147.865,57.647 147.84,57.142 147.253,57.728 
	147.226,57.224 146.643,57.808 146.617,57.306 146.038,57.888 146.012,57.387 145.436,57.967 "
      />
      <polyline
        fill="none"
        stroke={stitching}
        onClick={() => handleClick("stitching")}
        onMouseEnter={() => setStitchingActive(true)}
        onMouseLeave={() => setStitchingActive(false)}
        style={
          stitchingActive && !preview
            ? {stroke: adjust(stitching, 55)}
            : {stroke: stitching}
        }
        strokeWidth="0.319"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        points="
	147.574,28.736 147.015,29.442 146.963,28.942 146.408,29.644 146.355,29.146 145.804,29.846 145.752,29.349 145.205,30.047 
	145.152,29.551 144.609,30.246 144.555,29.753 144.016,30.444 143.963,29.952 143.427,30.641 "
      />
      <line
        fill="none"
        stroke={stitching}
        onClick={() => handleClick("stitching")}
        onMouseEnter={() => setStitchingActive(true)}
        onMouseLeave={() => setStitchingActive(false)}
        style={
          stitchingActive && !preview
            ? {stroke: adjust(stitching, 55)}
            : {stroke: stitching}
        }
        strokeWidth="0.319"
        strokeMiterlimit="10"
        x1="171.654"
        y1="20.54"
        x2="171.701"
        y2="21.003"
      />
      <line
        fill="none"
        stroke={stitching}
        onClick={() => handleClick("stitching")}
        onMouseEnter={() => setStitchingActive(true)}
        onMouseLeave={() => setStitchingActive(false)}
        style={
          stitchingActive && !preview
            ? {stroke: adjust(stitching, 55)}
            : {stroke: stitching}
        }
        strokeWidth="0.319"
        strokeMiterlimit="10"
        x1="171.796"
        y1="21.935"
        x2="171.796"
        y2="21.936"
      />
      <line
        fill="none"
        stroke={stitching}
        onClick={() => handleClick("stitching")}
        onMouseEnter={() => setStitchingActive(true)}
        onMouseLeave={() => setStitchingActive(false)}
        style={
          stitchingActive && !preview
            ? {stroke: adjust(stitching, 55)}
            : {stroke: stitching}
        }
        strokeWidth="0.319"
        strokeMiterlimit="10"
        strokeDasharray="0.963,0.962"
        x1="171.796"
        y1="21.935"
        x2="175.095"
        y2="54.702"
      />
      <line
        fill="none"
        stroke={stitching}
        onClick={() => handleClick("stitching")}
        onMouseEnter={() => setStitchingActive(true)}
        onMouseLeave={() => setStitchingActive(false)}
        style={
          stitchingActive && !preview
            ? {stroke: adjust(stitching, 55)}
            : {stroke: stitching}
        }
        strokeWidth="0.319"
        strokeMiterlimit="10"
        x1="175.095"
        y1="54.702"
        x2="175.15"
        y2="55.235"
      />
      <line
        fill="none"
        stroke={stitching}
        onClick={() => handleClick("stitching")}
        onMouseEnter={() => setStitchingActive(true)}
        onMouseLeave={() => setStitchingActive(false)}
        style={
          stitchingActive && !preview
            ? {stroke: adjust(stitching, 55)}
            : {stroke: stitching}
        }
        strokeWidth="0.319"
        strokeMiterlimit="10"
        x1="175.261"
        y1="19.589"
        x2="175.313"
        y2="20.056"
      />
      <line
        fill="none"
        stroke={stitching}
        onClick={() => handleClick("stitching")}
        onMouseEnter={() => setStitchingActive(true)}
        onMouseLeave={() => setStitchingActive(false)}
        style={
          stitchingActive && !preview
            ? {stroke: adjust(stitching, 55)}
            : {stroke: stitching}
        }
        strokeWidth="0.319"
        strokeMiterlimit="10"
        x1="175.415"
        y1="20.998"
        x2="175.415"
        y2="20.999"
      />
      <line
        fill="none"
        stroke={stitching}
        onClick={() => handleClick("stitching")}
        onMouseEnter={() => setStitchingActive(true)}
        onMouseLeave={() => setStitchingActive(false)}
        style={
          stitchingActive && !preview
            ? {stroke: adjust(stitching, 55)}
            : {stroke: stitching}
        }
        strokeWidth="0.319"
        strokeMiterlimit="10"
        strokeDasharray="0.963,0.962"
        x1="175.415"
        y1="20.999"
        x2="179.016"
        y2="54.131"
      />
      <line
        fill="none"
        stroke={stitching}
        onClick={() => handleClick("stitching")}
        onMouseEnter={() => setStitchingActive(true)}
        onMouseLeave={() => setStitchingActive(false)}
        style={
          stitchingActive && !preview
            ? {stroke: adjust(stitching, 55)}
            : {stroke: stitching}
        }
        strokeWidth="0.319"
        strokeMiterlimit="10"
        x1="179.016"
        y1="54.131"
        x2="179.075"
        y2="54.67"
      />
      <line
        fill="none"
        stroke={stitching}
        onClick={() => handleClick("stitching")}
        onMouseEnter={() => setStitchingActive(true)}
        onMouseLeave={() => setStitchingActive(false)}
        style={
          stitchingActive && !preview
            ? {stroke: adjust(stitching, 55)}
            : {stroke: stitching}
        }
        strokeWidth="0.319"
        strokeMiterlimit="10"
        x1="179.084"
        y1="18.699"
        x2="179.141"
        y2="19.172"
      />
      <line
        fill="none"
        stroke={stitching}
        onClick={() => handleClick("stitching")}
        onMouseEnter={() => setStitchingActive(true)}
        onMouseLeave={() => setStitchingActive(false)}
        style={
          stitchingActive && !preview
            ? {stroke: adjust(stitching, 55)}
            : {stroke: stitching}
        }
        strokeWidth="0.319"
        strokeMiterlimit="10"
        x1="179.253"
        y1="20.124"
        x2="179.253"
        y2="20.125"
      />
      <line
        fill="none"
        stroke={stitching}
        onClick={() => handleClick("stitching")}
        onMouseEnter={() => setStitchingActive(true)}
        onMouseLeave={() => setStitchingActive(false)}
        style={
          stitchingActive && !preview
            ? {stroke: adjust(stitching, 55)}
            : {stroke: stitching}
        }
        strokeWidth="0.319"
        strokeMiterlimit="10"
        strokeDasharray="0.963,0.962"
        x1="179.253"
        y1="20.125"
        x2="183.18"
        y2="53.659"
      />
      <line
        fill="none"
        stroke={stitching}
        onClick={() => handleClick("stitching")}
        onMouseEnter={() => setStitchingActive(true)}
        onMouseLeave={() => setStitchingActive(false)}
        style={
          stitchingActive && !preview
            ? {stroke: adjust(stitching, 55)}
            : {stroke: stitching}
        }
        strokeWidth="0.319"
        strokeMiterlimit="10"
        x1="183.18"
        y1="53.659"
        x2="183.244"
        y2="54.205"
      />
      <polyline
        fill="none"
        stroke={stitching}
        onClick={() => handleClick("stitching")}
        onMouseEnter={() => setStitchingActive(true)}
        onMouseLeave={() => setStitchingActive(false)}
        style={
          stitchingActive && !preview
            ? {stroke: adjust(stitching, 55)}
            : {stroke: stitching}
        }
        strokeWidth="0.319"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        points="
	178.282,52.288 177.926,52.913 177.874,52.339 177.517,52.963 177.466,52.391 177.11,53.013 177.059,52.442 176.705,53.063 
	176.654,52.493 176.301,53.113 176.25,52.543 175.897,53.162 175.846,52.594 175.496,53.212 "
      />
      <polyline
        fill="none"
        stroke={stitching}
        onClick={() => handleClick("stitching")}
        onMouseEnter={() => setStitchingActive(true)}
        onMouseLeave={() => setStitchingActive(false)}
        style={
          stitchingActive && !preview
            ? {stroke: adjust(stitching, 55)}
            : {stroke: stitching}
        }
        strokeWidth="0.319"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        points="
	178.145,50.533 177.77,51.157 177.717,50.588 177.343,51.211 177.292,50.643 176.919,51.264 176.869,50.698 176.498,51.318 
	176.447,50.752 176.076,51.371 176.026,50.806 175.657,51.424 175.606,50.86 175.238,51.477 "
      />
      <polyline
        fill="none"
        stroke={stitching}
        onClick={() => handleClick("stitching")}
        onMouseEnter={() => setStitchingActive(true)}
        onMouseLeave={() => setStitchingActive(false)}
        style={
          stitchingActive && !preview
            ? {stroke: adjust(stitching, 55)}
            : {stroke: stitching}
        }
        strokeWidth="0.319"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        points="
	182.427,51.79 182.064,52.424 182.006,51.844 181.645,52.476 181.587,51.896 181.226,52.527 181.169,51.949 180.809,52.579 
	180.752,52.001 180.394,52.63 180.337,52.053 179.979,52.681 179.923,52.105 179.566,52.732 "
      />
      <polyline
        fill="none"
        stroke={stitching}
        onClick={() => handleClick("stitching")}
        onMouseEnter={() => setStitchingActive(true)}
        onMouseLeave={() => setStitchingActive(false)}
        style={
          stitchingActive && !preview
            ? {stroke: adjust(stitching, 55)}
            : {stroke: stitching}
        }
        strokeWidth="0.319"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        points="
	182.204,50.018 181.843,50.649 181.786,50.073 181.425,50.704 181.368,50.128 181.009,50.757 180.952,50.183 180.594,50.811 
	180.537,50.238 180.18,50.864 180.124,50.292 179.766,50.917 179.712,50.346 179.355,50.971 "
      />
      <path
        fill="none"
        stroke={stitching}
        onClick={() => handleClick("stitching")}
        onMouseEnter={() => setStitchingActive(true)}
        onMouseLeave={() => setStitchingActive(false)}
        style={
          stitchingActive && !preview
            ? {stroke: adjust(stitching, 55)}
            : {stroke: stitching}
        }
        strokeWidth="0.319"
        strokeMiterlimit="10"
        strokeDasharray="1.276,0.957"
        d="M45.463,184.828
	c1.956,0.835,3.915,1.677,5.876,2.525c1.587-1.043,3.406-2.041,4.933-3.072c0.825-0.557,1.125-0.807,1.521-1.722
	c2.575-5.932,5.172-12.027,7.948-18.037c-2.87-1.415-5.741-2.783-8.611-4.114"
      />
      <path
        fill="none"
        stroke={stitching}
        onClick={() => handleClick("stitching")}
        onMouseEnter={() => setStitchingActive(true)}
        onMouseLeave={() => setStitchingActive(false)}
        style={
          stitchingActive && !preview
            ? {stroke: adjust(stitching, 55)}
            : {stroke: stitching}
        }
        strokeWidth="0.319"
        strokeMiterlimit="10"
        strokeDasharray="1.276,0.957"
        d="M53.202,185.211
	c-2.239-0.956-4.476-1.905-6.706-2.848"
      />
      <path
        fill="none"
        stroke={stitching}
        onClick={() => handleClick("stitching")}
        onMouseEnter={() => setStitchingActive(true)}
        onMouseLeave={() => setStitchingActive(false)}
        style={
          stitchingActive && !preview
            ? {stroke: adjust(stitching, 55)}
            : {stroke: stitching}
        }
        strokeWidth="0.319"
        strokeMiterlimit="10"
        strokeDasharray="1.276,0.957"
        d="M69.103,166.203
	c-2.995,6.392-5.757,12.868-8.455,19.221c-0.241,0.567-0.42,0.728-0.929,1.085c-1.278,0.897-2.771,1.769-4.065,2.684
	c2.532,0.19,5.384,0.6,7.858,0.755c1.047,0.066,1.536-0.107,1.908-0.996c2.796-6.67,5.685-13.493,8.874-20.18
	C72.55,167.906,70.829,167.053,69.103,166.203z"
      />
      <path
        fill="none"
        stroke={stitching}
        onClick={() => handleClick("stitching")}
        onMouseEnter={() => setStitchingActive(true)}
        onMouseLeave={() => setStitchingActive(false)}
        style={
          stitchingActive && !preview
            ? {stroke: adjust(stitching, 55)}
            : {stroke: stitching}
        }
        strokeWidth="0.319"
        strokeMiterlimit="10"
        strokeDasharray="1.276,0.957"
        d="M81.877,153.725
	c-2.185,4.354-4.397,8.655-6.496,12.974"
      />
      <path
        fill="none"
        stroke={stitching}
        onClick={() => handleClick("stitching")}
        onMouseEnter={() => setStitchingActive(true)}
        onMouseLeave={() => setStitchingActive(false)}
        style={
          stitchingActive && !preview
            ? {stroke: adjust(stitching, 55)}
            : {stroke: stitching}
        }
        strokeWidth="0.319"
        strokeMiterlimit="10"
        strokeDasharray="1.276,0.957"
        d="M62.149,150.613
	c5.391,2.604,10.813,5.343,16.306,8.274"
      />
      <path
        fill="none"
        stroke={stitching}
        onClick={() => handleClick("stitching")}
        onMouseEnter={() => setStitchingActive(true)}
        onMouseLeave={() => setStitchingActive(false)}
        style={
          stitchingActive && !preview
            ? {stroke: adjust(stitching, 55)}
            : {stroke: stitching}
        }
        strokeWidth="0.233"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        d="M78.396,158.931
	c0.475-0.134,0.95-0.264,1.427-0.394c-0.343-0.125-0.686-0.247-1.029-0.369c0.475-0.132,0.951-0.262,1.427-0.393
	c-0.343-0.125-0.686-0.247-1.029-0.37c0.475-0.132,0.951-0.263,1.428-0.393c-0.343-0.125-0.686-0.248-1.03-0.37
	c0.476-0.131,0.952-0.262,1.428-0.393c-0.343-0.125-0.685-0.248-1.029-0.37c0.48-0.133,0.961-0.266,1.436-0.416
	c-0.347-0.112-0.692-0.227-1.035-0.354c0.485-0.138,0.964-0.289,1.445-0.44c-0.345-0.116-0.693-0.228-1.036-0.351
	c0.485-0.146,0.966-0.301,1.45-0.452"
      />
      <path
        fill="none"
        stroke={stitching}
        onClick={() => handleClick("stitching")}
        onMouseEnter={() => setStitchingActive(true)}
        onMouseLeave={() => setStitchingActive(false)}
        style={
          stitchingActive && !preview
            ? {stroke: adjust(stitching, 55)}
            : {stroke: stitching}
        }
        strokeWidth="0.319"
        strokeMiterlimit="10"
        strokeDasharray="1.276,0.957"
        d="M54.862,164.912
	c2.584,1.186,5.171,2.399,7.758,3.644"
      />
      <path
        fill="none"
        stroke={stitching}
        onClick={() => handleClick("stitching")}
        onMouseEnter={() => setStitchingActive(true)}
        onMouseLeave={() => setStitchingActive(false)}
        style={
          stitchingActive && !preview
            ? {stroke: adjust(stitching, 55)}
            : {stroke: stitching}
        }
        strokeWidth="0.319"
        strokeMiterlimit="10"
        strokeDasharray="1.276,0.957"
        d="M68.087,171.192
	c1.327,0.639,2.655,1.282,3.991,1.917"
      />
      <path
        fill="none"
        stroke={stitching}
        onClick={() => handleClick("stitching")}
        onMouseEnter={() => setStitchingActive(true)}
        onMouseLeave={() => setStitchingActive(false)}
        style={
          stitchingActive && !preview
            ? {stroke: adjust(stitching, 55)}
            : {stroke: stitching}
        }
        strokeWidth="0.42"
        strokeMiterlimit="10"
        strokeDasharray="1.259,1.259"
        d="M150.929,46.563
	c0,0,15.823-2.341,22.35-1.342"
      />
      <path
        fill="none"
        stroke={stitching}
        onClick={() => handleClick("stitching")}
        onMouseEnter={() => setStitchingActive(true)}
        onMouseLeave={() => setStitchingActive(false)}
        style={
          stitchingActive && !preview
            ? {stroke: adjust(stitching, 55)}
            : {stroke: stitching}
        }
        strokeWidth="0.42"
        strokeMiterlimit="10"
        strokeDasharray="1.259,1.259"
        d="M184.645,45.221
	c0,0,5.373,0.194,10.887,0.757"
      />
      <path
        fill="none"
        stroke={stitching}
        onClick={() => handleClick("stitching")}
        onMouseEnter={() => setStitchingActive(true)}
        onMouseLeave={() => setStitchingActive(false)}
        style={
          stitchingActive && !preview
            ? {stroke: adjust(stitching, 55)}
            : {stroke: stitching}
        }
        strokeWidth="0.42"
        strokeMiterlimit="10"
        strokeDasharray="1.247,1.246"
        d="M111.705,57.904
	c0.116,9.478,0.672,25.507,1.726,28.027c0.445,0.286,2.949,1.22,16.573,1.22c1.733,0,3.646-0.016,5.759-0.049
	c10.334-0.164,20.377-0.685,20.476-0.691"
      />
      <path
        fill="none"
        stroke={stitching}
        onClick={() => handleClick("stitching")}
        onMouseEnter={() => setStitchingActive(true)}
        onMouseLeave={() => setStitchingActive(false)}
        style={
          stitchingActive && !preview
            ? {stroke: adjust(stitching, 55)}
            : {stroke: stitching}
        }
        strokeWidth="0.42"
        strokeMiterlimit="10"
        strokeDasharray="1.247,1.246"
        d="M155.926,82.546
	c-0.081-0.004-8.725-0.431-16.984-1.044c-16.995-1.26-18.117-2.045-18.655-2.422c-0.543-0.38-1.23-1.222-2.222-4.592
	c-0.547-1.859-1.14-4.323-1.761-7.326c-1.054-5.086-1.841-10.045-1.849-10.094"
      />
      <polyline
        fill="none"
        stroke={stitching}
        onClick={() => handleClick("stitching")}
        onMouseEnter={() => setStitchingActive(true)}
        onMouseLeave={() => setStitchingActive(false)}
        style={
          stitchingActive && !preview
            ? {stroke: adjust(stitching, 55)}
            : {stroke: stitching}
        }
        strokeWidth="0.319"
        strokeMiterlimit="10"
        points="151.789,73.447 152.268,73.489 
	152.191,73.013 "
      />
      <line
        fill="none"
        stroke={stitching}
        onClick={() => handleClick("stitching")}
        onMouseEnter={() => setStitchingActive(true)}
        onMouseLeave={() => setStitchingActive(false)}
        style={
          stitchingActive && !preview
            ? {stroke: adjust(stitching, 55)}
            : {stroke: stitching}
        }
        strokeWidth="0.319"
        strokeMiterlimit="10"
        x1="152.04"
        y1="72.087"
        x2="152.038"
        y2="72.077"
      />
      <path
        fill="none"
        stroke={stitching}
        onClick={() => handleClick("stitching")}
        onMouseEnter={() => setStitchingActive(true)}
        onMouseLeave={() => setStitchingActive(false)}
        style={
          stitchingActive && !preview
            ? {stroke: adjust(stitching, 55)}
            : {stroke: stitching}
        }
        strokeWidth="0.319"
        strokeMiterlimit="10"
        strokeDasharray="0.949,0.938"
        d="M152.038,72.077
	l-0.67-4.103c0,0-0.084-0.327-0.435-0.332l-27.821-2.196c-0.147-0.011-0.267,0.106-0.242,0.235l1.015,5.211
	c0.016,0.087,0.095,0.152,0.194,0.16l27.71,2.395"
      />
      <path
        d="M233.336,483.055c-0.445-9.022-7.358-63.723-7.426-64.266c-0.045-0.433-4.537-43.54-7.429-61.79
	c-1.846-11.636-5.434-26.152-8.119-36.28c-1.464-5.525-2.167-11.228-2.088-16.949c0.143-10.291,0.255-23.363-0.029-27.195
	c-0.13-1.755-0.467-4.208-0.856-7.046c-0.967-7.054-2.291-16.715-1.732-24.865c0.244-3.562,0.854-8.625,1.559-14.489
	c1.56-12.947,3.695-30.676,3.616-46.744c-0.089-18.649,0.891-31.214,1.316-35.751c0.08-0.849,0.234-1.695,0.458-2.517
	c0.925-3.383,3.893-15.78,3.258-33.242c-0.357-9.835-1.794-19.736-4.27-29.429c-2.672-10.466-6.584-20.774-11.645-30.699
	c0.975-0.683,1.404-1.26,1.469-1.353c1.102-1.387,0.753-2.255,0.709-2.349l-0.005-0.011c-0.06-0.12-6.072-12.171-9.424-30.575
	c-0.092-0.506-0.484-0.808-1.047-0.808l-1.833-0.004c-0.09-0.003-1.137-0.043-2.106-0.03c-0.082-0.011-0.165-0.013-0.251-0.004
	c-2.549,0.306-5.073,0.713-7.558,1.192c-0.074-0.04-0.16-0.051-0.241-0.029l-0.939,0.263c-7.765,1.58-15.12,3.852-21.581,5.995
	c-2.461,0.816-4.96,1.615-7.508,2.4c-0.024-0.038-0.055-0.073-0.093-0.1c-0.08-0.055-0.179-0.071-0.273-0.043l-13.412,4.007
	c-0.089,0.026-0.159,0.089-0.197,0.168c-3.018,0.815-6.116,1.621-9.309,2.419c-4.496,1.263-9.056,2.624-13.552,4.047
	c-2.047,0.644-4.245,1.317-6.572,2.029c-0.088,0.027-0.177,0.054-0.265,0.081l-7.434,2.056c-0.143,0.039-0.238,0.17-0.235,0.316
	c-4.276,1.349-8.526,2.753-12.171,4.138c-0.357,0.137-0.597,0.484-0.597,0.867v18.792l0.011-0.004
	c0.083,3.036,0.6,22.29,1.079,37.734l0.005,0.16c-0.279,0.473-0.554,0.947-0.83,1.422c-0.759,1.31-1.566,2.714-2.464,4.291
	c-1.229,2.158-2.511,4.437-3.813,6.775c-1.629,2.923-3.285,5.933-4.921,8.946c-1.94,3.575-3.888,7.213-5.787,10.812
	c-2.174,4.12-4.33,8.282-6.412,12.366c-2.326,4.567-4.613,9.147-6.796,13.614c-2.4,4.91-4.735,9.806-6.94,14.551
	c-2.397,5.157-4.7,10.265-6.842,15.178c-2.312,5.299-4.5,10.514-6.505,15.5c-2.147,5.337-4.14,10.556-5.926,15.512
	c-1.927,5.344-3.596,10.322-5.105,15.215c-1.597,5.178-2.92,9.956-4.046,14.612l-0.15,0.622c-1.117,4.615-2.272,9.388-2.709,14.122
	c-0.225,2.426-0.191,4.879-0.159,7.252c0.011,0.772,0.022,1.573,0.023,2.359c0.005,1.634,0.009,3.268,0.013,4.902
	c0.013,3.958,0.029,7.994,0.048,11.994c0.023,4.78,0.05,9.528,0.083,14.112c0.038,5.37,0.08,10.362,0.129,15.265
	c0.057,5.537,0.117,10.591,0.186,15.45c0.078,5.391,0.16,10.19,0.254,14.67c0.101,4.969,0.207,9.077,0.328,12.927
	c0.095,3.018,0.219,6.591,0.415,10.219l0.013,0.239c0.112,2.077,0.228,4.224,0.501,6.321c0.04,0.311,0.091,0.679,0.163,1.044
	c1.939,10.025,1.948,30.812,1.007,39.399c-0.893,8.15,1.215,33.783,1.237,34.041l0.015,0.178l0.159,0.081
	c0.109,0.054,2.7,1.352,7.388,2.625c4.066,1.103,10.482,2.375,18.473,2.375c0.501,0,1.007-0.004,1.519-0.016
	c15.73-0.315,26.241-0.753,36.717-2.152c9.955-1.33,10.686-3.691,10.735-3.957l0.006-0.059c0.001-0.364,0.126-36.625,1.238-52.187
	c1.199-16.803,3.105-62.517,1.35-74.87c0.008-5.987,3.053-9.982,3.084-10.022l0.011-0.015c0.071-0.105,1.751-2.729,3.205-18.716
	c1.43-15.733,3.642-28.286,3.708-28.659c0.052-0.224,1.667-6.859,8.357-10.006c1.051,4.545,4.344,19.36,4.065,25.344
	c-0.334,7.111,0.989,44.538,2.027,52.601c0.458,3.563,3.964,16.049,7.707,28.783c4.455,15.155,9.244,30.661,9.629,31.909
	c0.194,0.798,9.581,39.854,11.018,56.427c0.837,9.665,2.3,18.267,3.097,22.535c1.032,5.522,2.102,10.034,2.488,10.497
	c0.457,0.548,8.854,3.081,21.783,3.505c1.259,0.04,2.524,0.062,3.799,0.062c6.507,0,13.196-0.538,19.935-1.605
	c9.986-1.58,20.08-4.328,30.006-8.17l0.215-0.083L233.336,483.055z M213.442,138.441c-0.023,0.13-0.046,0.256-0.07,0.382
	c-0.011,0.063-0.022,0.125-0.035,0.187c-0.032,0.178-0.066,0.354-0.099,0.527c-0.06,0.306-0.118,0.602-0.176,0.887
	c-0.021,0.098-0.041,0.193-0.06,0.289c-0.126,0.612-0.25,1.174-0.366,1.682c-0.031,0.137-0.061,0.268-0.091,0.396
	c-0.02,0.086-0.039,0.17-0.059,0.252c-0.02,0.079-0.037,0.157-0.055,0.232c-0.04,0.161-0.077,0.316-0.114,0.463
	c-0.017,0.071-0.035,0.141-0.052,0.21c-0.028,0.11-0.055,0.216-0.081,0.317c-0.015,0.057-0.029,0.112-0.043,0.165
	c-0.033,0.127-0.064,0.246-0.094,0.356c-0.012,0.047-0.025,0.093-0.036,0.135c-0.007,0.025-0.014,0.05-0.02,0.073
	c-0.235,0.858-0.395,1.741-0.479,2.626c-0.425,4.547-1.407,17.136-1.317,35.813c0.078,16.029-2.055,33.736-3.611,46.665
	c-0.708,5.873-1.319,10.945-1.564,14.523c-0.564,8.214,0.767,17.913,1.737,24.994c0.388,2.829,0.723,5.271,0.851,7.006
	c0.297,4.01,0.145,18.726,0.028,27.14c-0.08,5.78,0.629,11.54,2.109,17.121c0.848,3.199,1.787,6.835,2.732,10.68
	c-7.468,1.536-15.893,2.967-24.402,4.141c-2.119-11.761-4.225-22.321-6.205-30.518c-2.645-10.952-5.721-30.518-9.159-58.233
	c0.109-0.024,0.171-0.04,0.183-0.043l-0.15-0.619c-0.009,0.001-0.046,0.011-0.112,0.026c-0.329-2.657-0.66-5.387-0.995-8.192
	l26.37-3.818c1.582-0.229,2.948-1.187,3.749-2.628l0.636-1.145c0.576-1.038,0.811-2.273,0.661-3.479
	c-0.651-5.245-4.075-32.529-7.54-55.056c0.239-0.531,0.331-1.131,0.237-1.737l-3.777-24.674l-30.07,4.445l-1.705-22.326
	c-1.969-21.36-3.624-40.528-4.816-54.72c0.157-0.016,0.374-0.037,0.643-0.064c0.04-0.005,0.083-0.01,0.127-0.013
	c0.088-0.009,0.18-0.018,0.277-0.027c0.048-0.006,0.099-0.01,0.15-0.015c0.022-0.002,0.045-0.004,0.068-0.006
	c0.068-0.007,0.14-0.014,0.212-0.02c0.071-0.007,0.146-0.015,0.221-0.022c0.077-0.008,0.155-0.015,0.234-0.022h0.005
	c0.108-0.011,0.218-0.022,0.333-0.032c0.139-0.013,0.283-0.027,0.433-0.041c0.096-0.008,0.194-0.018,0.293-0.026l0.217-0.021
	c0.102-0.009,0.207-0.019,0.314-0.028c0.503-0.047,1.047-0.095,1.631-0.147c0.234-0.021,0.474-0.042,0.72-0.063
	c0.052-0.005,0.104-0.01,0.155-0.014c0.117-0.01,0.235-0.02,0.353-0.03c0.035-0.004,0.069-0.007,0.105-0.009
	c0.224-0.02,0.454-0.04,0.687-0.059c0.09-0.007,0.18-0.015,0.271-0.022c0.242-0.022,0.49-0.042,0.741-0.062
	c0.126-0.011,0.254-0.022,0.382-0.032c0.417-0.034,0.847-0.068,1.286-0.104c0.146-0.012,0.294-0.023,0.442-0.035
	c0.092-0.007,0.184-0.015,0.276-0.021c0.445-0.036,0.899-0.07,1.362-0.105c0.017-0.002,0.034-0.003,0.052-0.005l1.465,19.134
	c0.075,0.959,0.659,1.781,1.476,2.168c1.599,18.221,3.878,41.194,4.969,48.045c0.14,0.885,0.88,1.52,1.725,1.52
	c0.064,0,0.126-0.003,0.191-0.01c6.601-0.743,18.819-2.346,36.318-4.766C213.492,138.17,213.467,138.306,213.442,138.441
	 M144.876,195.655c-0.096-0.628-0.642-1.069-1.215-0.985l-18.738,2.77c-0.654-4.636-1.293-9.24-1.886-13.577
	c0.119,0.043,0.242,0.08,0.367,0.108l5.343,1.177c0.776,0.171,1.56,0.257,2.346,0.257c0.532,0,1.063-0.04,1.594-0.118l52.056-7.693
	c1.158-0.172,2.274-0.527,3.316-1.055l6.043-3.063c0.344-0.174,0.642-0.407,0.888-0.679c3.426,22.38,6.785,49.146,7.429,54.335
	c0.135,1.074-0.074,2.171-0.586,3.092l-0.635,1.144c-0.703,1.267-1.9,2.107-3.283,2.307l-46.239,6.694L144.876,195.655z
	 M151.039,240.461l-10.857,1.572c-1.259,0.182-2.552,0.001-3.736-0.524c-0.746-0.331-1.611-0.714-2.395-1.059
	c-1.441-0.636-2.512-1.971-2.862-3.573c-1.508-6.885-3.933-22.959-6.176-38.801l18.741-2.769c0.225-0.032,0.444,0.167,0.487,0.445
	L151.039,240.461z M188.001,168.738l-3.281-21.427l6.713-0.992l3.681,24.038c0.16,1.047-0.364,2.069-1.302,2.544l-6.043,3.063
	c-0.81,0.41-1.664,0.707-2.55,0.889l2.547-6.157C188.022,170.08,188.103,169.403,188.001,168.738 M187.365,168.836
	c0.083,0.548,0.016,1.106-0.194,1.614l-2.703,6.532l-51.875,7.668c-1.237,0.183-2.483,0.139-3.707-0.131l-5.345-1.178
	c-0.987-0.217-1.754-1.037-1.907-2.037l-3.692-24.123l66.141-9.776L187.365,168.836z M151.96,60.311l-0.791-11.684
	c0.405-0.076,1.08-0.202,1.558-0.291c0.173,2.204,1.148,14.524,2.7,32.745c-9.336-0.489-32.553-1.953-34.311-3.184
	c-1.554-1.088-3.941-12.947-5.196-20.813c6.095-1.579,14.062-3.609,21.341-5.353l0.793,10.232c0.013,0.164,0.149,0.292,0.314,0.294
	c0.044,0.001,0.211,0.003,0.483,0.003c1.899,0,8.818-0.102,12.877-1.52C151.9,60.704,151.978,60.56,151.96,60.311 M117.093,67
	c2.146,10.363,3.282,11.158,3.656,11.419c2.202,1.542,30.181,3.066,34.732,3.305c0.152,1.782,0.309,3.621,0.473,5.51
	c-1.867,0.093-11.396,0.552-20.975,0.686c-6.237,0.088-11.275,0.023-14.968-0.193c-6.128-0.359-7.116-1.055-7.257-1.338
	c-1.476-2.965-1.837-25.609-1.871-27.99c1.288-0.339,2.779-0.728,4.418-1.154C115.488,58.396,116.193,62.651,117.093,67
	 M167.995,71.895c0.401-0.03,0.81-0.059,1.222-0.09c0.104-0.008,0.207-0.014,0.313-0.022c0.832-0.06,1.686-0.119,2.557-0.177
	c0.238-0.016,0.477-0.031,0.717-0.047c0.091-0.006,0.182-0.012,0.273-0.018l1.645,15.579c0.072,0.678,0.411,1.309,0.931,1.732
	l7.902,6.435c-0.282-0.012-0.566-0.036-0.851-0.076L171.12,92.84l-0.02-0.004c-0.879-0.121-1.568-0.883-1.64-1.808L167.995,71.895z
	 M212.219,87.847c0.02,0.089,0.038,0.177,0.057,0.266c0.033,0.154,0.066,0.309,0.097,0.464c0.032,0.145,0.062,0.292,0.091,0.438
	c0.043,0.208,0.086,0.417,0.127,0.625c0.038,0.187,0.075,0.373,0.112,0.56c0.043,0.209,0.084,0.418,0.123,0.629
	c0.045,0.234,0.089,0.469,0.133,0.704c0.009,0.046,0.019,0.091,0.027,0.138c0.04,0.216,0.079,0.434,0.119,0.651
	c0.036,0.196,0.07,0.39,0.104,0.586c0.028,0.154,0.054,0.307,0.081,0.461c0.052,0.295,0.102,0.59,0.15,0.885
	c0.024,0.147,0.05,0.295,0.073,0.442c0.143,0.876,0.278,1.752,0.402,2.629c0.022,0.148,0.043,0.296,0.064,0.444
	c0.037,0.259,0.072,0.517,0.106,0.776c0.056,0.411,0.11,0.822,0.161,1.234c0.027,0.211,0.052,0.424,0.079,0.636l0.06,0.51
	c0.007,0.066,0.015,0.13,0.023,0.197c0.02,0.175,0.039,0.349,0.058,0.522c0.051,0.451,0.099,0.904,0.145,1.356
	c0.038,0.361,0.072,0.723,0.106,1.086c0.025,0.27,0.049,0.541,0.073,0.811c0.014,0.15,0.026,0.3,0.039,0.449
	c0.016,0.202,0.033,0.403,0.049,0.603c0.018,0.226,0.035,0.453,0.053,0.679c0.078,1.054,0.146,2.111,0.201,3.168
	c0.01,0.21,0.022,0.418,0.03,0.627c0.008,0.153,0.015,0.306,0.022,0.46c0.007,0.14,0.013,0.278,0.018,0.418
	c0.009,0.213,0.017,0.428,0.025,0.643c0.03,0.827,0.053,1.644,0.066,2.448c0.007,0.402,0.012,0.802,0.016,1.198
	c0.006,0.631,0.007,1.255,0.003,1.87c0,0.151-0.001,0.3-0.003,0.45c-0.008,0.945-0.026,1.87-0.053,2.775
	c-0.004,0.117-0.007,0.233-0.011,0.348c-0.004,0.146-0.009,0.29-0.015,0.434c-0.008,0.214-0.016,0.429-0.025,0.643
	c-0.041,0.996-0.09,1.966-0.152,2.91c-0.007,0.116-0.015,0.232-0.022,0.347c-0.014,0.203-0.027,0.405-0.041,0.604
	c-0.012,0.167-0.025,0.333-0.037,0.498c-0.013,0.178-0.027,0.356-0.041,0.531c-0.055,0.702-0.117,1.387-0.18,2.055
	c-0.011,0.099-0.02,0.197-0.029,0.295c-0.214,2.171-0.47,4.161-0.739,5.946c-0.019,0.124-0.037,0.246-0.056,0.366
	c-0.038,0.251-0.077,0.497-0.116,0.738c-0.011,0.072-0.022,0.143-0.035,0.214c-0.022,0.143-0.046,0.282-0.069,0.422
	c-0.012,0.07-0.024,0.138-0.035,0.208l-0.024,0.142c-17.6,2.435-29.881,4.046-36.502,4.791c-0.589,0.066-1.131-0.367-1.229-0.987
	c-1.082-6.805-3.344-29.576-4.941-47.745c0.016,0.002,0.031,0.006,0.046,0.009l11.586,2.371l0.02,0.003
	c0.433,0.061,0.868,0.091,1.302,0.091c0.86,0,1.715-0.119,2.554-0.358l25.753-7.714H212.219z M207.955,72.378
	c0.243,0.716,0.479,1.432,0.707,2.15c0.099,0.307,0.196,0.616,0.292,0.923c0.097,0.311,0.193,0.623,0.289,0.936
	c0.012,0.04,0.025,0.08,0.037,0.121c0.091,0.297,0.18,0.596,0.268,0.895c0.096,0.322,0.19,0.645,0.284,0.968
	c0,0.003,0.001,0.006,0.002,0.01c0.094,0.321,0.185,0.644,0.277,0.965c0.001,0.007,0.003,0.014,0.005,0.021
	c0.091,0.325,0.181,0.649,0.269,0.973c0.179,0.655,0.354,1.31,0.522,1.964c0.413,1.606,0.797,3.215,1.153,4.829
	c0.009,0.034,0.017,0.068,0.024,0.103l-25.794,7.726c-0.58,0.165-1.169,0.269-1.763,0.31l-8.481-6.905
	c-0.392-0.32-0.649-0.798-0.703-1.312l-1.642-15.554V71.5c0.549-0.034,1.107-0.07,1.669-0.102c0.047-0.004,0.097-0.007,0.146-0.009
	c0.199-0.012,0.398-0.023,0.599-0.035c0.005-0.001,0.01-0.001,0.014-0.001c0.559-0.033,1.122-0.064,1.689-0.094
	c0.188-0.011,0.378-0.021,0.568-0.031c0.127-0.008,0.254-0.013,0.382-0.02c0.234-0.012,0.47-0.025,0.706-0.036
	c0.038-0.002,0.075-0.005,0.113-0.006c0.239-0.012,0.479-0.025,0.718-0.035c0.279-0.015,0.559-0.027,0.839-0.04
	c0.211-0.01,0.424-0.019,0.636-0.028l0.462-0.021c0.218-0.01,0.437-0.019,0.657-0.027c0.56-0.024,1.122-0.046,1.684-0.065
	c0.257-0.01,0.514-0.019,0.772-0.027c0.39-0.014,0.779-0.026,1.168-0.037c0.259-0.009,0.517-0.016,0.776-0.022
	c0.259-0.008,0.517-0.015,0.776-0.021c0.664-0.017,1.327-0.03,1.986-0.041c0.209-0.004,0.418-0.007,0.628-0.011
	c0.281-0.004,0.562-0.008,0.843-0.009c0.253-0.003,0.505-0.005,0.756-0.007c0.126-0.002,0.251-0.003,0.377-0.003
	c5.769-0.032,11.098,0.176,15.014,0.79C207.772,71.834,207.865,72.106,207.955,72.378 M207.442,70.88
	c-14.806-2.194-48.445,1.1-52.113,1.47c-1.137-13.566-1.839-22.445-1.971-24.127c6.346-1.117,14.402-1.299,20.122-1.252l0.834,8.228
	c0.015,0.15,0.135,0.27,0.287,0.284c0.104,0.011,1.196,0.114,2.662,0.114c2.055,0,4.843-0.203,6.661-1.151
	c0.099-0.028,0.235-0.117,0.197-0.444l-0.753-6.68c7.221,0.418,13.077,1.045,14.118,1.16
	C201.465,55.806,204.787,63.282,207.442,70.88 M188.79,18.755l1.316,0.003l0.018-0.002c0.183-0.021,0.351,0.103,0.384,0.286
	c3.31,18.174,8.568,28.808,8.801,29.271c0.017,0.055,0.153,0.561-0.431,1.419c-0.299-0.566-0.6-1.131-0.907-1.694
	C197.887,47.871,192.171,36.384,188.79,18.755 M189.798,17.33l1.852,0.005c0.369,0,0.407,0.207,0.421,0.284
	c3.315,18.209,9.236,30.253,9.474,30.735c0.02,0.057,0.19,0.666-0.632,1.697l-0.014,0.019c-0.004,0.005-0.362,0.516-1.243,1.149
	c-0.148-0.286-0.297-0.572-0.446-0.858c1.065-1.363,0.723-2.216,0.679-2.311l-0.006-0.011c-0.053-0.105-5.398-10.717-8.747-29.112
	c-0.092-0.505-0.557-0.856-1.065-0.807l-1.403-0.003c-0.04-0.217-0.08-0.434-0.12-0.653c-0.011-0.056-0.026-0.111-0.046-0.164
	C189.204,17.308,189.789,17.33,189.798,17.33 M187.433,17.305c0.076-0.001,0.151-0.003,0.228-0.004
	c0.129,0.033,0.234,0.138,0.259,0.278c2.976,16.338,8.045,27.711,9.231,30.226c-1.739-0.187-7.236-0.746-13.856-1.126l-3.189-28.217
	C182.517,17.997,184.964,17.604,187.433,17.305 M178.872,18.706c0.203-0.041,0.404-0.083,0.606-0.122
	c0.433,3.827,3.681,32.568,3.997,35.373c-2.631,1.265-7.394,1.001-8.553,0.913l-3.454-34.083L178.872,18.706z M157.343,24.686
	c4.161-1.381,8.694-2.815,13.466-4.08l2.606,25.726c-5.828-0.046-14.044,0.147-20.456,1.313c-0.396,0.072-1.297,0.24-1.834,0.342
	l-1.414-20.863C152.303,26.326,154.843,25.515,157.343,24.686 M149.085,27.316c0.301,4.442,2.041,30.115,2.23,32.892
	c-4.036,1.331-10.948,1.413-12.584,1.413h-0.064L136.293,31C140.714,29.801,144.964,28.576,149.085,27.316 M112.991,37.587
	c4.489-1.422,9.042-2.782,13.523-4.039c3.138-0.785,6.184-1.576,9.153-2.378l1.544,19.916c-10.02,2.401-21.372,5.349-27.35,6.921
	l-0.705-19.231C110.485,38.368,111.764,37.972,112.991,37.587 M106.141,39.7l2.386-0.659c0.126,3.419,1.003,27.362,1.092,29.813
	c-2.883,1.429-8,1.973-9.337,2.095l-1.33-29.028C101.381,41.158,103.813,40.412,106.141,39.7 M86.162,46.465
	c0-0.12,0.075-0.227,0.185-0.27c3.585-1.363,7.763-2.745,11.976-4.076l0.858,18.758c-6.445,1.729-11.579,3.143-13.019,3.54V46.465z
	 M86.169,65.078c1.16-0.321,6.388-1.763,13.042-3.549l0.449,9.78c0.002,0.085,0.043,0.166,0.106,0.224
	c0.059,0.052,0.134,0.08,0.212,0.08H100c0.272-0.02,6.656-0.501,10.052-2.263c0.148-0.044,0.217-0.179,0.209-0.403l-0.377-10.286
	c0.118-0.032,0.239-0.063,0.363-0.096c0.045,3.209,0.409,25.039,1.936,28.109c0.295,0.591,1.445,1.318,7.791,1.69
	c2.646,0.155,5.976,0.232,9.938,0.232c1.59,0,3.284-0.012,5.077-0.038c9.626-0.135,19.203-0.597,21.019-0.689
	c1.006,11.676,2.206,25.27,3.554,39.891l1.709,22.365l-44.066,6.513l3.792,24.763c0.134,0.873,0.638,1.636,1.348,2.114
	c2.657,19.429,6.232,44.418,8.22,53.5c0.394,1.798,1.601,3.301,3.228,4.019c0.785,0.345,1.648,0.727,2.394,1.058
	c0.968,0.428,2.001,0.648,3.038,0.648c0.35,0,0.7-0.025,1.048-0.076l30.732-4.449c0.337,2.824,0.671,5.572,1.002,8.247
	c-4.682,1.08-30.837,6.886-55.615,7.75l-22.474-88.064c-2.897-15.494-5.503-37.259-5.956-43.948
	c-0.158-2.315-0.426-10.091-0.709-19.201L86.169,65.078z M62.961,148.049c0.584-1.146,1.175-2.3,1.77-3.456
	c6.915,3.338,13.143,6.445,18.523,9.239c-0.723,1.439-1.462,2.889-2.177,4.293c-1.608,3.161-3.269,6.425-4.815,9.657
	c-6.196-3.04-12.656-6.126-18.714-8.937C59.307,155.28,61.121,151.66,62.961,148.049 M75.922,168.332
	c-3.173,6.654-6.071,13.461-8.876,20.045l-0.69,1.618c-0.49,1.152-1.11,1.45-2.755,1.322c-1.432-0.11-3.152-0.342-4.816-0.564
	c-0.985-0.133-1.914-0.258-2.773-0.357c-1.293-0.15-2.029-0.343-3.135-0.778c0.979-0.657,2.018-1.304,3.029-1.932
	c0.925-0.576,1.881-1.171,2.772-1.762c0.73-0.485,0.993-0.745,1.332-1.542c2.678-6.289,5.549-12.973,8.653-19.572
	C71.098,165.978,73.529,167.158,75.922,168.332 M49.234,176.193c2.203-4.742,4.537-9.633,6.934-14.54
	c0.363-0.741,0.729-1.484,1.095-2.23c3.515,1.631,7.164,3.355,10.82,5.108c-3.11,6.61-5.985,13.302-8.666,19.599
	c-0.285,0.67-0.463,0.837-1.096,1.257c-0.881,0.586-1.834,1.179-2.756,1.752c-1.14,0.708-2.314,1.44-3.404,2.187
	c-1.699-0.707-3.511-1.478-5.265-2.224c-0.747-0.317-1.486-0.631-2.205-0.936C46.154,182.89,47.673,179.553,49.234,176.193
	 M29.983,222.33c1.784-4.949,3.775-10.161,5.918-15.489c2.003-4.982,4.188-10.19,6.497-15.483c0.661-1.517,1.34-3.054,2.03-4.603
	c0.723,0.306,1.465,0.622,2.216,0.941c1.791,0.761,3.643,1.549,5.373,2.268c1.518,0.632,2.338,0.889,3.921,1.072
	c0.854,0.098,1.78,0.223,2.76,0.354c1.673,0.226,3.404,0.458,4.853,0.57c0.236,0.018,0.46,0.028,0.673,0.028
	c0.273,0,0.527-0.018,0.765-0.059c-1.511,3.827-2.976,7.766-4.395,11.581c-2.881,7.747-5.86,15.756-9.278,22.955
	c-0.597,1.255-0.94,1.48-1.623,1.926c-0.248,0.163-0.53,0.347-0.875,0.611c-0.335,0.256-0.789,0.437-1.225,0.601
	c-1.793,0.673-6.09-0.404-8.542-1.293c-1.497-0.542-3.008-1.076-4.47-1.592c-1.764-0.624-3.592-1.269-5.372-1.923l-0.219,0.599
	c1.783,0.655,3.612,1.301,5.381,1.925c1.458,0.516,2.968,1.05,4.463,1.591c1.704,0.618,4.93,1.538,7.27,1.538
	c0.66,0,1.25-0.074,1.715-0.248c0.479-0.181,0.981-0.382,1.385-0.691c0.329-0.25,0.599-0.427,0.838-0.583
	c0.734-0.481,1.177-0.77,1.85-2.187c3.431-7.225,6.414-15.247,9.3-23.006c1.479-3.978,3.009-8.092,4.587-12.068
	c0.479-0.26,0.865-0.701,1.17-1.417l0.689-1.617c2.843-6.675,5.783-13.576,9.003-20.308l0.002-0.002l0.061,0.029
	c1.582-3.319,3.292-6.681,4.948-9.932c0.761-1.498,1.551-3.047,2.32-4.581l0.143-0.283l-0.282-0.146
	c-5.444-2.834-11.771-5.991-18.807-9.388c1.428-2.771,2.882-5.56,4.344-8.33c1.896-3.597,3.842-7.231,5.782-10.804
	c1.635-3.011,3.29-6.02,4.917-8.942c1.301-2.334,2.583-4.612,3.809-6.768c0.899-1.576,1.704-2.978,2.464-4.287
	c0.105-0.181,0.21-0.362,0.315-0.542c0.267,8.538,0.518,15.688,0.669,17.896c0.454,6.705,3.065,28.515,5.967,44.032l0.066-0.012
	l-0.065,0.014c0.029,0.161,0.63,4.239-5.954,31.68c-4.83,11.061-9.032,29.579-11.982,45.011c-2.698-0.054-26.621-0.672-50.452-5.509
	C26.407,232.594,28.068,227.644,29.983,222.33 M20.702,252.712l0.15-0.622c1.082-4.475,2.349-9.067,3.865-14.019
	c23.611,4.8,47.323,5.464,50.519,5.533c-0.347,1.827-0.676,3.61-0.987,5.327c-0.804,4.454-1.529,8.732-2.163,12.645
	c-2.759-0.08-28.247-0.934-52.125-5.768C20.204,254.77,20.454,253.738,20.702,252.712 M19.817,256.43
	c23.702,4.8,48.989,5.686,52.166,5.781c-0.063,0.393-0.125,0.781-0.187,1.166c-2.515-0.093-28.206-1.128-52.201-5.976
	C19.668,257.076,19.742,256.753,19.817,256.43 M49.156,461.486c-15.38,0.308-25.543-4.194-26.921-4.841
	c-0.188-2.322-2.071-26.009-1.223-33.737c0.848-7.729,1.104-28.632-1.017-39.59c-0.068-0.348-0.116-0.703-0.156-1.005
	c-0.269-2.073-0.385-4.208-0.496-6.272l-0.014-0.24c-0.195-3.622-0.319-7.19-0.414-10.205c-0.11-3.503-0.207-7.22-0.299-11.606
	c-0.011-0.431-0.02-0.869-0.029-1.315c-0.093-4.476-0.175-9.274-0.253-14.667c-0.069-4.857-0.13-9.909-0.185-15.446
	c-0.05-4.902-0.092-9.893-0.13-15.262c-0.032-4.584-0.061-9.331-0.083-14.11c-0.02-4.001-0.035-8.036-0.048-11.994
	c-0.004-1.633-0.009-3.267-0.013-4.901c-0.002-0.79-0.012-1.592-0.022-2.367c-0.033-2.356-0.066-4.792,0.155-7.184
	c0.268-2.9,0.81-5.825,1.447-8.721c23.814,4.814,49.293,5.879,52.242,5.987c-1.647,10.363-2.584,17.619-2.598,17.724l-0.002,0.034
	c-0.005,0.26-0.5,26.319-4.724,56.107c-0.612,4.317-1.086,9.948-1.45,16.36c-0.952,16.758-1.157,38.855-1.123,56.753
	c0.047,25.118,0.56,47.265,0.628,50.143C58.404,461.278,54.031,461.387,49.156,461.486 M110.341,267.268
	c-1.321,2.216-1.726,4.01-1.743,4.086l-0.002,0.012c-0.022,0.125-2.269,12.8-3.717,28.719c-1.396,15.363-3.02,18.285-3.094,18.411
	c-0.226,0.295-3.209,4.302-3.209,10.423l0.003,0.045c1.754,12.277-0.153,57.966-1.352,74.758
	c-1.094,15.312-1.232,50.614-1.238,52.18c-0.102,0.231-1.239,2.24-10.189,3.436c-7.031,0.94-14.084,1.444-22.734,1.77
	c-0.144-6.06-2.158-94.242,1.94-123.142c4.182-29.503,4.712-55.352,4.728-56.169c0.076-0.59,1.996-15.332,5.143-32.752
	c2.949-16.32,7.608-38.389,13.059-50.858l0.018-0.053c4.382-18.262,5.597-26.257,5.904-29.642l23.569,92.353
	C113.724,262.551,111.534,265.268,110.341,267.268 M118.174,261.211l0.004-0.003l-0.056-0.217c-0.129-0.56-0.215-0.914-0.238-1.009
	l-0.017,0.005l-1.313-5.143c24.675-0.878,50.664-6.624,55.531-7.745c0.957,7.705,1.885,14.78,2.785,21.218
	c-3.222,0.779-28.219,6.653-53.706,7.544C120.163,269.943,118.776,263.789,118.174,261.211 M121.27,276.495
	c25.358-0.896,50.206-6.704,53.688-7.544c0.057,0.397,0.112,0.792,0.166,1.185c-3.542,0.855-28.369,6.648-53.668,7.526
	C121.397,277.276,121.334,276.886,121.27,276.495 M179.843,492.455c-13.5-0.391-21.154-2.965-21.751-3.304
	c-0.565-1.022-3.983-15.95-5.432-32.663c-1.449-16.708-10.939-56.135-11.035-56.532l-0.005-0.019
	c-0.09-0.289-4.995-16.169-9.568-31.725c-3.761-12.794-7.299-25.367-7.749-28.869c-1.034-8.046-2.355-45.394-2.022-52.491
	c0.097-2.096-0.221-5.196-0.727-8.555c25.191-0.884,49.891-6.619,53.661-7.526c2.198,15.469,4.218,27.041,6.034,34.563
	c3.407,14.106,7.189,35.208,10.761,57.407c2.928,18.188,5.713,37.114,8.035,53.526c5.304,37.499,9.334,69.915,9.763,73.378
	C197.639,492.195,187.354,492.673,179.843,492.455 M210.433,489.512c-0.462-3.741-4.479-36.021-9.756-73.333
	c-3.481-24.615-8.007-54.878-12.49-79.849c8.524-1.176,16.963-2.608,24.441-4.148c1.993,8.152,3.995,17.175,5.223,24.917
	c2.891,18.233,7.379,61.322,7.425,61.764c0.068,0.545,6.867,54.335,7.411,63.994C224.784,485.902,217.314,488.038,210.433,489.512"
      />
      <path
        fill="#E0E0E0"
        d="M474.261,277.661c-2.163,0.315-13.257,1.864-28.068,2.673c-15.181,0.828-37.48,1.03-57.521-2.723
	c-0.005-0.386-0.01-0.77-0.015-1.154c13.144,2.45,27.242,3.212,39.672,3.212c6.562,0,12.659-0.212,17.906-0.499
	c14.597-0.797,25.591-2.312,28.009-2.662C474.25,276.893,474.255,277.278,474.261,277.661"
      />
      <path
        fill="#E0E0E0"
        d="M358.337,276.463c-0.004,0.345-0.008,0.692-0.013,1.04c-20.034,3.748-42.32,3.547-57.494,2.719
	c-14.87-0.813-25.992-2.369-28.094-2.677c0.004-0.347,0.01-0.693,0.015-1.041c2.363,0.344,13.386,1.866,28.036,2.666
	c5.249,0.287,11.343,0.499,17.905,0.499C331.114,279.669,345.203,278.908,358.337,276.463"
      />
      <path
        fill={patchColor}
        onClick={() => handleClick("patch")}
        onMouseEnter={() => setPatchActive(true)}
        onMouseLeave={() => setPatchActive(false)}
        style={
          patchActive && !preview ? {fill: adjust(patchColor, 55)} : {fill: patchColor}
        }
        d="M358.315,278.153c-0.444,33.753-2.104,76.733-2.618,89.479c-36.708,6.819-77.197,0.375-81.481-0.342v-0.001
	c-0.145-20.071-0.367-35.805-1.037-39.161c-0.808-4.037-0.815-23.826-0.453-49.94c2.315,0.337,13.362,1.867,28.061,2.669
	c5.249,0.287,11.342,0.5,17.905,0.5C331.107,281.357,345.186,280.597,358.315,278.153"
      />
      <path
        fill={patchColor}
        onClick={() => handleClick("patch")}
        onMouseEnter={() => setPatchActive(true)}
        onMouseLeave={() => setPatchActive(false)}
        style={
          patchActive && !preview ? {fill: adjust(patchColor, 55)} : {fill: patchColor}
        }
        d="M473.816,328.128c-0.672,3.356-0.894,19.091-1.038,39.16c-4.3,0.705-44.827,7.001-81.485,0.236
	c-0.519-12.869-2.167-55.624-2.614-89.262c13.138,2.447,27.226,3.208,39.65,3.208c6.561,0,12.658-0.213,17.905-0.5
	c14.646-0.8,25.668-2.322,28.035-2.665C474.629,304.361,474.622,324.097,473.816,328.128"
      />
      <path
        fill={darkContrast}
        onClick={() => handleClick("contrast")}
        onMouseEnter={() => setDarkContrastActive(true)}
        onMouseLeave={() => setDarkContrastActive(false)}
        style={
          darkContrastActive && !preview
            ? {fill: adjust(darkContrast, 55)}
            : {fill: darkContrast}
        }
        d="M472.17,175.64l-31.165,0.339c-1.226,0.014-2.423-0.218-3.569-0.689l-5.028-2.059
	c-0.931-0.383-1.563-1.388-1.574-2.504l-0.23-26.536l40.868-0.443C471.669,153.409,471.91,164.218,472.17,175.64"
      />
      <path
        fill="#E0E0E0"
        d="M321.168,84.131c-0.004,0-0.007,0.001-0.011,0.001c-3.85,0.545-18.2,2.297-41.773,2.057
	c0.278-2.166,0.578-4.342,0.896-6.525c3.891-0.342,27.983-2.501,29.858-3.714c0.359-0.233,1.317-0.853,3.528-16.744
	c0.973-6.993,1.767-13.942,1.925-15.346c1.92,0.046,3.89,0.093,5.891,0.137l-0.397,0.019
	C321.103,44.371,322.727,79.161,321.168,84.131"
      />
      <path
        fill={darkContrast}
        onClick={() => handleClick("contrast")}
        onMouseEnter={() => setDarkContrastActive(true)}
        onMouseLeave={() => setDarkContrastActive(false)}
        style={
          darkContrastActive && !preview
            ? {fill: adjust(darkContrast, 55)}
            : {fill: darkContrast}
        }
        d="M314.951,43.845c-0.173,1.52-0.957,8.376-1.917,15.272c-2.014,14.478-2.991,16.134-3.243,16.297
	c-1.338,0.866-18.163,2.612-29.415,3.601c0.608-4.117,1.291-8.255,2.054-12.385l19.336-1.997c0.429-0.044,0.752-0.402,0.752-0.833
	v-4.205c0-0.234-0.098-0.459-0.271-0.618c-0.173-0.157-0.407-0.237-0.639-0.215l-17.948,1.577c0.914-4.429,1.927-8.831,3.05-13.168
	c0.199-0.772,0.325-1.257,0.394-1.571c0.01-0.027,0.016-0.055,0.02-0.086c0.063-0.301,0.066-0.42,0.031-0.496
	c-0.091-0.65-0.169-1.305-0.235-1.96C289.079,43.129,300.59,43.497,314.951,43.845"
      />
      <path
        fill={lightContrast}
        onClick={() => handleClick("base")}
        onMouseEnter={() => setLightContrastActive(true)}
        onMouseLeave={() => setLightContrastActive(false)}
        style={
          lightContrastActive && !preview
            ? {fill: adjust(lightContrast, 55)}
            : {fill: lightContrast}
        }
        d="M301.882,59.596v4.205c0,0.102-0.078,0.188-0.18,0.198l-19.15,1.978c0.311-1.664,0.636-3.326,0.974-4.985
	l18.137-1.595c0.077-0.006,0.129,0.03,0.154,0.052C301.84,59.471,301.882,59.519,301.882,59.596"
      />
      <path
        fill={darkContrast}
        onClick={() => handleClick("contrast")}
        onMouseEnter={() => setDarkContrastActive(true)}
        onMouseLeave={() => setDarkContrastActive(false)}
        style={
          darkContrastActive && !preview
            ? {fill: adjust(darkContrast, 55)}
            : {fill: darkContrast}
        }
        d="M465.225,70.318l-28.675-5.41c-0.157-0.029-0.288-0.217-0.31-0.446l-0.691-6.965
	c-0.024-0.243,0.072-0.427,0.168-0.515c0.07-0.065,0.145-0.091,0.223-0.076l27.712,4.993c0.256,1.269,0.503,2.54,0.741,3.813
	L436.765,60.7l-0.114,0.627l27.867,5.055C464.762,67.694,464.997,69.006,465.225,70.318"
      />
      <path
        fill={darkContrast}
        onClick={() => handleClick("contrast")}
        onMouseEnter={() => setDarkContrastActive(true)}
        onMouseLeave={() => setDarkContrastActive(false)}
        style={
          darkContrastActive && !preview
            ? {fill: adjust(darkContrast, 55)}
            : {fill: darkContrast}
        }
        d="M466.617,79.005c-11.25-0.99-27.964-2.728-29.298-3.59c-0.252-0.163-1.232-1.834-3.293-16.499
	c-0.976-6.953-1.78-13.867-1.961-15.45c14.344-0.214,25.873-0.382,28.009-0.414c-0.065,0.657-0.143,1.314-0.234,1.967
	c-0.072,0.153,0.014,0.484,0.446,2.153c1.197,4.625,2.269,9.325,3.23,14.054l-27.464-4.948c-0.273-0.049-0.554,0.037-0.769,0.235
	c-0.271,0.25-0.409,0.642-0.369,1.047l0.691,6.965c0.05,0.514,0.392,0.931,0.828,1.012l28.908,5.453
	C465.799,73.665,466.223,76.339,466.617,79.005"
      />
      <path
        fill="#E0E0E0"
        d="M467.611,86.19c-23.506,0.232-37.814-1.515-41.658-2.058c-0.003,0-0.007-0.001-0.01-0.001
	c-1.489-4.756-0.041-37.298,0.108-40.575c1.824-0.028,3.619-0.054,5.372-0.08c0.167,1.457,0.981,8.471,1.972,15.527
	c2.26,16.094,3.218,16.714,3.578,16.947c1.857,1.201,25.508,3.33,29.74,3.704C467.033,81.84,467.331,84.021,467.611,86.19"
      />
      <path
        fill={darkContrast}
        onClick={() => handleClick("contrast")}
        onMouseEnter={() => setDarkContrastActive(true)}
        onMouseLeave={() => setDarkContrastActive(false)}
        style={
          darkContrastActive && !preview
            ? {fill: adjust(darkContrast, 55)}
            : {fill: darkContrast}
        }
        d="M461.923,45.89h-1.307c-0.087-0.346-0.145-0.594-0.158-0.692l0.001-0.008
	c0.94-6.577,0.491-13.509-0.052-18.167c-0.314-2.698-0.685-4.895-0.943-6.263c-0.159-0.843-0.286-1.44-0.365-1.794
	c-0.015-0.063-0.027-0.119-0.039-0.172l2.138,0.497c0.224,0.749,1.108,3.881,1.647,8.248C463.408,32.095,463.7,38.956,461.923,45.89
	"
      />
      <path
        fill={darkContrast}
        onClick={() => handleClick("contrast")}
        onMouseEnter={() => setDarkContrastActive(true)}
        onMouseLeave={() => setDarkContrastActive(false)}
        style={
          darkContrastActive && !preview
            ? {fill: adjust(darkContrast, 55)}
            : {fill: darkContrast}
        }
        d="M423.285,56.543c-1.665,0.166-10.13,0.937-14.888,0.024c-0.078-2.917-0.81-30.113-0.9-33.467l15.323,0.394
	L423.285,56.543z"
      />
      <path
        fill={darkContrast}
        onClick={() => handleClick("contrast")}
        onMouseEnter={() => setDarkContrastActive(true)}
        onMouseLeave={() => setDarkContrastActive(false)}
        style={
          darkContrastActive && !preview
            ? {fill: adjust(darkContrast, 55)}
            : {fill: darkContrast}
        }
        d="M339.791,23.1c-0.091,3.34-0.823,30.338-0.901,33.243c-4.758,0.911-13.223,0.14-14.888-0.025l0.465-32.824
	L339.791,23.1z"
      />
      <path
        d="M381.02,27.327c-2.807,0-5.091,2.285-5.091,5.092s2.284,5.092,5.091,5.092c2.808,0,5.092-2.285,5.092-5.092
	S383.827,27.327,381.02,27.327 M381.02,36.554c-2.279,0-4.135-1.855-4.135-4.135s1.856-4.135,4.135-4.135
	c2.28,0,4.136,1.855,4.136,4.135S383.299,36.554,381.02,36.554"
      />
      <path
        fill="#E0E0E0"
        d="M385.155,32.419c0,2.279-1.855,4.135-4.136,4.135c-2.279,0-4.135-1.856-4.135-4.135
	c0-2.28,1.856-4.135,4.135-4.135C383.3,28.284,385.155,30.139,385.155,32.419"
      />
      <path
        fill="#E0E0E0"
        d="M346.099,189.038c0.003,1.157-0.777,2.183-1.942,2.553l-7.383,2.346c-1.015,0.323-2.066,0.511-3.131,0.565
	l3.966-6.245c0.393-0.619,0.6-1.33,0.597-2.056l-0.062-23.513l7.883-0.031L346.099,189.038z"
      />
      <path
        fill={darkContrast}
        onClick={() => handleClick("contrast")}
        onMouseEnter={() => setDarkContrastActive(true)}
        onMouseLeave={() => setDarkContrastActive(false)}
        style={
          darkContrastActive && !preview
            ? {fill: adjust(darkContrast, 55)}
            : {fill: darkContrast}
        }
        d="M337.563,186.202c0.001,0.603-0.171,1.194-0.497,1.709l-4.198,6.609l-34.453,0.168
	c-1.44,0.005-2.859-0.237-4.207-0.719l-5.865-2.094c-1.097-0.392-1.838-1.398-1.84-2.503l-0.072-26.478l51.068-0.204
	L337.563,186.202z"
      />
      <path
        d="M346.743,144.683h-60.957v17.57l0.072,27.121c0.002,0.607,0.18,1.188,0.494,1.691c-0.064,18.384,0.39,29.64,0.69,37.094
	c0.087,2.171,0.163,4.045,0.211,5.716c0.038,1.318,0.546,2.606,1.428,3.627l0.977,1.13c1.318,1.524,7.856,2.21,9.391,2.21h34.432
	c1.535,0,8.073-0.686,9.39-2.21l0.977-1.13c0.883-1.021,1.39-2.309,1.428-3.627c0.049-1.671,0.124-3.544,0.212-5.715
	c0.301-7.478,0.757-18.778,0.688-37.259h-0.028c0.38-0.538,0.597-1.182,0.595-1.865l-0.073-26.704h0.073V144.683z M286.431,162.895
	l51.067-0.205l0.065,23.512c0.001,0.603-0.171,1.194-0.497,1.709l-4.198,6.61l-34.454,0.167c-1.439,0.005-2.858-0.237-4.207-0.718
	l-5.864-2.095c-1.098-0.391-1.838-1.397-1.84-2.502L286.431,162.895z M344.85,228.135c-0.087,2.171-0.163,4.048-0.212,5.722
	c-0.033,1.171-0.486,2.317-1.272,3.229l-0.976,1.128c-1.044,1.209-7.061,1.991-8.909,1.991h-34.432
	c-1.848,0-7.865-0.782-8.909-1.991l-0.976-1.128c-0.787-0.912-1.239-2.058-1.272-3.229c-0.049-1.675-0.125-3.551-0.212-5.722
	c-0.297-7.349-0.741-18.393-0.692-36.323c0.325,0.287,0.71,0.518,1.138,0.671l5.866,2.093c1.407,0.503,2.883,0.757,4.387,0.757
	h0.037l34.629-0.17c1.337-0.003,2.658-0.209,3.925-0.612l7.383-2.347c0.446-0.142,0.848-0.363,1.187-0.645
	C345.596,209.639,345.148,220.752,344.85,228.135 M344.157,191.591l-7.383,2.346c-1.015,0.323-2.066,0.511-3.131,0.565l3.966-6.245
	c0.393-0.619,0.6-1.33,0.597-2.056l-0.062-23.513l7.883-0.031l0.072,26.381C346.102,190.195,345.322,191.22,344.157,191.591
	 M346.099,162.012l-59.669,0.238v-16.923h59.669V162.012z"
      />
      <polygon points="283.426,258.49 278.056,269.304 279.646,270.353 283.404,262.11 287.544,270.342 289.149,269.218 " />
      <line
        fill="none"
        stroke={stitching}
        onClick={() => handleClick("stitching")}
        onMouseEnter={() => setStitchingActive(true)}
        onMouseLeave={() => setStitchingActive(false)}
        style={
          stitchingActive && !preview
            ? {stroke: adjust(stitching, 55)}
            : {stroke: stitching}
        }
        strokeWidth="0.319"
        strokeMiterlimit="10"
        strokeDasharray="0.957,0.957"
        x1="377.639"
        y1="129.193"
        x2="377.639"
        y2="149.656"
      />
      <path
        fill="none"
        stroke={stitching}
        onClick={() => handleClick("stitching")}
        onMouseEnter={() => setStitchingActive(true)}
        onMouseLeave={() => setStitchingActive(false)}
        style={
          stitchingActive && !preview
            ? {stroke: adjust(stitching, 55)}
            : {stroke: stitching}
        }
        strokeWidth="0.424"
        strokeMiterlimit="10"
        strokeDasharray="1.271,1.271"
        d="
	M273.844,366.146c0,0,0.215,0.038,0.629,0.106"
      />
      <line
        fill="none"
        stroke={stitching}
        onClick={() => handleClick("stitching")}
        onMouseEnter={() => setStitchingActive(true)}
        onMouseLeave={() => setStitchingActive(false)}
        style={
          stitchingActive && !preview
            ? {stroke: adjust(stitching, 55)}
            : {stroke: stitching}
        }
        strokeWidth="0.424"
        strokeMiterlimit="10"
        strokeDasharray="1.271,1.271"
        x1="275.414"
        y1="366.403"
        x2="275.415"
        y2="366.403"
      />
      <path
        fill="none"
        stroke={stitching}
        onClick={() => handleClick("stitching")}
        onMouseEnter={() => setStitchingActive(true)}
        onMouseLeave={() => setStitchingActive(false)}
        style={
          stitchingActive && !preview
            ? {stroke: adjust(stitching, 55)}
            : {stroke: stitching}
        }
        strokeWidth="0.424"
        strokeMiterlimit="10"
        strokeDasharray="1.271,1.271"
        d="
	M275.415,366.403c8.043,1.266,45.563,6.507,79.9,0.219"
      />
      <path
        fill="none"
        stroke={stitching}
        onClick={() => handleClick("stitching")}
        onMouseEnter={() => setStitchingActive(true)}
        onMouseLeave={() => setStitchingActive(false)}
        style={
          stitchingActive && !preview
            ? {stroke: adjust(stitching, 55)}
            : {stroke: stitching}
        }
        strokeWidth="0.424"
        strokeMiterlimit="10"
        strokeDasharray="1.271,1.271"
        d="
	M355.316,366.622c0.209-0.038,0.419-0.077,0.628-0.116"
      />
      <path
        fill="none"
        stroke={stitching}
        onClick={() => handleClick("stitching")}
        onMouseEnter={() => setStitchingActive(true)}
        onMouseLeave={() => setStitchingActive(false)}
        style={
          stitchingActive && !preview
            ? {stroke: adjust(stitching, 55)}
            : {stroke: stitching}
        }
        strokeWidth="0.424"
        strokeMiterlimit="10"
        strokeDasharray="1.271,1.271"
        d="
	M273.844,364.346c0,0,0.215,0.038,0.629,0.106"
      />
      <line
        fill="none"
        stroke={stitching}
        onClick={() => handleClick("stitching")}
        onMouseEnter={() => setStitchingActive(true)}
        onMouseLeave={() => setStitchingActive(false)}
        style={
          stitchingActive && !preview
            ? {stroke: adjust(stitching, 55)}
            : {stroke: stitching}
        }
        strokeWidth="0.424"
        strokeMiterlimit="10"
        strokeDasharray="1.271,1.271"
        x1="275.414"
        y1="364.604"
        x2="275.415"
        y2="364.604"
      />
      <path
        fill="none"
        stroke={stitching}
        onClick={() => handleClick("stitching")}
        onMouseEnter={() => setStitchingActive(true)}
        onMouseLeave={() => setStitchingActive(false)}
        style={
          stitchingActive && !preview
            ? {stroke: adjust(stitching, 55)}
            : {stroke: stitching}
        }
        strokeWidth="0.424"
        strokeMiterlimit="10"
        strokeDasharray="1.271,1.271"
        d="
	M275.415,364.604c8.043,1.265,45.563,6.506,79.9,0.219"
      />
      <path
        fill="none"
        stroke={stitching}
        onClick={() => handleClick("stitching")}
        onMouseEnter={() => setStitchingActive(true)}
        onMouseLeave={() => setStitchingActive(false)}
        style={
          stitchingActive && !preview
            ? {stroke: adjust(stitching, 55)}
            : {stroke: stitching}
        }
        strokeWidth="0.424"
        strokeMiterlimit="10"
        strokeDasharray="1.271,1.271"
        d="
	M355.316,364.821c0.209-0.038,0.419-0.076,0.628-0.116"
      />
      <path
        fill="none"
        stroke={stitching}
        onClick={() => handleClick("stitching")}
        onMouseEnter={() => setStitchingActive(true)}
        onMouseLeave={() => setStitchingActive(false)}
        style={
          stitchingActive && !preview
            ? {stroke: adjust(stitching, 55)}
            : {stroke: stitching}
        }
        strokeWidth="0.424"
        strokeMiterlimit="10"
        strokeDasharray="1.271,1.271"
        d="
	M474.334,248.732c0,0-0.216,0.033-0.63,0.093"
      />
      <line
        fill="none"
        stroke={stitching}
        onClick={() => handleClick("stitching")}
        onMouseEnter={() => setStitchingActive(true)}
        onMouseLeave={() => setStitchingActive(false)}
        style={
          stitchingActive && !preview
            ? {stroke: adjust(stitching, 55)}
            : {stroke: stitching}
        }
        strokeWidth="0.424"
        strokeMiterlimit="10"
        strokeDasharray="1.271,1.271"
        x1="472.765"
        y1="248.958"
        x2="472.764"
        y2="248.958"
      />
      <path
        fill="none"
        stroke={stitching}
        onClick={() => handleClick("stitching")}
        onMouseEnter={() => setStitchingActive(true)}
        onMouseLeave={() => setStitchingActive(false)}
        style={
          stitchingActive && !preview
            ? {stroke: adjust(stitching, 55)}
            : {stroke: stitching}
        }
        strokeWidth="0.424"
        strokeMiterlimit="10"
        strokeDasharray="1.271,1.271"
        d="
	M472.764,248.958c-8.443,1.167-49.349,6.216-83.913-0.109"
      />
      <path
        fill="none"
        stroke={stitching}
        onClick={() => handleClick("stitching")}
        onMouseEnter={() => setStitchingActive(true)}
        onMouseLeave={() => setStitchingActive(false)}
        style={
          stitchingActive && !preview
            ? {stroke: adjust(stitching, 55)}
            : {stroke: stitching}
        }
        strokeWidth="0.424"
        strokeMiterlimit="10"
        strokeDasharray="1.271,1.271"
        d="M388.85,248.848
	c-0.21-0.039-0.419-0.077-0.628-0.116"
      />
      <path
        fill="none"
        stroke={stitching}
        onClick={() => handleClick("stitching")}
        onMouseEnter={() => setStitchingActive(true)}
        onMouseLeave={() => setStitchingActive(false)}
        style={
          stitchingActive && !preview
            ? {stroke: adjust(stitching, 55)}
            : {stroke: stitching}
        }
        strokeWidth="0.424"
        strokeMiterlimit="10"
        strokeDasharray="1.271,1.271"
        d="
	M474.334,250.352c0,0-0.216,0.034-0.63,0.093"
      />
      <line
        fill="none"
        stroke={stitching}
        onClick={() => handleClick("stitching")}
        onMouseEnter={() => setStitchingActive(true)}
        onMouseLeave={() => setStitchingActive(false)}
        style={
          stitchingActive && !preview
            ? {stroke: adjust(stitching, 55)}
            : {stroke: stitching}
        }
        strokeWidth="0.424"
        strokeMiterlimit="10"
        strokeDasharray="1.271,1.271"
        x1="472.765"
        y1="250.578"
        x2="472.764"
        y2="250.578"
      />
      <path
        fill="none"
        stroke={stitching}
        onClick={() => handleClick("stitching")}
        onMouseEnter={() => setStitchingActive(true)}
        onMouseLeave={() => setStitchingActive(false)}
        style={
          stitchingActive && !preview
            ? {stroke: adjust(stitching, 55)}
            : {stroke: stitching}
        }
        strokeWidth="0.424"
        strokeMiterlimit="10"
        strokeDasharray="1.271,1.271"
        d="
	M472.764,250.578c-8.443,1.166-49.349,6.216-83.913-0.109"
      />
      <path
        fill="none"
        stroke={stitching}
        onClick={() => handleClick("stitching")}
        onMouseEnter={() => setStitchingActive(true)}
        onMouseLeave={() => setStitchingActive(false)}
        style={
          stitchingActive && !preview
            ? {stroke: adjust(stitching, 55)}
            : {stroke: stitching}
        }
        strokeWidth="0.424"
        strokeMiterlimit="10"
        strokeDasharray="1.271,1.271"
        d="M388.85,250.468
	c-0.21-0.038-0.419-0.077-0.628-0.116"
      />
      <path
        fill="none"
        stroke={stitching}
        onClick={() => handleClick("stitching")}
        onMouseEnter={() => setStitchingActive(true)}
        onMouseLeave={() => setStitchingActive(false)}
        style={
          stitchingActive && !preview
            ? {stroke: adjust(stitching, 55)}
            : {stroke: stitching}
        }
        strokeWidth="0.424"
        strokeMiterlimit="10"
        strokeDasharray="1.271,1.271"
        d="
	M474.334,274.544c0,0-0.216,0.033-0.63,0.093"
      />
      <line
        fill="none"
        stroke={stitching}
        onClick={() => handleClick("stitching")}
        onMouseEnter={() => setStitchingActive(true)}
        onMouseLeave={() => setStitchingActive(false)}
        style={
          stitchingActive && !preview
            ? {stroke: adjust(stitching, 55)}
            : {stroke: stitching}
        }
        strokeWidth="0.424"
        strokeMiterlimit="10"
        strokeDasharray="1.271,1.271"
        x1="472.765"
        y1="274.77"
        x2="472.764"
        y2="274.77"
      />
      <path
        fill="none"
        stroke={stitching}
        onClick={() => handleClick("stitching")}
        onMouseEnter={() => setStitchingActive(true)}
        onMouseLeave={() => setStitchingActive(false)}
        style={
          stitchingActive && !preview
            ? {stroke: adjust(stitching, 55)}
            : {stroke: stitching}
        }
        strokeWidth="0.424"
        strokeMiterlimit="10"
        strokeDasharray="1.271,1.271"
        d="M472.764,274.77
	c-8.443,1.166-49.349,6.217-83.913-0.109"
      />
      <path
        fill="none"
        stroke={stitching}
        onClick={() => handleClick("stitching")}
        onMouseEnter={() => setStitchingActive(true)}
        onMouseLeave={() => setStitchingActive(false)}
        style={
          stitchingActive && !preview
            ? {stroke: adjust(stitching, 55)}
            : {stroke: stitching}
        }
        strokeWidth="0.424"
        strokeMiterlimit="10"
        strokeDasharray="1.271,1.271"
        d="M388.85,274.66
	c-0.21-0.038-0.419-0.077-0.628-0.116"
      />
      <path
        fill="none"
        stroke={stitching}
        onClick={() => handleClick("stitching")}
        onMouseEnter={() => setStitchingActive(true)}
        onMouseLeave={() => setStitchingActive(false)}
        style={
          stitchingActive && !preview
            ? {stroke: adjust(stitching, 55)}
            : {stroke: stitching}
        }
        strokeWidth="0.424"
        strokeMiterlimit="10"
        strokeDasharray="1.271,1.271"
        d="
	M473.125,366.146c0,0-0.216,0.038-0.629,0.106"
      />
      <line
        fill="none"
        stroke={stitching}
        onClick={() => handleClick("stitching")}
        onMouseEnter={() => setStitchingActive(true)}
        onMouseLeave={() => setStitchingActive(false)}
        style={
          stitchingActive && !preview
            ? {stroke: adjust(stitching, 55)}
            : {stroke: stitching}
        }
        strokeWidth="0.424"
        strokeMiterlimit="10"
        strokeDasharray="1.271,1.271"
        x1="471.555"
        y1="366.403"
        x2="471.554"
        y2="366.403"
      />
      <path
        fill="none"
        stroke={stitching}
        onClick={() => handleClick("stitching")}
        onMouseEnter={() => setStitchingActive(true)}
        onMouseLeave={() => setStitchingActive(false)}
        style={
          stitchingActive && !preview
            ? {stroke: adjust(stitching, 55)}
            : {stroke: stitching}
        }
        strokeWidth="0.424"
        strokeMiterlimit="10"
        strokeDasharray="1.271,1.271"
        d="
	M471.554,366.403c-8.043,1.266-45.563,6.507-79.901,0.219"
      />
      <path
        fill="none"
        stroke={stitching}
        onClick={() => handleClick("stitching")}
        onMouseEnter={() => setStitchingActive(true)}
        onMouseLeave={() => setStitchingActive(false)}
        style={
          stitchingActive && !preview
            ? {stroke: adjust(stitching, 55)}
            : {stroke: stitching}
        }
        strokeWidth="0.424"
        strokeMiterlimit="10"
        strokeDasharray="1.271,1.271"
        d="
	M391.651,366.622c-0.209-0.038-0.418-0.077-0.627-0.116"
      />
      <path
        fill="none"
        stroke={stitching}
        onClick={() => handleClick("stitching")}
        onMouseEnter={() => setStitchingActive(true)}
        onMouseLeave={() => setStitchingActive(false)}
        style={
          stitchingActive && !preview
            ? {stroke: adjust(stitching, 55)}
            : {stroke: stitching}
        }
        strokeWidth="0.424"
        strokeMiterlimit="10"
        strokeDasharray="1.271,1.271"
        d="
	M473.125,364.346c0,0-0.216,0.038-0.629,0.106"
      />
      <line
        fill="none"
        stroke={stitching}
        onClick={() => handleClick("stitching")}
        onMouseEnter={() => setStitchingActive(true)}
        onMouseLeave={() => setStitchingActive(false)}
        style={
          stitchingActive && !preview
            ? {stroke: adjust(stitching, 55)}
            : {stroke: stitching}
        }
        strokeWidth="0.424"
        strokeMiterlimit="10"
        strokeDasharray="1.271,1.271"
        x1="471.555"
        y1="364.604"
        x2="471.554"
        y2="364.604"
      />
      <path
        fill="none"
        stroke={stitching}
        onClick={() => handleClick("stitching")}
        onMouseEnter={() => setStitchingActive(true)}
        onMouseLeave={() => setStitchingActive(false)}
        style={
          stitchingActive && !preview
            ? {stroke: adjust(stitching, 55)}
            : {stroke: stitching}
        }
        strokeWidth="0.424"
        strokeMiterlimit="10"
        strokeDasharray="1.271,1.271"
        d="
	M471.554,364.604c-8.043,1.265-45.563,6.506-79.901,0.219"
      />
      <path
        fill="none"
        stroke={stitching}
        onClick={() => handleClick("stitching")}
        onMouseEnter={() => setStitchingActive(true)}
        onMouseLeave={() => setStitchingActive(false)}
        style={
          stitchingActive && !preview
            ? {stroke: adjust(stitching, 55)}
            : {stroke: stitching}
        }
        strokeWidth="0.424"
        strokeMiterlimit="10"
        strokeDasharray="1.271,1.271"
        d="
	M391.651,364.821c-0.209-0.038-0.418-0.076-0.627-0.116"
      />
      <path
        fill="none"
        stroke={stitching}
        onClick={() => handleClick("stitching")}
        onMouseEnter={() => setStitchingActive(true)}
        onMouseLeave={() => setStitchingActive(false)}
        style={
          stitchingActive && !preview
            ? {stroke: adjust(stitching, 55)}
            : {stroke: stitching}
        }
        strokeWidth="0.424"
        strokeMiterlimit="10"
        strokeDasharray="1.271,1.271"
        d="
	M272.688,248.732c0,0,0.216,0.033,0.631,0.093"
      />
      <line
        fill="none"
        stroke={stitching}
        onClick={() => handleClick("stitching")}
        onMouseEnter={() => setStitchingActive(true)}
        onMouseLeave={() => setStitchingActive(false)}
        style={
          stitchingActive && !preview
            ? {stroke: adjust(stitching, 55)}
            : {stroke: stitching}
        }
        strokeWidth="0.424"
        strokeMiterlimit="10"
        strokeDasharray="1.271,1.271"
        x1="274.258"
        y1="248.958"
        x2="274.258"
        y2="248.958"
      />
      <path
        fill="none"
        stroke={stitching}
        onClick={() => handleClick("stitching")}
        onMouseEnter={() => setStitchingActive(true)}
        onMouseLeave={() => setStitchingActive(false)}
        style={
          stitchingActive && !preview
            ? {stroke: adjust(stitching, 55)}
            : {stroke: stitching}
        }
        strokeWidth="0.424"
        strokeMiterlimit="10"
        strokeDasharray="1.271,1.271"
        d="
	M274.258,248.958c8.444,1.167,49.35,6.216,83.914-0.109"
      />
      <path
        fill="none"
        stroke={stitching}
        onClick={() => handleClick("stitching")}
        onMouseEnter={() => setStitchingActive(true)}
        onMouseLeave={() => setStitchingActive(false)}
        style={
          stitchingActive && !preview
            ? {stroke: adjust(stitching, 55)}
            : {stroke: stitching}
        }
        strokeWidth="0.424"
        strokeMiterlimit="10"
        strokeDasharray="1.271,1.271"
        d="
	M358.173,248.848c0.209-0.039,0.418-0.077,0.627-0.116"
      />
      <path
        fill="none"
        stroke={stitching}
        onClick={() => handleClick("stitching")}
        onMouseEnter={() => setStitchingActive(true)}
        onMouseLeave={() => setStitchingActive(false)}
        style={
          stitchingActive && !preview
            ? {stroke: adjust(stitching, 55)}
            : {stroke: stitching}
        }
        strokeWidth="0.424"
        strokeMiterlimit="10"
        strokeDasharray="1.271,1.271"
        d="
	M272.688,250.352c0,0,0.216,0.034,0.631,0.093"
      />
      <line
        fill="none"
        stroke={stitching}
        onClick={() => handleClick("stitching")}
        onMouseEnter={() => setStitchingActive(true)}
        onMouseLeave={() => setStitchingActive(false)}
        style={
          stitchingActive && !preview
            ? {stroke: adjust(stitching, 55)}
            : {stroke: stitching}
        }
        strokeWidth="0.424"
        strokeMiterlimit="10"
        strokeDasharray="1.271,1.271"
        x1="274.258"
        y1="250.578"
        x2="274.258"
        y2="250.578"
      />
      <path
        fill="none"
        stroke={stitching}
        onClick={() => handleClick("stitching")}
        onMouseEnter={() => setStitchingActive(true)}
        onMouseLeave={() => setStitchingActive(false)}
        style={
          stitchingActive && !preview
            ? {stroke: adjust(stitching, 55)}
            : {stroke: stitching}
        }
        strokeWidth="0.424"
        strokeMiterlimit="10"
        strokeDasharray="1.271,1.271"
        d="
	M274.258,250.578c8.444,1.166,49.35,6.216,83.914-0.109"
      />
      <path
        fill="none"
        stroke={stitching}
        onClick={() => handleClick("stitching")}
        onMouseEnter={() => setStitchingActive(true)}
        onMouseLeave={() => setStitchingActive(false)}
        style={
          stitchingActive && !preview
            ? {stroke: adjust(stitching, 55)}
            : {stroke: stitching}
        }
        strokeWidth="0.424"
        strokeMiterlimit="10"
        strokeDasharray="1.271,1.271"
        d="
	M358.173,250.468c0.209-0.038,0.418-0.077,0.627-0.116"
      />
      <path
        fill="none"
        stroke={stitching}
        onClick={() => handleClick("stitching")}
        onMouseEnter={() => setStitchingActive(true)}
        onMouseLeave={() => setStitchingActive(false)}
        style={
          stitchingActive && !preview
            ? {stroke: adjust(stitching, 55)}
            : {stroke: stitching}
        }
        strokeWidth="0.424"
        strokeMiterlimit="10"
        strokeDasharray="1.271,1.271"
        d="
	M272.688,274.544c0,0,0.216,0.033,0.631,0.093"
      />
      <line
        fill="none"
        stroke={stitching}
        onClick={() => handleClick("stitching")}
        onMouseEnter={() => setStitchingActive(true)}
        onMouseLeave={() => setStitchingActive(false)}
        style={
          stitchingActive && !preview
            ? {stroke: adjust(stitching, 55)}
            : {stroke: stitching}
        }
        strokeWidth="0.424"
        strokeMiterlimit="10"
        strokeDasharray="1.271,1.271"
        x1="274.258"
        y1="274.77"
        x2="274.258"
        y2="274.77"
      />
      <path
        fill="none"
        stroke={stitching}
        onClick={() => handleClick("stitching")}
        onMouseEnter={() => setStitchingActive(true)}
        onMouseLeave={() => setStitchingActive(false)}
        style={
          stitchingActive && !preview
            ? {stroke: adjust(stitching, 55)}
            : {stroke: stitching}
        }
        strokeWidth="0.424"
        strokeMiterlimit="10"
        strokeDasharray="1.271,1.271"
        d="M274.258,274.77
	c8.444,1.166,49.35,6.217,83.914-0.109"
      />
      <path
        fill="none"
        stroke={stitching}
        onClick={() => handleClick("stitching")}
        onMouseEnter={() => setStitchingActive(true)}
        onMouseLeave={() => setStitchingActive(false)}
        style={
          stitchingActive && !preview
            ? {stroke: adjust(stitching, 55)}
            : {stroke: stitching}
        }
        strokeWidth="0.424"
        strokeMiterlimit="10"
        strokeDasharray="1.271,1.271"
        d="M358.173,274.66
	c0.209-0.038,0.418-0.077,0.627-0.116"
      />
      <path
        fill="none"
        stroke={stitching}
        onClick={() => handleClick("stitching")}
        onMouseEnter={() => setStitchingActive(true)}
        onMouseLeave={() => setStitchingActive(false)}
        style={
          stitchingActive && !preview
            ? {stroke: adjust(stitching, 55)}
            : {stroke: stitching}
        }
        strokeWidth="0.319"
        strokeMiterlimit="10"
        strokeDasharray="1.276,0.957"
        d="
	M276.095,476.423c0,0,32.87,9.694,74.448,0.27"
      />
      <path
        fill="none"
        stroke={stitching}
        onClick={() => handleClick("stitching")}
        onMouseEnter={() => setStitchingActive(true)}
        onMouseLeave={() => setStitchingActive(false)}
        style={
          stitchingActive && !preview
            ? {stroke: adjust(stitching, 55)}
            : {stroke: stitching}
        }
        strokeWidth="0.319"
        strokeMiterlimit="10"
        strokeDasharray="1.276,0.957"
        d="
	M470.901,476.423c0,0-33.05,9.694-74.628,0.27"
      />
      <line
        fill="none"
        stroke={stitching}
        onClick={() => handleClick("stitching")}
        onMouseEnter={() => setStitchingActive(true)}
        onMouseLeave={() => setStitchingActive(false)}
        style={
          stitchingActive && !preview
            ? {stroke: adjust(stitching, 55)}
            : {stroke: stitching}
        }
        strokeWidth="0.319"
        strokeMiterlimit="10"
        strokeDasharray="0.957,0.957"
        x1="340.795"
        y1="24.243"
        x2="372.862"
        y2="23.53"
      />
      <path
        fill="none"
        stroke={stitching}
        onClick={() => handleClick("stitching")}
        onMouseEnter={() => setStitchingActive(true)}
        onMouseLeave={() => setStitchingActive(false)}
        style={
          stitchingActive && !preview
            ? {stroke: adjust(stitching, 55)}
            : {stroke: stitching}
        }
        strokeWidth="0.319"
        strokeMiterlimit="10"
        strokeDasharray="0.957,0.957"
        d="M287.978,20.552
	c3.24,3.601,9.36,4.196,9.594,4.226l0.094,0.013l25.526-0.148"
      />
      <path
        fill="none"
        stroke={stitching}
        onClick={() => handleClick("stitching")}
        onMouseEnter={() => setStitchingActive(true)}
        onMouseLeave={() => setStitchingActive(false)}
        style={
          stitchingActive && !preview
            ? {stroke: adjust(stitching, 55)}
            : {stroke: stitching}
        }
        strokeWidth="0.319"
        strokeMiterlimit="10"
        strokeDasharray="0.957,0.957"
        d="M322.89,42.026
	c-16.51-0.316-33.442-0.856-36.173-0.949"
      />
      <path
        fill="none"
        stroke={stitching}
        onClick={() => handleClick("stitching")}
        onMouseEnter={() => setStitchingActive(true)}
        onMouseLeave={() => setStitchingActive(false)}
        style={
          stitchingActive && !preview
            ? {stroke: adjust(stitching, 55)}
            : {stroke: stitching}
        }
        strokeWidth="0.319"
        strokeMiterlimit="10"
        strokeDasharray="0.957,0.957"
        d="M372.019,42.545
	c-9.788-0.051-18.905-0.113-31.649-0.326"
      />
      <line
        fill="none"
        stroke={stitching}
        onClick={() => handleClick("stitching")}
        onMouseEnter={() => setStitchingActive(true)}
        onMouseLeave={() => setStitchingActive(false)}
        style={
          stitchingActive && !preview
            ? {stroke: adjust(stitching, 55)}
            : {stroke: stitching}
        }
        strokeWidth="0.319"
        strokeMiterlimit="10"
        strokeDasharray="0.957,0.957"
        x1="406.367"
        y1="24.172"
        x2="374.831"
        y2="23.53"
      />
      <path
        fill="none"
        stroke={stitching}
        onClick={() => handleClick("stitching")}
        onMouseEnter={() => setStitchingActive(true)}
        onMouseLeave={() => setStitchingActive(false)}
        style={
          stitchingActive && !preview
            ? {stroke: adjust(stitching, 55)}
            : {stroke: stitching}
        }
        strokeWidth="0.319"
        strokeMiterlimit="10"
        strokeDasharray="0.957,0.957"
        d="M458.478,20.634
	c-3.24,3.601-9.36,4.196-9.594,4.226l-0.094,0.013l-24.506-0.498"
      />
      <path
        fill="none"
        stroke={stitching}
        onClick={() => handleClick("stitching")}
        onMouseEnter={() => setStitchingActive(true)}
        onMouseLeave={() => setStitchingActive(false)}
        style={
          stitchingActive && !preview
            ? {stroke: adjust(stitching, 55)}
            : {stroke: stitching}
        }
        strokeWidth="0.319"
        strokeMiterlimit="10"
        strokeDasharray="0.957,0.957"
        d="M424.284,41.722
	c16.176-0.292,32.754-0.471,35.455-0.563"
      />
      <path
        fill="none"
        stroke={stitching}
        onClick={() => handleClick("stitching")}
        onMouseEnter={() => setStitchingActive(true)}
        onMouseLeave={() => setStitchingActive(false)}
        style={
          stitchingActive && !preview
            ? {stroke: adjust(stitching, 55)}
            : {stroke: stitching}
        }
        strokeWidth="0.319"
        strokeMiterlimit="10"
        strokeDasharray="0.957,0.957"
        d="M374.831,42.545
	c9.92-0.052,19.088-0.22,31.937-0.423"
      />
      <path
        fill="none"
        stroke={stitching}
        onClick={() => handleClick("stitching")}
        onMouseEnter={() => setStitchingActive(true)}
        onMouseLeave={() => setStitchingActive(false)}
        style={
          stitchingActive && !preview
            ? {stroke: adjust(stitching, 55)}
            : {stroke: stitching}
        }
        strokeWidth="0.319"
        strokeMiterlimit="10"
        d="M373.497,129.21
	c0.148-0.046,0.301-0.093,0.456-0.143"
      />
      <line
        fill="none"
        stroke={stitching}
        onClick={() => handleClick("stitching")}
        onMouseEnter={() => setStitchingActive(true)}
        onMouseLeave={() => setStitchingActive(false)}
        style={
          stitchingActive && !preview
            ? {stroke: adjust(stitching, 55)}
            : {stroke: stitching}
        }
        strokeWidth="0.319"
        strokeMiterlimit="10"
        x1="374.858"
        y1="128.769"
        x2="374.859"
        y2="128.769"
      />
      <path
        fill="none"
        stroke={stitching}
        onClick={() => handleClick("stitching")}
        onMouseEnter={() => setStitchingActive(true)}
        onMouseLeave={() => setStitchingActive(false)}
        style={
          stitchingActive && !preview
            ? {stroke: adjust(stitching, 55)}
            : {stroke: stitching}
        }
        strokeWidth="0.319"
        strokeMiterlimit="10"
        strokeDasharray="0.954,0.953"
        d="
	M374.859,128.769c3.33-1.129,7.764-3.013,10.432-5.682c1.771-1.771,2.65-4.154,2.987-6.597c0.342-2.472,0.05-4.909,0.086-7.382
	c0.027-1.809,0.009-3.62,0.014-5.43l0.02-8.639c0.009-3.352,0.017-6.703,0.024-10.054c0.009-3.529,0.018-7.057,0.026-10.586
	c0.009-3.413,0.016-6.826,0.025-10.239c0.007-3.004,0.015-6.008,0.022-9.012c0.006-2.301,0.011-4.603,0.016-6.904
	c0.003-1.306,0.007-2.612,0.01-3.917v-0.085"
      />
      <path
        fill="none"
        stroke={stitching}
        onClick={() => handleClick("stitching")}
        onMouseEnter={() => setStitchingActive(true)}
        onMouseLeave={() => setStitchingActive(false)}
        style={
          stitchingActive && !preview
            ? {stroke: adjust(stitching, 55)}
            : {stroke: stitching}
        }
        strokeWidth="0.319"
        strokeMiterlimit="10"
        d="M388.522,44.24
	c0.001-0.159,0.001-0.319,0.001-0.478"
      />
      <line
        fill="none"
        stroke={stitching}
        onClick={() => handleClick("stitching")}
        onMouseEnter={() => setStitchingActive(true)}
        onMouseLeave={() => setStitchingActive(false)}
        style={
          stitchingActive && !preview
            ? {stroke: adjust(stitching, 55)}
            : {stroke: stitching}
        }
        strokeWidth="0.319"
        strokeMiterlimit="10"
        x1="375.016"
        y1="44.198"
        x2="375.015"
        y2="44.677"
      />
      <line
        fill="none"
        stroke={stitching}
        onClick={() => handleClick("stitching")}
        onMouseEnter={() => setStitchingActive(true)}
        onMouseLeave={() => setStitchingActive(false)}
        style={
          stitchingActive && !preview
            ? {stroke: adjust(stitching, 55)}
            : {stroke: stitching}
        }
        strokeWidth="0.319"
        strokeMiterlimit="10"
        x1="375.014"
        y1="45.624"
        x2="375.014"
        y2="45.625"
      />
      <polyline
        fill="none"
        stroke={stitching}
        onClick={() => handleClick("stitching")}
        onMouseEnter={() => setStitchingActive(true)}
        onMouseLeave={() => setStitchingActive(false)}
        style={
          stitchingActive && !preview
            ? {stroke: adjust(stitching, 55)}
            : {stroke: stitching}
        }
        strokeWidth="0.319"
        strokeMiterlimit="10"
        strokeDasharray="0.948,0.947"
        points="
	375.014,45.625 374.939,128.669 374.939,148.04 "
      />
      <line
        fill="none"
        stroke={stitching}
        onClick={() => handleClick("stitching")}
        onMouseEnter={() => setStitchingActive(true)}
        onMouseLeave={() => setStitchingActive(false)}
        style={
          stitchingActive && !preview
            ? {stroke: adjust(stitching, 55)}
            : {stroke: stitching}
        }
        strokeWidth="0.319"
        strokeMiterlimit="10"
        x1="374.939"
        y1="148.039"
        x2="374.939"
        y2="148.517"
      />
      <path
        fill="none"
        stroke={stitching}
        onClick={() => handleClick("stitching")}
        onMouseEnter={() => setStitchingActive(true)}
        onMouseLeave={() => setStitchingActive(false)}
        style={
          stitchingActive && !preview
            ? {stroke: adjust(stitching, 55)}
            : {stroke: stitching}
        }
        strokeWidth="0.319"
        strokeMiterlimit="10"
        strokeDasharray="1.276,0.957"
        d="M289.161,19.805
	c1.202-0.247,2.358-0.479,3.361-0.679c2.566-0.511,4.572-1.014,6.512-1.498c2.324-0.58,4.52-1.129,7.535-1.671
	c8.61-1.545,16.472-2.837,24.034-3.948c9.824-1.442,17.827-2.398,25.19-3.009c8.459-0.7,17.35-0.885,26.431-0.55
	c8.815,0.325,17.393,1.321,24.623,2.252c7.869,1.012,16.304,2.29,25.791,3.905c3.826,0.652,6.758,1.323,9.864,2.034
	c2.366,0.54,4.811,1.101,7.76,1.671c2.231,0.434,4.839,0.944,7.513,1.496"
      />
      <path
        fill="none"
        stroke={stitching}
        onClick={() => handleClick("stitching")}
        onMouseEnter={() => setStitchingActive(true)}
        onMouseLeave={() => setStitchingActive(false)}
        style={
          stitchingActive && !preview
            ? {stroke: adjust(stitching, 55)}
            : {stroke: stitching}
        }
        strokeWidth="0.319"
        strokeMiterlimit="10"
        strokeDasharray="0.957,0.957"
        d="
	M380.133,181.591c0.428-9.348,1.027-23.715,0.779-26.022c-0.373-3.465-5.068-5.679-7.257-5.026h-0.094
	c-2.189-0.653-6.884,1.561-7.256,5.026c-0.245,2.276,0.335,16.294,0.762,25.647"
      />
      <path
        fill="none"
        stroke={stitching}
        onClick={() => handleClick("stitching")}
        onMouseEnter={() => setStitchingActive(true)}
        onMouseLeave={() => setStitchingActive(false)}
        style={
          stitchingActive && !preview
            ? {stroke: adjust(stitching, 55)}
            : {stroke: stitching}
        }
        strokeWidth="0.319"
        strokeMiterlimit="10"
        strokeDasharray="0.957,0.957"
        d="
	M368.343,171.809v-12.963c0-1.667,0.621-3.29,1.797-4.471c0.808-0.812,1.824-1.251,3.293-1.267"
      />
      <path
        fill="none"
        stroke={stitching}
        onClick={() => handleClick("stitching")}
        onMouseEnter={() => setStitchingActive(true)}
        onMouseLeave={() => setStitchingActive(false)}
        style={
          stitchingActive && !preview
            ? {stroke: adjust(stitching, 55)}
            : {stroke: stitching}
        }
        strokeWidth="0.319"
        strokeMiterlimit="10"
        strokeDasharray="0.957,0.957"
        d="
	M378.834,171.923l-0.181-13.144c0-1.666-0.62-3.29-1.796-4.47c-0.809-0.812-1.825-1.251-3.294-1.267"
      />
      <path
        fill="none"
        stroke={stitching}
        onClick={() => handleClick("stitching")}
        onMouseEnter={() => setStitchingActive(true)}
        onMouseLeave={() => setStitchingActive(false)}
        style={
          stitchingActive && !preview
            ? {stroke: adjust(stitching, 55)}
            : {stroke: stitching}
        }
        strokeWidth="0.319"
        strokeMiterlimit="10"
        strokeDasharray="1.276,0.957"
        d="
	M472.066,145.026l-40.398,0.439l0.216,24.823c0.005,0.549,0.304,1.04,0.756,1.239l4.996,2.207c0.882,0.39,1.822,0.585,2.769,0.575
	l32.286-0.35"
      />
      <path
        fill="none"
        stroke={stitching}
        onClick={() => handleClick("stitching")}
        onMouseEnter={() => setStitchingActive(true)}
        onMouseLeave={() => setStitchingActive(false)}
        style={
          stitchingActive && !preview
            ? {stroke: adjust(stitching, 55)}
            : {stroke: stitching}
        }
        strokeWidth="0.319"
        strokeMiterlimit="10"
        strokeDasharray="1.276,0.957"
        d="
	M472.097,147.839l-37.591,0.408l0.184,21.11l4.084,1.805c0.503,0.222,1.027,0.334,1.56,0.334h0.041l32.286-0.35"
      />
      <line
        fill="none"
        stroke={stitching}
        onClick={() => handleClick("stitching")}
        onMouseEnter={() => setStitchingActive(true)}
        onMouseLeave={() => setStitchingActive(false)}
        style={
          stitchingActive && !preview
            ? {stroke: adjust(stitching, 55)}
            : {stroke: stitching}
        }
        strokeWidth="0.309"
        strokeMiterlimit="10"
        strokeDasharray="1.238,0.928"
        x1="441.013"
        y1="188.826"
        x2="441.421"
        y2="238.6"
      />
      <line
        fill="none"
        stroke={stitching}
        onClick={() => handleClick("stitching")}
        onMouseEnter={() => setStitchingActive(true)}
        onMouseLeave={() => setStitchingActive(false)}
        style={
          stitchingActive && !preview
            ? {stroke: adjust(stitching, 55)}
            : {stroke: stitching}
        }
        strokeWidth="0.309"
        strokeMiterlimit="10"
        strokeDasharray="1.238,0.928"
        x1="431.817"
        y1="197.038"
        x2="450.667"
        y2="196.834"
      />
      <polyline
        fill="none"
        stroke={stitching}
        onClick={() => handleClick("stitching")}
        onMouseEnter={() => setStitchingActive(true)}
        onMouseLeave={() => setStitchingActive(false)}
        style={
          stitchingActive && !preview
            ? {stroke: adjust(stitching, 55)}
            : {stroke: stitching}
        }
        strokeWidth="0.233"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        points="
	449.677,195.95 450.572,194.864 449.679,195.042 450.573,193.958 449.682,194.135 450.575,193.051 449.684,193.228 450.577,192.145 
	449.686,192.322 450.58,191.24 449.688,191.415 450.582,190.334 449.691,190.509 450.584,189.429 "
      />
      <polyline
        fill="none"
        stroke={stitching}
        onClick={() => handleClick("stitching")}
        onMouseEnter={() => setStitchingActive(true)}
        onMouseLeave={() => setStitchingActive(false)}
        style={
          stitchingActive && !preview
            ? {stroke: adjust(stitching, 55)}
            : {stroke: stitching}
        }
        strokeWidth="0.233"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        points="
	440.55,196.051 441.445,194.965 440.553,195.143 441.447,194.058 440.555,194.236 441.449,193.151 440.557,193.328 441.451,192.245 
	440.559,192.421 441.453,191.339 440.562,191.514 441.455,190.433 440.564,190.608 441.457,189.527 "
      />
      <polyline
        fill="none"
        stroke={stitching}
        onClick={() => handleClick("stitching")}
        onMouseEnter={() => setStitchingActive(true)}
        onMouseLeave={() => setStitchingActive(false)}
        style={
          stitchingActive && !preview
            ? {stroke: adjust(stitching, 55)}
            : {stroke: stitching}
        }
        strokeWidth="0.233"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        points="
	432.081,196.14 432.976,195.054 432.083,195.233 432.977,194.148 432.085,194.325 432.979,193.241 432.087,193.418 432.981,192.335 
	432.09,192.512 432.983,191.43 432.092,191.605 432.985,190.524 432.094,190.699 432.987,189.619 "
      />
      <line
        fill="none"
        stroke={stitching}
        onClick={() => handleClick("stitching")}
        onMouseEnter={() => setStitchingActive(true)}
        onMouseLeave={() => setStitchingActive(false)}
        style={
          stitchingActive && !preview
            ? {stroke: adjust(stitching, 55)}
            : {stroke: stitching}
        }
        strokeWidth="0.319"
        strokeMiterlimit="10"
        x1="325.134"
        y1="23.295"
        x2="325.126"
        y2="23.773"
      />
      <line
        fill="none"
        stroke={stitching}
        onClick={() => handleClick("stitching")}
        onMouseEnter={() => setStitchingActive(true)}
        onMouseLeave={() => setStitchingActive(false)}
        style={
          stitchingActive && !preview
            ? {stroke: adjust(stitching, 55)}
            : {stroke: stitching}
        }
        strokeWidth="0.319"
        strokeMiterlimit="10"
        x1="325.111"
        y1="24.735"
        x2="325.111"
        y2="24.736"
      />
      <line
        fill="none"
        stroke={stitching}
        onClick={() => handleClick("stitching")}
        onMouseEnter={() => setStitchingActive(true)}
        onMouseLeave={() => setStitchingActive(false)}
        style={
          stitchingActive && !preview
            ? {stroke: adjust(stitching, 55)}
            : {stroke: stitching}
        }
        strokeWidth="0.319"
        strokeMiterlimit="10"
        strokeDasharray="0.963,0.962"
        x1="325.111"
        y1="24.736"
        x2="324.564"
        y2="56.239"
      />
      <line
        fill="none"
        stroke={stitching}
        onClick={() => handleClick("stitching")}
        onMouseEnter={() => setStitchingActive(true)}
        onMouseLeave={() => setStitchingActive(false)}
        style={
          stitchingActive && !preview
            ? {stroke: adjust(stitching, 55)}
            : {stroke: stitching}
        }
        strokeWidth="0.319"
        strokeMiterlimit="10"
        x1="324.565"
        y1="56.239"
        x2="324.556"
        y2="56.718"
      />
      <line
        fill="none"
        stroke={stitching}
        onClick={() => handleClick("stitching")}
        onMouseEnter={() => setStitchingActive(true)}
        onMouseLeave={() => setStitchingActive(false)}
        style={
          stitchingActive && !preview
            ? {stroke: adjust(stitching, 55)}
            : {stroke: stitching}
        }
        strokeWidth="0.319"
        strokeMiterlimit="10"
        x1="331.885"
        y1="23.183"
        x2="331.877"
        y2="23.662"
      />
      <line
        fill="none"
        stroke={stitching}
        onClick={() => handleClick("stitching")}
        onMouseEnter={() => setStitchingActive(true)}
        onMouseLeave={() => setStitchingActive(false)}
        style={
          stitchingActive && !preview
            ? {stroke: adjust(stitching, 55)}
            : {stroke: stitching}
        }
        strokeWidth="0.319"
        strokeMiterlimit="10"
        x1="331.862"
        y1="24.623"
        x2="331.862"
        y2="24.624"
      />
      <line
        fill="none"
        stroke={stitching}
        onClick={() => handleClick("stitching")}
        onMouseEnter={() => setStitchingActive(true)}
        onMouseLeave={() => setStitchingActive(false)}
        style={
          stitchingActive && !preview
            ? {stroke: adjust(stitching, 55)}
            : {stroke: stitching}
        }
        strokeWidth="0.319"
        strokeMiterlimit="10"
        strokeDasharray="0.963,0.962"
        x1="331.862"
        y1="24.624"
        x2="331.315"
        y2="56.127"
      />
      <line
        fill="none"
        stroke={stitching}
        onClick={() => handleClick("stitching")}
        onMouseEnter={() => setStitchingActive(true)}
        onMouseLeave={() => setStitchingActive(false)}
        style={
          stitchingActive && !preview
            ? {stroke: adjust(stitching, 55)}
            : {stroke: stitching}
        }
        strokeWidth="0.319"
        strokeMiterlimit="10"
        x1="331.315"
        y1="56.127"
        x2="331.307"
        y2="56.606"
      />
      <line
        fill="none"
        stroke={stitching}
        onClick={() => handleClick("stitching")}
        onMouseEnter={() => setStitchingActive(true)}
        onMouseLeave={() => setStitchingActive(false)}
        style={
          stitchingActive && !preview
            ? {stroke: adjust(stitching, 55)}
            : {stroke: stitching}
        }
        strokeWidth="0.319"
        strokeMiterlimit="10"
        x1="338.839"
        y1="23.183"
        x2="338.831"
        y2="23.662"
      />
      <line
        fill="none"
        stroke={stitching}
        onClick={() => handleClick("stitching")}
        onMouseEnter={() => setStitchingActive(true)}
        onMouseLeave={() => setStitchingActive(false)}
        style={
          stitchingActive && !preview
            ? {stroke: adjust(stitching, 55)}
            : {stroke: stitching}
        }
        strokeWidth="0.319"
        strokeMiterlimit="10"
        x1="338.816"
        y1="24.623"
        x2="338.816"
        y2="24.624"
      />
      <line
        fill="none"
        stroke={stitching}
        onClick={() => handleClick("stitching")}
        onMouseEnter={() => setStitchingActive(true)}
        onMouseLeave={() => setStitchingActive(false)}
        style={
          stitchingActive && !preview
            ? {stroke: adjust(stitching, 55)}
            : {stroke: stitching}
        }
        strokeWidth="0.319"
        strokeMiterlimit="10"
        strokeDasharray="0.963,0.962"
        x1="338.816"
        y1="24.624"
        x2="338.269"
        y2="56.127"
      />
      <line
        fill="none"
        stroke={stitching}
        onClick={() => handleClick("stitching")}
        onMouseEnter={() => setStitchingActive(true)}
        onMouseLeave={() => setStitchingActive(false)}
        style={
          stitchingActive && !preview
            ? {stroke: adjust(stitching, 55)}
            : {stroke: stitching}
        }
        strokeWidth="0.319"
        strokeMiterlimit="10"
        x1="338.27"
        y1="56.127"
        x2="338.261"
        y2="56.606"
      />
      <polyline
        fill="none"
        stroke={stitching}
        onClick={() => handleClick("stitching")}
        onMouseEnter={() => setStitchingActive(true)}
        onMouseLeave={() => setStitchingActive(false)}
        style={
          stitchingActive && !preview
            ? {stroke: adjust(stitching, 55)}
            : {stroke: stitching}
        }
        strokeWidth="0.319"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        points="
	330.449,54.428 329.725,54.943 329.75,54.43 329.026,54.945 329.05,54.432 328.327,54.947 328.35,54.434 327.629,54.949 
	327.652,54.436 326.93,54.95 326.953,54.438 326.232,54.952 326.255,54.441 325.535,54.954 "
      />
      <polyline
        fill="none"
        stroke={stitching}
        onClick={() => handleClick("stitching")}
        onMouseEnter={() => setStitchingActive(true)}
        onMouseLeave={() => setStitchingActive(false)}
        style={
          stitchingActive && !preview
            ? {stroke: adjust(stitching, 55)}
            : {stroke: stitching}
        }
        strokeWidth="0.319"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        points="
	330.561,52.856 329.805,53.37 329.83,52.857 329.074,53.371 329.098,52.858 328.343,53.372 328.367,52.859 327.613,53.372 
	327.635,52.86 326.882,53.373 326.905,52.861 326.152,53.374 326.174,52.862 325.422,53.375 "
      />
      <polyline
        fill="none"
        stroke={stitching}
        onClick={() => handleClick("stitching")}
        onMouseEnter={() => setStitchingActive(true)}
        onMouseLeave={() => setStitchingActive(false)}
        style={
          stitchingActive && !preview
            ? {stroke: adjust(stitching, 55)}
            : {stroke: stitching}
        }
        strokeWidth="0.319"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        points="
	330.66,24.258 329.996,24.809 329.993,24.297 329.329,24.848 329.326,24.335 328.663,24.886 328.659,24.373 327.996,24.924 
	327.992,24.412 327.33,24.962 327.325,24.45 326.664,25.001 326.659,24.488 325.999,25.039 "
      />
      <polyline
        fill="none"
        stroke={stitching}
        onClick={() => handleClick("stitching")}
        onMouseEnter={() => setStitchingActive(true)}
        onMouseLeave={() => setStitchingActive(false)}
        style={
          stitchingActive && !preview
            ? {stroke: adjust(stitching, 55)}
            : {stroke: stitching}
        }
        strokeWidth="0.319"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        points="
	337.426,54.428 336.701,54.943 336.726,54.43 336.003,54.945 336.027,54.432 335.304,54.947 335.327,54.434 334.606,54.949 
	334.628,54.436 333.907,54.95 333.93,54.438 333.209,54.952 333.231,54.441 332.511,54.954 "
      />
      <polyline
        fill="none"
        stroke={stitching}
        onClick={() => handleClick("stitching")}
        onMouseEnter={() => setStitchingActive(true)}
        onMouseLeave={() => setStitchingActive(false)}
        style={
          stitchingActive && !preview
            ? {stroke: adjust(stitching, 55)}
            : {stroke: stitching}
        }
        strokeWidth="0.319"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        points="
	337.426,52.853 336.701,53.368 336.726,52.855 336.003,53.37 336.027,52.857 335.304,53.372 335.327,52.859 334.606,53.373 
	334.628,52.861 333.907,53.375 333.93,52.863 333.209,53.377 333.231,52.866 332.511,53.379 "
      />
      <polyline
        fill="none"
        stroke={stitching}
        onClick={() => handleClick("stitching")}
        onMouseEnter={() => setStitchingActive(true)}
        onMouseLeave={() => setStitchingActive(false)}
        style={
          stitchingActive && !preview
            ? {stroke: adjust(stitching, 55)}
            : {stroke: stitching}
        }
        strokeWidth="0.319"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        points="
	337.858,24.012 337.166,24.57 337.159,24.056 336.467,24.614 336.46,24.101 335.769,24.658 335.761,24.145 335.071,24.702 
	335.062,24.19 334.373,24.746 334.364,24.234 333.675,24.791 333.666,24.279 332.978,24.835 "
      />
      <line
        fill="none"
        stroke={stitching}
        onClick={() => handleClick("stitching")}
        onMouseEnter={() => setStitchingActive(true)}
        onMouseLeave={() => setStitchingActive(false)}
        style={
          stitchingActive && !preview
            ? {stroke: adjust(stitching, 55)}
            : {stroke: stitching}
        }
        strokeWidth="0.319"
        strokeMiterlimit="10"
        x1="422.153"
        y1="23.295"
        x2="422.161"
        y2="23.773"
      />
      <line
        fill="none"
        stroke={stitching}
        onClick={() => handleClick("stitching")}
        onMouseEnter={() => setStitchingActive(true)}
        onMouseLeave={() => setStitchingActive(false)}
        style={
          stitchingActive && !preview
            ? {stroke: adjust(stitching, 55)}
            : {stroke: stitching}
        }
        strokeWidth="0.319"
        strokeMiterlimit="10"
        x1="422.177"
        y1="24.735"
        x2="422.177"
        y2="24.736"
      />
      <line
        fill="none"
        stroke={stitching}
        onClick={() => handleClick("stitching")}
        onMouseEnter={() => setStitchingActive(true)}
        onMouseLeave={() => setStitchingActive(false)}
        style={
          stitchingActive && !preview
            ? {stroke: adjust(stitching, 55)}
            : {stroke: stitching}
        }
        strokeWidth="0.319"
        strokeMiterlimit="10"
        strokeDasharray="0.963,0.962"
        x1="422.177"
        y1="24.736"
        x2="422.723"
        y2="56.464"
      />
      <line
        fill="none"
        stroke={stitching}
        onClick={() => handleClick("stitching")}
        onMouseEnter={() => setStitchingActive(true)}
        onMouseLeave={() => setStitchingActive(false)}
        style={
          stitchingActive && !preview
            ? {stroke: adjust(stitching, 55)}
            : {stroke: stitching}
        }
        strokeWidth="0.319"
        strokeMiterlimit="10"
        x1="422.723"
        y1="56.464"
        x2="422.731"
        y2="56.943"
      />
      <line
        fill="none"
        stroke={stitching}
        onClick={() => handleClick("stitching")}
        onMouseEnter={() => setStitchingActive(true)}
        onMouseLeave={() => setStitchingActive(false)}
        style={
          stitchingActive && !preview
            ? {stroke: adjust(stitching, 55)}
            : {stroke: stitching}
        }
        strokeWidth="0.319"
        strokeMiterlimit="10"
        x1="415.402"
        y1="23.183"
        x2="415.41"
        y2="23.662"
      />
      <line
        fill="none"
        stroke={stitching}
        onClick={() => handleClick("stitching")}
        onMouseEnter={() => setStitchingActive(true)}
        onMouseLeave={() => setStitchingActive(false)}
        style={
          stitchingActive && !preview
            ? {stroke: adjust(stitching, 55)}
            : {stroke: stitching}
        }
        strokeWidth="0.319"
        strokeMiterlimit="10"
        x1="415.425"
        y1="24.623"
        x2="415.425"
        y2="24.624"
      />
      <line
        fill="none"
        stroke={stitching}
        onClick={() => handleClick("stitching")}
        onMouseEnter={() => setStitchingActive(true)}
        onMouseLeave={() => setStitchingActive(false)}
        style={
          stitchingActive && !preview
            ? {stroke: adjust(stitching, 55)}
            : {stroke: stitching}
        }
        strokeWidth="0.319"
        strokeMiterlimit="10"
        strokeDasharray="0.963,0.962"
        x1="415.425"
        y1="24.624"
        x2="415.972"
        y2="56.353"
      />
      <line
        fill="none"
        stroke={stitching}
        onClick={() => handleClick("stitching")}
        onMouseEnter={() => setStitchingActive(true)}
        onMouseLeave={() => setStitchingActive(false)}
        style={
          stitchingActive && !preview
            ? {stroke: adjust(stitching, 55)}
            : {stroke: stitching}
        }
        strokeWidth="0.319"
        strokeMiterlimit="10"
        x1="415.972"
        y1="56.352"
        x2="415.98"
        y2="56.83"
      />
      <line
        fill="none"
        stroke={stitching}
        onClick={() => handleClick("stitching")}
        onMouseEnter={() => setStitchingActive(true)}
        onMouseLeave={() => setStitchingActive(false)}
        style={
          stitchingActive && !preview
            ? {stroke: adjust(stitching, 55)}
            : {stroke: stitching}
        }
        strokeWidth="0.319"
        strokeMiterlimit="10"
        x1="408.448"
        y1="23.183"
        x2="408.456"
        y2="23.662"
      />
      <line
        fill="none"
        stroke={stitching}
        onClick={() => handleClick("stitching")}
        onMouseEnter={() => setStitchingActive(true)}
        onMouseLeave={() => setStitchingActive(false)}
        style={
          stitchingActive && !preview
            ? {stroke: adjust(stitching, 55)}
            : {stroke: stitching}
        }
        strokeWidth="0.319"
        strokeMiterlimit="10"
        x1="408.472"
        y1="24.623"
        x2="408.472"
        y2="24.624"
      />
      <line
        fill="none"
        stroke={stitching}
        onClick={() => handleClick("stitching")}
        onMouseEnter={() => setStitchingActive(true)}
        onMouseLeave={() => setStitchingActive(false)}
        style={
          stitchingActive && !preview
            ? {stroke: adjust(stitching, 55)}
            : {stroke: stitching}
        }
        strokeWidth="0.319"
        strokeMiterlimit="10"
        strokeDasharray="0.963,0.962"
        x1="408.472"
        y1="24.624"
        x2="409.018"
        y2="56.353"
      />
      <line
        fill="none"
        stroke={stitching}
        onClick={() => handleClick("stitching")}
        onMouseEnter={() => setStitchingActive(true)}
        onMouseLeave={() => setStitchingActive(false)}
        style={
          stitchingActive && !preview
            ? {stroke: adjust(stitching, 55)}
            : {stroke: stitching}
        }
        strokeWidth="0.319"
        strokeMiterlimit="10"
        x1="409.018"
        y1="56.352"
        x2="409.026"
        y2="56.83"
      />
      <polyline
        fill="none"
        stroke={stitching}
        onClick={() => handleClick("stitching")}
        onMouseEnter={() => setStitchingActive(true)}
        onMouseLeave={() => setStitchingActive(false)}
        style={
          stitchingActive && !preview
            ? {stroke: adjust(stitching, 55)}
            : {stroke: stitching}
        }
        strokeWidth="0.319"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        points="
	416.838,54.653 417.563,55.168 417.538,54.655 418.261,55.17 418.237,54.657 418.96,55.172 418.937,54.659 419.658,55.173 
	419.636,54.661 420.357,55.175 420.334,54.663 421.055,55.177 421.033,54.666 421.752,55.179 "
      />
      <polyline
        fill="none"
        stroke={stitching}
        onClick={() => handleClick("stitching")}
        onMouseEnter={() => setStitchingActive(true)}
        onMouseLeave={() => setStitchingActive(false)}
        style={
          stitchingActive && !preview
            ? {stroke: adjust(stitching, 55)}
            : {stroke: stitching}
        }
        strokeWidth="0.319"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        points="
	416.726,53.082 417.483,53.596 417.457,53.083 418.213,53.597 418.19,53.084 418.945,53.597 418.92,53.085 419.675,53.598 
	419.652,53.086 420.406,53.599 420.382,53.087 421.135,53.6 421.114,53.088 421.866,53.601 "
      />
      <polyline
        fill="none"
        stroke={stitching}
        onClick={() => handleClick("stitching")}
        onMouseEnter={() => setStitchingActive(true)}
        onMouseLeave={() => setStitchingActive(false)}
        style={
          stitchingActive && !preview
            ? {stroke: adjust(stitching, 55)}
            : {stroke: stitching}
        }
        strokeWidth="0.319"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        points="
	416.627,24.258 417.291,24.809 417.294,24.297 417.958,24.848 417.962,24.335 418.624,24.886 418.629,24.373 419.291,24.924 
	419.296,24.412 419.957,24.962 419.962,24.45 420.623,25.001 420.628,24.488 421.289,25.039 "
      />
      <polyline
        fill="none"
        stroke={stitching}
        onClick={() => handleClick("stitching")}
        onMouseEnter={() => setStitchingActive(true)}
        onMouseLeave={() => setStitchingActive(false)}
        style={
          stitchingActive && !preview
            ? {stroke: adjust(stitching, 55)}
            : {stroke: stitching}
        }
        strokeWidth="0.319"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        points="
	409.862,54.653 410.586,55.168 410.561,54.655 411.285,55.17 411.26,54.657 411.983,55.172 411.96,54.659 412.681,55.173 
	412.659,54.661 413.381,55.175 413.358,54.663 414.078,55.177 414.056,54.666 414.776,55.179 "
      />
      <polyline
        fill="none"
        stroke={stitching}
        onClick={() => handleClick("stitching")}
        onMouseEnter={() => setStitchingActive(true)}
        onMouseLeave={() => setStitchingActive(false)}
        style={
          stitchingActive && !preview
            ? {stroke: adjust(stitching, 55)}
            : {stroke: stitching}
        }
        strokeWidth="0.319"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        points="
	409.862,53.078 410.586,53.593 410.561,53.08 411.285,53.594 411.26,53.082 411.983,53.596 411.96,53.084 412.681,53.598 
	412.659,53.086 413.381,53.6 413.358,53.088 414.078,53.602 414.056,53.09 414.776,53.604 "
      />
      <polyline
        fill="none"
        stroke={stitching}
        onClick={() => handleClick("stitching")}
        onMouseEnter={() => setStitchingActive(true)}
        onMouseLeave={() => setStitchingActive(false)}
        style={
          stitchingActive && !preview
            ? {stroke: adjust(stitching, 55)}
            : {stroke: stitching}
        }
        strokeWidth="0.319"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        points="
	409.429,24.012 410.121,24.57 410.128,24.056 410.82,24.614 410.827,24.101 411.519,24.658 411.527,24.145 412.216,24.702 
	412.225,24.19 412.914,24.746 412.923,24.234 413.613,24.791 413.621,24.279 414.31,24.835 "
      />
      <path
        fill="none"
        stroke={stitching}
        onClick={() => handleClick("stitching")}
        onMouseEnter={() => setStitchingActive(true)}
        onMouseLeave={() => setStitchingActive(false)}
        style={
          stitchingActive && !preview
            ? {stroke: adjust(stitching, 55)}
            : {stroke: stitching}
        }
        strokeWidth="0.319"
        strokeMiterlimit="10"
        strokeDasharray="0.963,0.962"
        d="M320.484,44.121
	c0,0,1.5,34.806-0.206,39.176c-0.023-0.023-12.722,2.424-40.525,2.118"
      />
      <path
        fill="none"
        stroke={stitching}
        onClick={() => handleClick("stitching")}
        onMouseEnter={() => setStitchingActive(true)}
        onMouseLeave={() => setStitchingActive(false)}
        style={
          stitchingActive && !preview
            ? {stroke: adjust(stitching, 55)}
            : {stroke: stitching}
        }
        strokeWidth="0.319"
        strokeMiterlimit="10"
        strokeDasharray="0.963,0.962"
        d="M316.378,43.344
	c0,0-3.563,31.965-5.552,33.247c-1.99,1.281-31.073,3.873-31.073,3.873"
      />
      <path
        fill="none"
        stroke={stitching}
        onClick={() => handleClick("stitching")}
        onMouseEnter={() => setStitchingActive(true)}
        onMouseLeave={() => setStitchingActive(false)}
        style={
          stitchingActive && !preview
            ? {stroke: adjust(stitching, 55)}
            : {stroke: stitching}
        }
        strokeWidth="0.319"
        strokeMiterlimit="10"
        strokeDasharray="0.963,0.962"
        d="M426.702,43.671
	c0,0-1.576,35.256,0.13,39.625c0.022-0.022,12.722,2.425,40.525,2.119"
      />
      <path
        fill="none"
        stroke={stitching}
        onClick={() => handleClick("stitching")}
        onMouseEnter={() => setStitchingActive(true)}
        onMouseLeave={() => setStitchingActive(false)}
        style={
          stitchingActive && !preview
            ? {stroke: adjust(stitching, 55)}
            : {stroke: stitching}
        }
        strokeWidth="0.319"
        strokeMiterlimit="10"
        strokeDasharray="0.963,0.962"
        d="M430.753,43.671
	c0,0,3.542,31.639,5.531,32.92c1.99,1.282,31.073,3.873,31.073,3.873"
      />
      <path
        fill="none"
        stroke={stitching}
        onClick={() => handleClick("stitching")}
        onMouseEnter={() => setStitchingActive(true)}
        onMouseLeave={() => setStitchingActive(false)}
        style={
          stitchingActive && !preview
            ? {stroke: adjust(stitching, 55)}
            : {stroke: stitching}
        }
        strokeWidth="0.319"
        strokeMiterlimit="10"
        strokeDasharray="1.276,0.957"
        d="
	M287.722,164.173l0.066,24.77c0.002,0.548,0.348,1.04,0.875,1.242l5.828,2.24c1.029,0.396,2.127,0.598,3.234,0.595l33.65-0.167
	l3.381-5.166c0.456-0.697,0.697-1.506,0.695-2.33l-0.058-21.39L287.722,164.173z"
      />
      <path
        fill="none"
        stroke={stitching}
        onClick={() => handleClick("stitching")}
        onMouseEnter={() => setStitchingActive(true)}
        onMouseLeave={() => setStitchingActive(false)}
        style={
          stitchingActive && !preview
            ? {stroke: adjust(stitching, 55)}
            : {stroke: stitching}
        }
        strokeWidth="0.319"
        strokeMiterlimit="10"
        strokeDasharray="1.276,0.957"
        d="
	M332.243,189.945l-34.544,0.261c-0.758,0-1.497-0.136-2.199-0.407l-4.902-1.884l-0.056-18.28"
      />
      <path
        fill="none"
        stroke={stitching}
        onClick={() => handleClick("stitching")}
        onMouseEnter={() => setStitchingActive(true)}
        onMouseLeave={() => setStitchingActive(false)}
        style={
          stitchingActive && !preview
            ? {stroke: adjust(stitching, 55)}
            : {stroke: stitching}
        }
        strokeWidth="0.319"
        strokeMiterlimit="10"
        strokeDasharray="1.276,0.957"
        d="
	M339.121,163.957l0.062,22.991c0.001,0.513-0.142,1.017-0.415,1.457l-2.744,4.435l7.516-2.625c0.794-0.278,1.321-1.006,1.319-1.821
	l-0.066-24.453L339.121,163.957z"
      />
      <line
        fill="none"
        stroke={stitching}
        onClick={() => handleClick("stitching")}
        onMouseEnter={() => setStitchingActive(true)}
        onMouseLeave={() => setStitchingActive(false)}
        style={
          stitchingActive && !preview
            ? {stroke: adjust(stitching, 55)}
            : {stroke: stitching}
        }
        strokeWidth="0.319"
        strokeMiterlimit="10"
        strokeDasharray="1.276,0.957"
        x1="287.722"
        y1="145.865"
        x2="287.722"
        y2="161.344"
      />
      <line
        fill="none"
        stroke={stitching}
        onClick={() => handleClick("stitching")}
        onMouseEnter={() => setStitchingActive(true)}
        onMouseLeave={() => setStitchingActive(false)}
        style={
          stitchingActive && !preview
            ? {stroke: adjust(stitching, 55)}
            : {stroke: stitching}
        }
        strokeWidth="0.319"
        strokeMiterlimit="10"
        strokeDasharray="1.276,0.957"
        x1="344.881"
        y1="145.865"
        x2="344.881"
        y2="161.344"
      />
      <line
        fill="none"
        stroke={stitching}
        onClick={() => handleClick("stitching")}
        onMouseEnter={() => setStitchingActive(true)}
        onMouseLeave={() => setStitchingActive(false)}
        style={
          stitchingActive && !preview
            ? {stroke: adjust(stitching, 55)}
            : {stroke: stitching}
        }
        strokeWidth="0.319"
        strokeMiterlimit="10"
        strokeDasharray="1.276,0.957"
        x1="288.304"
        y1="152.251"
        x2="344.184"
        y2="152.251"
      />
      <polyline
        fill="none"
        stroke={stitching}
        onClick={() => handleClick("stitching")}
        onMouseEnter={() => setStitchingActive(true)}
        onMouseLeave={() => setStitchingActive(false)}
        style={
          stitchingActive && !preview
            ? {stroke: adjust(stitching, 55)}
            : {stroke: stitching}
        }
        strokeWidth="0.233"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        points="
	344.152,152.321 345.207,151.244 344.162,151.415 345.215,150.339 344.171,150.509 345.225,149.434 344.18,149.604 345.234,148.529 
	344.19,148.699 345.244,147.626 344.199,147.795 345.253,146.722 344.209,146.89 345.261,145.819 "
      />
      <polyline
        fill="none"
        stroke={stitching}
        onClick={() => handleClick("stitching")}
        onMouseEnter={() => setStitchingActive(true)}
        onMouseLeave={() => setStitchingActive(false)}
        style={
          stitchingActive && !preview
            ? {stroke: adjust(stitching, 55)}
            : {stroke: stitching}
        }
        strokeWidth="0.233"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        points="
	287.218,152.321 288.273,151.244 287.228,151.415 288.281,150.339 287.237,150.509 288.291,149.434 287.246,149.604 288.3,148.529 
	287.255,148.699 288.31,147.626 287.265,147.795 288.319,146.722 287.275,146.89 288.327,145.819 "
      />
      <line
        fill="none"
        stroke={stitching}
        onClick={() => handleClick("stitching")}
        onMouseEnter={() => setStitchingActive(true)}
        onMouseLeave={() => setStitchingActive(false)}
        style={
          stitchingActive && !preview
            ? {stroke: adjust(stitching, 55)}
            : {stroke: stitching}
        }
        strokeWidth="0.319"
        strokeMiterlimit="10"
        strokeDasharray="1.276,0.957"
        x1="288.327"
        y1="169.361"
        x2="334.23"
        y2="169.361"
      />
      <line
        fill="none"
        stroke={stitching}
        onClick={() => handleClick("stitching")}
        onMouseEnter={() => setStitchingActive(true)}
        onMouseLeave={() => setStitchingActive(false)}
        style={
          stitchingActive && !preview
            ? {stroke: adjust(stitching, 55)}
            : {stroke: stitching}
        }
        strokeWidth="0.319"
        strokeMiterlimit="10"
        strokeDasharray="1.276,0.957"
        x1="340.281"
        y1="169.361"
        x2="344.634"
        y2="169.361"
      />
      <line
        fill="none"
        stroke={stitching}
        onClick={() => handleClick("stitching")}
        onMouseEnter={() => setStitchingActive(true)}
        onMouseLeave={() => setStitchingActive(false)}
        style={
          stitchingActive && !preview
            ? {stroke: adjust(stitching, 55)}
            : {stroke: stitching}
        }
        strokeWidth="0.319"
        strokeMiterlimit="10"
        strokeDasharray="1.276,0.957"
        x1="290.542"
        y1="168.485"
        x2="290.542"
        y2="164.71"
      />
      <line
        fill="none"
        stroke={stitching}
        onClick={() => handleClick("stitching")}
        onMouseEnter={() => setStitchingActive(true)}
        onMouseLeave={() => setStitchingActive(false)}
        style={
          stitchingActive && !preview
            ? {stroke: adjust(stitching, 55)}
            : {stroke: stitching}
        }
        strokeWidth="0.319"
        strokeMiterlimit="10"
        strokeDasharray="0.963,0.962"
        x1="301.188"
        y1="59.592"
        x2="301.188"
        y2="63.668"
      />
      <line
        fill="none"
        stroke={stitching}
        onClick={() => handleClick("stitching")}
        onMouseEnter={() => setStitchingActive(true)}
        onMouseLeave={() => setStitchingActive(false)}
        style={
          stitchingActive && !preview
            ? {stroke: adjust(stitching, 55)}
            : {stroke: stitching}
        }
        strokeWidth="0.319"
        strokeMiterlimit="10"
        strokeDasharray="0.963,0.962"
        x1="299.443"
        y1="59.761"
        x2="299.443"
        y2="63.802"
      />
      <line
        fill="none"
        stroke={stitching}
        onClick={() => handleClick("stitching")}
        onMouseEnter={() => setStitchingActive(true)}
        onMouseLeave={() => setStitchingActive(false)}
        style={
          stitchingActive && !preview
            ? {stroke: adjust(stitching, 55)}
            : {stroke: stitching}
        }
        strokeWidth="0.319"
        strokeMiterlimit="10"
        strokeDasharray="0.963,0.962"
        x1="299.443"
        y1="65.266"
        x2="299.443"
        y2="76.704"
      />
      <line
        fill="none"
        stroke={stitching}
        onClick={() => handleClick("stitching")}
        onMouseEnter={() => setStitchingActive(true)}
        onMouseLeave={() => setStitchingActive(false)}
        style={
          stitchingActive && !preview
            ? {stroke: adjust(stitching, 55)}
            : {stroke: stitching}
        }
        strokeWidth="0.319"
        strokeMiterlimit="10"
        strokeDasharray="0.963,0.962"
        x1="301.188"
        y1="65.102"
        x2="301.188"
        y2="76.537"
      />
      <line
        fill="none"
        stroke={stitching}
        onClick={() => handleClick("stitching")}
        onMouseEnter={() => setStitchingActive(true)}
        onMouseLeave={() => setStitchingActive(false)}
        style={
          stitchingActive && !preview
            ? {stroke: adjust(stitching, 55)}
            : {stroke: stitching}
        }
        strokeWidth="0.319"
        strokeMiterlimit="10"
        strokeDasharray="0.963,0.962"
        x1="283.097"
        y1="65.102"
        x2="301.581"
        y2="63.137"
      />
      <path
        fill="none"
        stroke={stitching}
        onClick={() => handleClick("stitching")}
        onMouseEnter={() => setStitchingActive(true)}
        onMouseLeave={() => setStitchingActive(false)}
        style={
          stitchingActive && !preview
            ? {stroke: adjust(stitching, 55)}
            : {stroke: stitching}
        }
        strokeWidth="0.319"
        strokeMiterlimit="10"
        strokeDasharray="0.963,0.962"
        d="M464.228,62.855
	l-27.549-4.996c-0.144-0.026-0.274,0.093-0.26,0.24l0.586,5.913c0.01,0.098,0.083,0.178,0.181,0.196l28.28,5.286"
      />
      <path
        d="M474.485,252.618c0.16-0.023,0.248-0.037,0.259-0.038l-0.099-0.631c-0.008,0.002-0.066,0.011-0.173,0.028
	c-0.396-20.802-0.917-43.586-1.414-65.359c-0.397-17.392-0.772-33.82-1.044-47.537c-0.444-22.551-2.19-43.765-5.191-63.056
	c-2.395-15.397-4.956-25.289-5.921-29.014c-0.042-0.168-0.085-0.33-0.125-0.484h1.639l0.061-0.238
	c1.874-7.117,1.577-14.176,0.998-18.845c-0.626-5.056-1.711-8.486-1.722-8.52l-0.055-0.171l-2.986-0.695l-0.061-0.044l-0.042,0.022
	c-2.9-0.604-5.751-1.163-8.161-1.63c-2.935-0.569-5.374-1.127-7.733-1.666c-3.117-0.714-6.062-1.386-9.911-2.042
	c-9.497-1.618-17.943-2.898-25.824-3.913c-7.248-0.932-15.85-1.93-24.698-2.257c-9.112-0.336-18.038-0.15-26.529,0.554
	c-7.383,0.612-15.405,1.569-25.243,3.014c-7.573,1.113-15.443,2.406-24.06,3.953c-3.042,0.546-5.25,1.098-7.589,1.682
	c-1.931,0.483-3.927,0.981-6.473,1.489c-1.188,0.237-2.591,0.518-4.008,0.813l-0.037-0.019l-0.054,0.038
	c-0.005,0.002-0.01,0.003-0.016,0.004l-2.803,0.585l0.015,0.07c-0.11,0.021-0.205,0.099-0.242,0.212
	c-0.01,0.033-1.052,3.283-1.634,8.163c-0.536,4.503-0.784,11.366,1.089,18.483l0.062,0.239h1.64c-0.08,0.32-0.188,0.737-0.309,1.203
	c-0.963,3.725-3.526,13.618-5.921,29.016c-3,19.29-4.747,40.504-5.191,63.054c-0.269,13.717-0.645,30.144-1.043,47.536
	c-1.428,62.493-3.045,133.325-1.383,141.636c0.823,4.11,0.961,27.369,1.123,54.301c0.258,42.975,0.578,96.46,3.755,105.054
	l0.054,0.147l0.15,0.048c0.077,0.023,7.818,2.379,20.537,3.547c7.467,0.684,15.168,0.834,22.89,0.443
	c9.643-0.49,19.345-1.828,28.838-3.978c0.262-0.023,0.279-0.254,0.306-0.627c0.016-0.221,0.036-0.549,0.062-0.975
	c0.051-0.828,0.121-2.069,0.21-3.689c0.166-3.009,0.402-7.436,0.702-13.157c0.504-9.633,1.198-23.063,2.057-39.922
	c1.467-28.702,2.931-57.778,2.945-58.07c0.037-0.861,3.638-86.441,2.738-121.924c1.202-27.012,7.527-53.174,7.59-53.435l0.008-0.032
	l0.001-0.031c0.005-0.1,0.393-10.06,1.481-19.929c1.883-17.082,4.245-19.806,5.498-19.895c1.251,0.089,3.613,2.813,5.496,19.893
	c0.915,8.29,1.335,16.646,1.448,19.166c-0.002,0.05-0.004,0.081-0.004,0.09h0.009c0.017,0.423,0.028,0.659,0.028,0.675l0.001,0.031
	l0.008,0.032c0.063,0.261,6.389,26.423,7.592,53.435c-0.902,35.483,2.699,121.063,2.736,121.926
	c0.015,0.29,1.48,29.366,2.944,58.068c0.862,16.859,1.553,30.289,2.058,39.922c0.3,5.721,0.536,10.148,0.701,13.157
	c0.09,1.62,0.16,2.861,0.211,3.689c0.026,0.426,0.047,0.754,0.062,0.975c0.027,0.373,0.044,0.604,0.307,0.627
	c9.492,2.15,19.195,3.488,28.837,3.978c2.801,0.142,5.6,0.212,8.384,0.212c4.894,0,9.748-0.219,14.505-0.655
	c12.722-1.168,20.461-3.524,20.537-3.547l0.15-0.048l0.055-0.147c3.176-8.594,3.497-62.078,3.755-105.052
	c0.162-26.933,0.301-50.192,1.124-54.303C475.518,322.867,475.218,291.23,474.485,252.618 M472.42,186.633
	c0.383,16.766,0.793,34.692,1.146,51.969l-21.77,0.165l-0.384-49.15c-0.003-0.361-0.14-0.701-0.374-0.93
	c-0.189-0.186-0.424-0.288-0.679-0.284l-18.574,0.2c0.008-5.1,0.029-10.166,0.055-14.937c0.106,0.06,0.212,0.115,0.324,0.161
	l5.027,2.059c1.194,0.49,2.441,0.737,3.713,0.737c0.036,0,0.071,0,0.107-0.001l31.173-0.337
	C472.261,179.686,472.339,183.139,472.42,186.633 M451.153,238.771l-10.761,0.082c-1.247,0.011-2.466-0.376-3.54-1.114
	c-0.678-0.467-1.465-1.007-2.178-1.494c-1.316-0.901-2.172-2.507-2.292-4.294c-0.506-7.637-0.626-25.312-0.598-42.704l18.583-0.201
	h0.003c0.096,0,0.172,0.055,0.217,0.1c0.112,0.11,0.179,0.288,0.182,0.477L451.153,238.771z M472.17,175.64l-31.166,0.339
	c-1.225,0.013-2.422-0.219-3.569-0.689l-5.027-2.059c-0.932-0.383-1.564-1.389-1.574-2.504l-0.231-26.536l40.868-0.443
	C471.669,153.408,471.91,164.218,472.17,175.64 M467.611,86.191c-23.506,0.231-37.815-1.515-41.658-2.059
	c-0.003,0-0.007-0.001-0.01-0.001c-1.489-4.756-0.041-37.297,0.108-40.575c1.824-0.027,3.619-0.054,5.372-0.08
	c0.167,1.457,0.981,8.472,1.972,15.528c2.26,16.093,3.218,16.713,3.578,16.946c1.857,1.202,25.508,3.331,29.739,3.704
	C467.033,81.84,467.331,84.021,467.611,86.191 M464.392,65.712l-27.628-5.011l-0.114,0.626l27.867,5.056
	c0.244,1.312,0.48,2.624,0.707,3.935l-28.675-5.409c-0.157-0.03-0.288-0.218-0.31-0.447l-0.691-6.965
	c-0.024-0.243,0.072-0.426,0.168-0.515c0.07-0.064,0.145-0.09,0.223-0.076l27.712,4.993
	C463.907,63.168,464.154,64.44,464.392,65.712 M463.516,61.226l-27.465-4.948c-0.272-0.049-0.553,0.037-0.768,0.235
	c-0.272,0.25-0.409,0.642-0.369,1.047l0.691,6.965c0.05,0.514,0.392,0.931,0.828,1.012l28.907,5.453
	c0.458,2.675,0.883,5.349,1.277,8.015c-11.25-0.99-27.964-2.728-29.298-3.59c-0.252-0.163-1.232-1.834-3.293-16.499
	c-0.977-6.954-1.78-13.867-1.961-15.45c14.344-0.214,25.873-0.382,28.009-0.414c-0.066,0.657-0.143,1.314-0.235,1.967
	c-0.071,0.153,0.015,0.484,0.447,2.152C461.483,51.797,462.555,56.497,463.516,61.226 M461.198,19.291
	c0.223,0.749,1.107,3.881,1.646,8.248c0.564,4.556,0.856,11.417-0.922,18.351h-1.306c-0.087-0.346-0.146-0.595-0.158-0.692
	l0.001-0.008c0.939-6.577,0.491-13.509-0.052-18.167c-0.315-2.699-0.686-4.895-0.944-6.263c-0.159-0.843-0.286-1.441-0.365-1.794
	c-0.014-0.063-0.026-0.119-0.039-0.172L461.198,19.291z M459.805,27.372c0.445,3.916,0.82,9.477,0.33,15.042
	c-2.308,0.034-18.221,0.267-36.403,0.539l-0.275-19.591l25.735,0.516h0.024l0.024-0.001c0.276-0.038,6.499-0.891,9.252-4.695
	C458.772,20.454,459.356,23.42,459.805,27.372 M288.503,19.182c2.752,3.804,8.974,4.657,9.251,4.695l0.024,0.001l26.053-0.522
	l-0.285,20.049c-18.355-0.397-34.389-0.911-36.686-0.986c-0.49-5.567-0.116-11.13,0.329-15.047
	C287.637,23.42,288.221,20.454,288.503,19.182 M285.254,45.17c-1.78-6.929-1.536-13.597-1.013-17.992
	c0.517-4.351,1.403-7.384,1.578-7.958l2.119-0.443c-0.014,0.058-0.027,0.12-0.043,0.189c-0.079,0.353-0.206,0.951-0.364,1.794
	c-0.257,1.368-0.629,3.564-0.944,6.263c-0.542,4.652-0.991,11.575-0.055,18.147H285.254z M283.525,60.992l18.138-1.595
	c0.077-0.006,0.129,0.03,0.153,0.051c0.023,0.023,0.065,0.071,0.065,0.148V63.8c0,0.103-0.078,0.188-0.179,0.199l-19.151,1.978
	C282.863,64.313,283.187,62.65,283.525,60.992 M282.43,66.63l19.336-1.997c0.429-0.044,0.752-0.401,0.752-0.833v-4.204
	c0-0.235-0.098-0.46-0.271-0.618c-0.173-0.158-0.408-0.238-0.639-0.216l-17.948,1.577c0.914-4.428,1.927-8.831,3.05-13.168
	c0.199-0.772,0.325-1.256,0.394-1.57c0.01-0.028,0.016-0.056,0.02-0.086c0.063-0.302,0.066-0.421,0.031-0.496
	c-0.091-0.651-0.169-1.306-0.235-1.96c2.159,0.07,13.669,0.439,28.031,0.786c-0.173,1.52-0.957,8.376-1.917,15.272
	c-2.014,14.478-2.991,16.135-3.243,16.298c-1.338,0.865-18.163,2.611-29.415,3.6C280.984,74.898,281.667,70.76,282.43,66.63
	 M280.28,79.665c3.89-0.343,27.982-2.502,29.857-3.715c0.36-0.233,1.318-0.853,3.529-16.744c0.972-6.993,1.766-13.942,1.924-15.345
	c1.92,0.046,3.89,0.092,5.892,0.136l-0.397,0.019c0.017,0.356,1.641,35.146,0.083,40.115c-0.004,0-0.007,0.001-0.011,0.001
	c-3.851,0.545-18.2,2.297-41.774,2.058C279.662,84.024,279.962,81.847,280.28,79.665 M355.697,367.632
	c-0.092,2.297-0.148,3.611-0.152,3.714c-0.056,1.124-5.563,110.413-5.982,115.89c-21.332,4.805-39.669,4.547-51.31,3.48
	c-11.687-1.07-19.139-3.147-20.278-3.479c-3.087-8.946-3.405-62.005-3.661-104.687c-0.031-5.265-0.061-10.39-0.098-15.26v-0.001
	c-0.145-20.07-0.367-35.805-1.037-39.161c-0.808-4.037-0.815-23.825-0.453-49.939c2.315,0.336,13.362,1.867,28.061,2.668
	c5.249,0.288,11.342,0.5,17.905,0.5c12.415,0,26.494-0.759,39.623-3.204C357.871,311.906,356.212,354.887,355.697,367.632
	 M358.324,277.503c-20.034,3.748-42.32,3.547-57.494,2.719c-14.87-0.813-25.993-2.369-28.094-2.677
	c0.004-0.347,0.01-0.693,0.015-1.041c2.363,0.344,13.385,1.866,28.036,2.666c5.249,0.287,11.342,0.499,17.905,0.499
	c12.422,0,26.511-0.761,39.645-3.206C358.333,276.808,358.329,277.155,358.324,277.503 M358.344,275.813
	c-20.039,3.751-42.336,3.55-57.514,2.721c-14.816-0.81-25.91-2.358-28.071-2.674c0.107-7.321,0.24-15.105,0.392-23.153
	c2.108,0.309,13.129,1.85,27.889,2.646c5.14,0.277,11.102,0.48,17.528,0.48c12.414,0,26.551-0.762,39.779-3.226
	C358.46,259.195,358.445,267.16,358.344,275.813 M373.178,155.536c-1.364,0.34-2.533,2.504-3.567,6.593
	c-0.849,3.356-1.605,8.008-2.246,13.825c-0.559,5.075-0.934,10.175-1.166,13.951c-0.489-10.314-1.463-32.139-1.13-35.234
	c0.238-2.22,1.996-3.698,3.023-4.38c1.711-1.138,3.775-1.685,5.086-1.362V155.536z M373.178,148.274
	c-1.491-0.293-3.626,0.279-5.439,1.485c-1.934,1.286-3.107,3.007-3.305,4.844c-0.441,4.104,1.312,39.214,1.386,40.708l0.088-0.005
	c-0.014,0.312-0.023,0.515-0.025,0.59c-0.253,1.05-6.417,26.881-7.601,53.538l-0.001,0.01l0.001,0.012
	c0.019,0.809,0.038,1.645,0.054,2.503c-20.038,3.751-42.192,3.569-57.254,2.757c-14.945-0.807-26.043-2.376-27.919-2.652
	c0.402-21.232,0.927-44.256,1.411-65.433c0.397-17.392,0.772-33.819,1.043-47.536c0.284-14.435,1.22-32.91,3.684-52.269
	c1.52,0.016,3.001,0.024,4.445,0.024c21.026,0,33.883-1.574,37.501-2.086c0.014-0.002,0.029-0.004,0.042-0.006l0.234,0.233
	l0.178-0.453c1.705-4.368,0.1-38.853,0.021-40.535c0.603,0.013,1.208,0.027,1.816,0.04L323.36,56.6l-0.004,0.291l0.288,0.031
	c0.276,0.031,4.667,0.502,9.148,0.502c2.31,0,4.645-0.125,6.45-0.502c0.179-0.017,0.273-0.153,0.28-0.405l0.332-12.242
	c8.332,0.11,16.429,0.207,23.627,0.207c3.466,0,6.722-0.022,9.697-0.077V148.274z M324.002,56.318l0.464-32.824l15.325-0.394
	c-0.091,3.341-0.824,30.339-0.902,33.243C334.131,57.254,325.667,56.483,324.002,56.318 M373.178,43.768
	c-9.148,0.165-20.966,0.033-33.305-0.131l0.558-20.614l32.463-0.652l0.284-0.006V43.768z M373.498,21.72l-75.683,1.521
	c-0.421-0.061-6.52-0.995-8.95-4.657c1.294-0.267,2.56-0.521,3.648-0.739c2.561-0.51,4.565-1.011,6.503-1.495
	c2.327-0.582,4.527-1.131,7.547-1.673c8.61-1.546,16.474-2.838,24.04-3.949c9.825-1.443,17.833-2.399,25.202-3.011
	c8.467-0.702,17.367-0.887,26.453-0.552c8.823,0.326,17.407,1.322,24.641,2.254c7.872,1.014,16.311,2.292,25.798,3.908
	c3.832,0.652,6.768,1.324,9.877,2.034c2.363,0.541,4.806,1.099,7.753,1.671c2.312,0.449,5.029,0.981,7.8,1.555
	c-2.43,3.656-8.527,4.593-8.947,4.654l-75.677-1.521H373.498z M423.285,56.543c-1.665,0.166-10.13,0.937-14.888,0.024
	c-0.078-2.917-0.81-30.113-0.9-33.467l15.323,0.394L423.285,56.543z M407.399,43.188c-13.7,0.216-25.294,0.412-33.583,0.568V22.365
	l33.04,0.663L407.399,43.188z M379.628,175.954c-0.641-5.817-1.397-10.469-2.246-13.825c-1.032-4.089-2.203-6.253-3.566-6.593
	v-6.631c1.315-0.272,3.319,0.277,4.988,1.386c1.026,0.682,2.784,2.16,3.024,4.38c0.322,3.009-0.589,23.71-1.089,34.339
	C380.504,185.343,380.145,180.639,379.628,175.954 M381.111,195.896c-0.003-0.084-0.013-0.327-0.029-0.706
	c0.132-2.679,1.812-36.561,1.379-40.587c-0.197-1.837-1.371-3.558-3.304-4.844c-1.775-1.179-3.855-1.753-5.341-1.502V44.394
	c8.29-0.156,19.891-0.352,33.601-0.568l0.347,12.915c0.007,0.253,0.101,0.389,0.281,0.406c1.805,0.377,4.139,0.502,6.45,0.502
	c4.48,0,8.872-0.472,9.148-0.502l0.288-0.031l-0.19-13.525c0.558-0.009,1.117-0.017,1.672-0.025
	c-0.176,3.776-1.652,36.751-0.004,40.972l0.178,0.453l0.233-0.233c0.014,0.002,0.028,0.004,0.043,0.006
	c3.617,0.512,16.473,2.086,37.501,2.086c1.405,0,2.85-0.007,4.329-0.023c2.464,19.358,3.4,37.833,3.685,52.268
	c0.026,1.311,0.051,2.649,0.08,4.008l-41.505,0.451l0.237,27.179c0.008,0.951,0.392,1.838,1.015,2.454
	c-0.122,21.304-0.127,48.74,0.542,58.806c0.13,1.981,1.09,3.767,2.567,4.778c0.712,0.488,1.499,1.028,2.177,1.494
	c1.168,0.803,2.501,1.226,3.857,1.226h0.049l33.182-0.251c0.089,4.327,0.173,8.614,0.253,12.828
	c-2.947,0.42-13.629,1.847-27.64,2.612c-15.184,0.829-37.489,1.03-57.533-2.725c0.015-0.857,0.033-1.691,0.054-2.499v-0.012
	l-0.001-0.01C387.529,222.781,381.366,196.952,381.111,195.896 M388.647,252.601c13.146,2.453,27.248,3.214,39.682,3.214
	c6.561,0,12.658-0.212,17.905-0.5c13.904-0.758,24.541-2.168,27.609-2.604c0.152,8.049,0.285,15.831,0.392,23.154
	c-2.225,0.325-13.289,1.863-28.043,2.669c-15.186,0.829-37.495,1.03-57.543-2.726C388.549,267.156,388.535,259.19,388.647,252.601
	 M388.657,276.457c13.143,2.45,27.241,3.212,39.672,3.212c6.561,0,12.658-0.212,17.905-0.499c14.598-0.797,25.592-2.312,28.01-2.662
	c0.006,0.386,0.01,0.77,0.016,1.153c-2.163,0.316-13.257,1.864-28.068,2.673c-15.181,0.828-37.48,1.03-57.521-2.723
	C388.667,277.226,388.662,276.841,388.657,276.457 M473.816,328.128c-0.672,3.356-0.894,19.091-1.038,39.16v0.002
	c-0.036,4.87-0.066,9.995-0.099,15.261c-0.255,42.682-0.573,95.74-3.661,104.686c-1.138,0.332-8.575,2.406-20.255,3.478
	c-11.646,1.068-29.99,1.328-51.332-3.479c-0.42-5.477-5.925-114.766-5.982-115.888c-0.005-0.105-0.061-1.461-0.156-3.824
	c-0.519-12.869-2.167-55.624-2.614-89.262c13.138,2.447,27.226,3.208,39.65,3.208c6.561,0,12.658-0.213,17.905-0.5
	c14.646-0.8,25.668-2.322,28.035-2.665C474.629,304.361,474.622,324.097,473.816,328.128"
      />
      <rect fill="none" width="500" height="500" />
    </svg>
  );
}
